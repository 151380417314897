import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Company, Sector, USER_ROLE } from '../../types';
import { Chip, styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { CompanyStageCell } from '../Table/CellRenderers/CompanyStageCell';
import { SectorsCell } from '../Table/CellRenderers/SectorsCell';
// import { AssignESGContactCell } from '../components/AssignEsgContactCell/AssignESGContactCell';
// import { AssignAnalystCell } from '../components/AssignAnalystCell/AssignAnalystCell';
import { useAtomValue } from 'jotai';
import { activeRoleState } from '../../state/UIState';
import { CompanyCell } from '../Table/CellRenderers/CompanyCell';
import { AssignESGContactCell } from '../Table/CellRenderers/AssignEsgContactCell/AssignESGContactCell';
import { AssignAnalystCell } from '../Table/CellRenderers/AssignAnalystCell/AssignAnalystCell';

const RegionWrapper = styled('div')`
  display: flex;
`;

const columnHelper = createColumnHelper<Company>();

export const useCompaniesTableColumns = () => {
  const { colors } = useTheme();

  const activeRole = useAtomValue(activeRoleState);

  const nameColumn = columnHelper.accessor('name', {
    cell: (info) => (
      <CompanyCell
        name={info.getValue()}
        logo={info.row.original.logoUrl}
        labelColor={colors.accent[50]}
      />
    ),
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Company
      </Typography>
    ),
    meta: {
      width: '200px',
      minWidth: '200px',
      maxWidth: '200px',
    },
    enableColumnFilter: true,
  });

  const stageColumn = columnHelper.accessor('stage', {
    cell: (info) => <CompanyStageCell stage={info.getValue()} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Stage
      </Typography>
    ),
    meta: {
      width: '180px',
      minWidth: '180px',
      maxWidth: '180px',
    },
    filterFn: 'equalsString',
    enableColumnFilter: true,
  });

  const typeColumn = columnHelper.accessor('type', {
    cell: (info) => <Chip label={info.getValue()} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Type
      </Typography>
    ),
    meta: {
      width: '150px',
      minWidth: '150px',
      maxWidth: '150px',
    },
    filterFn: 'equalsString',
    enableColumnFilter: true,
  });

  const sectorsColumn = columnHelper.accessor('sectors', {
    cell: (info) => <SectorsCell sectors={info.getValue()} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Sector
      </Typography>
    ),
    meta: {
      width: '200px',
      minWidth: '200px',
      maxWidth: '200px',
    },
    enableSorting: false,
    filterFn: (row: any, columnId, selectedFilters) => {
      const values: string[] = row.getValue(columnId).map((s: Sector) => s.name);
      return selectedFilters.some((filter: string) => values.includes(filter));
    },
    enableColumnFilter: true,
  });

  const countryColumn = columnHelper.accessor('country', {
    cell: (info) => (
      <RegionWrapper>{info.getValue() ? <Chip label={info.getValue()} /> : null}</RegionWrapper>
    ),
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Country
      </Typography>
    ),
    meta: {
      width: '200px',
      minWidth: '200px',
      maxWidth: '200px',
    },
    filterFn: (row: any, columnId, selectedFilters) => {
      const value: string = row.getValue(columnId) || '';
      return selectedFilters.includes(value);
    },
    enableColumnFilter: true,
  });

  const esgContactColumn = columnHelper.accessor('externalEsgContact', {
    cell: (info) => <AssignESGContactCell company={info.row.original} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Assigned ESG Contact
      </Typography>
    ),
    meta: {
      width: '300px',
      minWidth: '300px',
      maxWidth: '300px',
    },
  });

  const analystColumn = columnHelper.accessor('analyst', {
    cell: (info) => <AssignAnalystCell company={info.row.original} />,
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Assigned Analyst
      </Typography>
    ),
    meta: {
      width: '300px',
      minWidth: '300px',
      maxWidth: '300px',
    },
    filterFn: (row: any, columnId, selectedFilters) => {
      const value: string = row.getValue(columnId) || '';
      return selectedFilters.includes(value);
    },
    enableColumnFilter: true,
  });

  const columns = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    ) {
      return [nameColumn, typeColumn, sectorsColumn, countryColumn];
    }

    return [
      nameColumn,
      stageColumn,
      typeColumn,
      sectorsColumn,
      countryColumn,
      esgContactColumn,
      analystColumn,
    ];
  }, [
    activeRole,
    analystColumn,
    countryColumn,
    esgContactColumn,
    nameColumn,
    sectorsColumn,
    stageColumn,
    typeColumn,
  ]);

  return columns;
};
