import { useTheme } from '@mui/material';
import { NO_ANSWER_OPTION, OTHER_OPTION } from '../constants';

export const useWidgetChartColor = () => {
  const { colors } = useTheme();
  const customColors = [
    colors.accent[50],
    colors.primary[70],
    colors.primary[60],
    colors.primary[50],
  ];
  const getWidgetChartColor = (
    widgetData: {
      name: string;
      value: number;
    }[],
    index: number
  ) => {
    const sorted = [...(widgetData || [])]
      .sort((a, b) => b.value - a.value)
      ?.filter((data) => data?.name !== NO_ANSWER_OPTION.name && data?.name !== OTHER_OPTION.name);

    const noAnswerColor = colors.gray[50];
    const otherColor = colors.primary[30];

    const currentWidget = widgetData[index];

    if (currentWidget?.name === NO_ANSWER_OPTION.name) {
      return noAnswerColor;
    }

    if (currentWidget?.name === OTHER_OPTION.name) {
      return otherColor;
    }

    const sortedIndex = sorted?.findIndex((data) => data?.name === currentWidget?.name);

    return customColors?.[sortedIndex] || undefined;
  };

  return getWidgetChartColor;
};
