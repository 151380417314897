import { memo, useEffect, useMemo, useRef } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, init as echartsInit, use as echartsUse } from 'echarts/core';
import { PieChart as EChartsPie, PieSeriesOption } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { CallbackDataParams, TopLevelFormatterParams } from 'echarts/types/dist/shared';
import { styled } from '@mui/material/styles';
import { useIsInViewport } from '../../../hooks/useIsInViewport';
import { ChartDataItem } from '../../../types';
import { debounce } from '../../../utils/debounce';

type ECPieOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | PieSeriesOption
  | TooltipComponentOption
>;

// Register the required components
echartsUse([
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  EChartsPie,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Chart = styled('div')<{ width: number | string; height?: string }>`
  min-width: ${({ width }) => `${width}`};
  height: ${({ height }) => (height ? `${height}` : '280px')};
  position: relative;
`;

const labelTextStyle = {
  fontFamily: 'PPNeueMontreal-Regular',
  fontSize: 12,
  lineHeight: 18,
  color: '#161717',
  rich: {
    bold: {
      fontWeight: 'bold' as const,
    },
  },
};

const tooltipTextStyle = {
  fontFamily: 'PPNeueMontreal-Regular',
  fontSize: 14,
  color: '#161717',
};

export interface PieChartProps {
  chartColors?: (string | undefined)[];
  data: ChartDataItem[];
  customLabelFormatter?: (params: TopLevelFormatterParams) => string;
  customTooltipFormatter?: (params: TopLevelFormatterParams) => string;
  hideLabelLine?: boolean;
}

export const PieChart = memo(function PieChart({
  chartColors,
  data,
  customLabelFormatter,
  customTooltipFormatter,
  hideLabelLine,
}: PieChartProps) {
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const pieChart = useRef<ECharts | null>(null);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.6,
    },
    chartContainer
  );

  const total = useMemo(() => {
    return data.reduce((acc, item) => acc + item.value, 0);
  }, [data]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!pieChart.current) {
      pieChart.current = echartsInit(container);
    }

    const handleResize = debounce((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          pieChart.current?.resize();
        }
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(container);

    return () => {
      if (container && resizeObserver) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(pieChart.current && isInViewport)) {
      return;
    }

    const pieChartData: PieSeriesOption = {
      type: 'pie',
      radius: ['50%', '75%'],
      labelLine: {
        show: !hideLabelLine,
      },
      data,
    };

    const formatter = (params: TopLevelFormatterParams) => {
      const { name, value } = (params as CallbackDataParams).data as ChartDataItem;
      const pctValue = `${((value / total) * 100).toFixed(2)}%`;
      return `${name} \n${value} (${pctValue})`;
    };

    const option: ECPieOption = {
      grid: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      series: [pieChartData],
      label: {
        ...labelTextStyle,
        position: 'outer',
        formatter: customLabelFormatter || formatter,
      },
      tooltip: {
        ...tooltipTextStyle,
        formatter: customTooltipFormatter || formatter,
        trigger: 'item',
      },
    };

    if (pieChart.current) {
      pieChart.current.setOption(option, true);
    }
  }, [
    chartColors,
    customLabelFormatter,
    customTooltipFormatter,
    data,
    hideLabelLine,
    isInViewport,
    total,
  ]);

  return (
    <Wrapper>
      <Chart width={'100%'} ref={chartContainer} />
    </Wrapper>
  );
});
