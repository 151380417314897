import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useCallback, useMemo } from 'react';

import { OpportunitiesFilter } from '../../../../../../components/Filters/OpportunitiesFilter';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const OpportunitiesLevelFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const uniqueOpportunities: SelectItem[] = useMemo(() => {
    const companies = aggregatedReport?.companies || [];
    return [...new Set(companies.map((c) => c.esgOpportunitiesLevel))]
      ?.filter((o) => o)
      .map((opportunity, i) => ({
        id: i + 1,
        value: opportunity,
      }));
  }, [aggregatedReport?.companies]);

  const selectedOpportunities = useMemo(() => {
    if (!aggregatedReport) return [];

    const selectedRiskFilters =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES];

    const hasFilteredRisks =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES]?.length;

    if (!hasFilteredRisks) return [];

    return uniqueOpportunities?.filter((r) => selectedRiskFilters?.includes(r?.value));
  }, [aggregatedReport, uniqueOpportunities]);

  const onChangeSelectedOpportunities = useCallback(
    (opportunities: SelectItem[]) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES]: opportunities?.length
            ? opportunities?.map((r) => r?.value)
            : [],
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[]>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <OpportunitiesFilter
      sortedUniqueOpportunities={uniqueOpportunities}
      selectedOpportunities={selectedOpportunities}
      onChangeSelectedOpportunities={onChangeSelectedOpportunities}
      width='100%'
      placeholder='ESG Opportunities'
    />
  );
};
