import { Fade, IconButton, styled } from '@mui/material';
import { QUESTION_TYPE, QuestionOption } from '../../../../../../types';
import { ReactComponent as DragIcon } from '../../../../../../assets/icons/drag.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close-gray.svg';
import { EditableTextFieldV2 } from '../../../../../../components/EditableTextField/EditableTextFieldV2';
import { Dispatch, SetStateAction } from 'react';
import { FlagOption } from '../../FlagOption';
import { Tooltip } from '../../../../../../components/Tooltip/Tooltip';
import { WeightInput } from './WeightInput';

const OptionWrapper = styled('div')`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
`;
const Icon = styled('div')<{ type: QUESTION_TYPE }>`
  height: 13px;
  width: 13px;
  ${({ type }) => type === QUESTION_TYPE.SINGLE && 'border-radius: 50%;'}
  border: 1px solid ${({ theme }) => theme.colors.primary[40]};
`;

const Wrapper = styled('div')<{ hasFixedWidth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ hasFixedWidth }) => (hasFixedWidth ? 'space-between' : 'flex-start')};
  width: ${({ hasFixedWidth }) => (hasFixedWidth ? '270px' : 'auto')};
  gap: 8px;
`;

const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onUpdateFlag?: () => void;
  questionType: QUESTION_TYPE;
  showDragIcon: boolean;
  option: QuestionOption;
  onOptionRename: (val: string) => void;
  onDeleteOption: () => void;
  optionTitle: string;
  setOptionTitle: Dispatch<SetStateAction<string>>;
  isWeightIncluded?: boolean;
  onUpdateWeight: (weight: number) => void;
}

export const Option = ({
  onOptionRename,
  showDragIcon,
  option,
  questionType,
  onUpdateFlag,
  onDeleteOption,
  optionTitle,
  setOptionTitle,
  isWeightIncluded,
  onUpdateWeight,
}: Props) => {
  return (
    <OptionWrapper>
      <TitleWrapper>
        <DragIcon
          style={{
            opacity: showDragIcon ? '1' : '0',
            margin: '-1px 4px 0 0',
          }}
        />
        <Icon type={questionType} />
        <EditableTextFieldV2
          value={optionTitle}
          onChange={(e) => setOptionTitle(e.target.value)}
          onValueChanged={onOptionRename}
          placeholder='Enter option'
          autoFocus={option.justCreated ? true : false}
        />
      </TitleWrapper>

      {showDragIcon && (
        <Wrapper hasFixedWidth={isWeightIncluded && Boolean(onUpdateFlag)}>
          {isWeightIncluded && (
            <WeightInput onUpdateWeight={onUpdateWeight} weightValue={option?.weight || ''} />
          )}
          <ActionWrapper>
            {onUpdateFlag && (
              <FlagOption isFlagged={option.isFlagged} onToggleFlag={onUpdateFlag} />
            )}
            <Tooltip
              title='Delete this option'
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [65, -13],
                    },
                  },
                ],
              }}
            >
              <IconButton onClick={onDeleteOption} style={{ padding: '4px' }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </ActionWrapper>
        </Wrapper>
      )}
    </OptionWrapper>
  );
};
