import { useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Table } from '../../../components/Table/Table';
import { useAtom } from 'jotai';
import { excelCompaniesState } from '../../../state/UIState';

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Checkbox, Chip, styled, useTheme } from '@mui/material';
import { CompanyCell } from '../../../components/Table/CellRenderers/CompanyCell';
import { rowSelectionUploadCompaniesState } from '../../../state/UIState';
import { CREATE_REQUEST_FOOTER, HEADER_HEIGHT } from '../../../constants/layoutSizes';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
`;
const CompanyLeftSection = styled('div')`
  display: flex;
  align-items: center;
`;

const columnHelper = createColumnHelper<Record<string, string | number>>();

export const SelectableCompaniesTable = () => {
  const { colors } = useTheme();
  const [data] = useAtom(excelCompaniesState);
  const [rowSelection, setRowSelection] = useAtom(rowSelectionUploadCompaniesState);
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <CellWrapper>
            <CompanyLeftSection>
              <Checkbox
                checked={info.row.original.disabled ? false : info.row.getIsSelected()}
                disabled={!!info.row.original.disabled}
              />
              <CompanyCell name={info.getValue() as string} />
            </CompanyLeftSection>
          </CellWrapper>
        ),
        header: ({ table }) => (
          <CellWrapper>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                table.getToggleAllRowsSelectedHandler()(e);
              }}
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
            <Typography variant='overline' color={colors.primary[70]}>
              Company
            </Typography>
          </CellWrapper>
        ),
        meta: {
          width: '25%',
        },
      }),
      columnHelper.accessor('type (not controlled/controlled)', {
        cell: (info) => (info.getValue() ? <Chip label={info.getValue()} /> : null),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Type
          </Typography>
        ),
        meta: {
          width: '25%',
        },
        enableSorting: false,
      }),
      columnHelper.accessor('analyst', {
        cell: (info) => (
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Analyst
          </Typography>
        ),
        meta: {
          width: '25%',
        },
      }),
    ],
    [colors.primary]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (company) => String(company.id),
  });

  return (
    <Wrapper>
      <Table
        table={table}
        height={`calc(100vh - ${HEADER_HEIGHT}px - ${CREATE_REQUEST_FOOTER}px - 78px)`}
      />
    </Wrapper>
  );
};
