import { useMemo } from 'react';
import { PieChart } from '../../../../../../components/Charts/PieChart/PieChart';
import {
  AggregatedReportWidget,
  ChartDataItem,
  QUESTION_TYPE,
  QuestionOption,
} from '../../../../../../types';
import { useCompaniesAnswers } from '../hooks/useCompaniesAnswers';
import { useWidgetQuestion } from '../hooks/useWidgetQuestion';
import { WidgetCard } from './WidgetCard';
import { NO_ANSWER_OPTION, OTHER_OPTION } from '../constants';
import { useWidgetChartColor } from '../hooks/useWidgetChartColor';
import { CallbackDataParams, TopLevelFormatterParams } from 'echarts/types/dist/shared';
import { styled } from '@mui/material';

const Wrapper = styled('div')`
  flex: 1;
  min-width: 400px;
`;

interface Props {
  widget: AggregatedReportWidget;
}

export const PieChartWidget = ({ widget }: Props) => {
  const widgetQuestion = useWidgetQuestion(widget);

  const { companiesAnswers } = useCompaniesAnswers(widget);

  const getWidgetChartColor = useWidgetChartColor();

  const widgetData = useMemo(() => {
    const questionAnswers = [...(widgetQuestion?.options || []), NO_ANSWER_OPTION];
    const data = questionAnswers?.map((answer: QuestionOption) => {
      if (widgetQuestion?.type === QUESTION_TYPE.SINGLE) {
        const numOfCompanies = companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption)?.id === answer?.id;
        }).length;

        return {
          id: answer.id,
          name: answer.name,
          value: numOfCompanies,
        };
      }

      if (widgetQuestion?.type === QUESTION_TYPE.MULTI) {
        const numOfCompanies = companiesAnswers?.filter((companyAnswer) => {
          return (companyAnswer?.answer as QuestionOption[])?.find((a) => a?.id === answer?.id);
        }).length;
        return {
          id: answer.id,
          name: answer.name,
          value: numOfCompanies,
        };
      }
    }) as ChartDataItem[];

    const sortedData = data?.sort((a, b) => b.value - a.value);
    const mostFrequentDataCap = sortedData?.slice(0, 5);
    const otherData = sortedData?.slice(5);

    const combinedData = [
      ...mostFrequentDataCap,
      {
        name: OTHER_OPTION.name,
        value: otherData?.reduce((acc, curr) => acc + curr.value, 0),
        id: OTHER_OPTION.id,
        groupedData: otherData,
      },
    ];

    return combinedData?.map((data, index) => {
      return {
        ...data,
        itemStyle: {
          color: getWidgetChartColor(combinedData, index),
        },
      };
    });
  }, [companiesAnswers, getWidgetChartColor, widgetQuestion?.options, widgetQuestion?.type]);

  const total = useMemo(() => {
    return widgetData.reduce((acc, item) => acc + item.value, 0);
  }, [widgetData]);

  const labelFormatter = (params: TopLevelFormatterParams) => {
    const { name, value } = (params as CallbackDataParams).data as ChartDataItem;

    const pctValue = `${((value / total) * 100).toFixed(2)}%`;

    return `${name} \n{bold|${pctValue}}`;
  };

  const tooltipFormatter = (params: TopLevelFormatterParams) => {
    const { name, value, id, groupedData } = (params as CallbackDataParams).data as ChartDataItem;

    if (id === OTHER_OPTION.id) {
      if (!groupedData?.length) return '';

      return `
        <div style="
            display:flex;
            flex-direction:column;
            width: 136px;
            gap: 5px;
        ">
        ${
          groupedData
            .map((data) => {
              const { name, value } = data;
              const pctValue = `${((value / total) * 100).toFixed(2)}%`;
              return `<div style="
            font-size: 14px;
            color: #161717;
            display:flex;
            justify-content:space-between;
          ">
            <div style="text-wrap: wrap">${name}</div> <strong style="font-weight: bold;">${pctValue}</strong>
          </div>`;
            })
            ?.join('') || ''
        }
        </div>
      `;
    }

    const pctValue = `${((value / total) * 100).toFixed(2)}%`;
    return `
       <div style="
            font-size: 14px;
            color: #161717;
            display:flex;
            gap: 15px;
          ">
            ${name} <strong style="font-weight: bold;">${pctValue}</strong>
          </div>
    `;
  };

  if (!widgetQuestion) return null;

  return (
    <Wrapper>
      <WidgetCard widget={widget} titleContainerStyles={{ flexDirection: 'column', gap: '3px' }}>
        <PieChart
          data={widgetData || []}
          customLabelFormatter={labelFormatter}
          customTooltipFormatter={tooltipFormatter}
        />
      </WidgetCard>
    </Wrapper>
  );
};
