import { Button, useTheme } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue-small.svg';
import { Typography } from '../../../components/Typography/Typography';
import ExcelJS, { Alignment } from 'exceljs';
import { useCallback, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { aggregatedReportState, userState } from '../../../state/UIState';
import { useBreakdownData } from '../hooks/useBreakdownData';
import FileSaver from 'file-saver';
import { getFileName } from '../../../utils/getFileName';

export const ExportButton = () => {
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const [aggregatedReport] = useAtom(aggregatedReportState);
  const { breakdownTableData, selectedQuestions, aggregatedReportBreakdownGroups } =
    useBreakdownData();

  const assessmentQuestionColumns = useMemo(() => {
    return selectedQuestions.map((question) => {
      return {
        header: question?.name,
        key: String(question?.id),
        width: 40,
        style: {
          alignment: {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left',
          } as Alignment,
        },
      };
    });
  }, [selectedQuestions]);

  const issuerCol = useMemo(
    () => ({
      header: 'Issuer',
      key: 'issuer',
      width: 20,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        } as Alignment,
      },
    }),
    []
  );

  const onExportGroups = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    aggregatedReportBreakdownGroups?.forEach((group) => {
      const worksheet = workbook.addWorksheet(group.groupName);

      const cols = [issuerCol, ...(assessmentQuestionColumns || [])];
      if (!group?.subgroups?.length) {
        worksheet.columns = cols;
        const formattedData = group?.tableData?.map((data) => {
          const assessmentQuestions: Record<string, string> = {};

          data?.assessmentQuestionsData?.forEach((question) => {
            assessmentQuestions[question?.questionId] = question?.answer;
          });
          return {
            issuer: data.companyName,
            ...assessmentQuestions,
          };
        });
        formattedData?.forEach((data) => {
          worksheet.addRow(data);
        });
      }

      if (group?.subgroups?.length) {
        group.subgroups.forEach((subgroup) => {
          const subGroupCol = {
            header: subgroup?.groupCategory,
            key: subgroup?.groupCategory,
            width: 20,
            style: {
              alignment: {
                wrapText: true,
                vertical: 'top',
                horizontal: 'left',
              } as Alignment,
            },
          };

          worksheet.columns = [issuerCol, subGroupCol, ...(assessmentQuestionColumns || [])];
          const formattedData = subgroup?.tableData?.map((data) => {
            const assessmentQuestions: Record<string, string> = {};

            data?.assessmentQuestionsData?.forEach((question) => {
              assessmentQuestions[question?.questionId] = question?.answer;
            });
            return {
              issuer: data.companyName,
              [subgroup?.groupCategory || '']: subgroup?.groupName,
              ...assessmentQuestions,
            };
          });
          formattedData?.forEach((data) => {
            worksheet.addRow(data);
          });
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${getFileName(aggregatedReport?.name || '')}.xlsx`);
  }, [
    aggregatedReport?.name,
    aggregatedReportBreakdownGroups,
    assessmentQuestionColumns,
    issuerCol,
    user?.fullName,
  ]);

  const onExportFlatReport = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet(aggregatedReport?.name || '');

    worksheet.columns = [issuerCol, ...(assessmentQuestionColumns || [])];

    const formattedData = breakdownTableData?.map((data) => {
      const assessmentQuestions: Record<string, string> = {};

      data?.assessmentQuestionsData?.forEach((question) => {
        assessmentQuestions[question?.questionId] = question?.answer;
      });
      return {
        issuer: data.companyName,
        ...assessmentQuestions,
      };
    });

    formattedData?.forEach((data) => {
      worksheet.addRow(data);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${getFileName(aggregatedReport?.name || '')}.xlsx`);
  }, [
    aggregatedReport?.name,
    assessmentQuestionColumns,
    breakdownTableData,
    issuerCol,
    user?.fullName,
  ]);

  const onExport = useCallback(async () => {
    if (!selectedQuestions) return;

    if (aggregatedReportBreakdownGroups?.length) {
      return onExportGroups();
    }

    onExportFlatReport();
  }, [
    aggregatedReportBreakdownGroups?.length,
    onExportFlatReport,
    onExportGroups,
    selectedQuestions,
  ]);

  return (
    <Button
      variant={'outlined'}
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
      onClick={onExport}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Export as Excel
      </Typography>
    </Button>
  );
};
