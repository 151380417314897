import { SelectItem } from '../../types';
import { SingleSelect } from '../SingleSelect/SingleSelect';

interface Props {
  sortedUniqueStages: SelectItem[];
  onChangeStageFilter: (stage: SelectItem | null) => void;
  selectedStage: SelectItem | null;
}

export const StageFilter = ({ sortedUniqueStages, selectedStage, onChangeStageFilter }: Props) => {
  return (
    <SingleSelect
      style={{ width: '200px' }}
      options={sortedUniqueStages}
      value={selectedStage}
      onChange={(_, stage) => onChangeStageFilter(stage)}
      disablePortal
      fieldPlaceholder='Filter by Stage'
    />
  );
};
