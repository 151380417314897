import { styled } from '@mui/material';
import { ReportHeader } from './components/ReportHeader';
import { FiltersBar } from './components/FiltersBar/FiltersBar';
import { Breakdown } from './components/Breakdown/Breakdown';
import { FiltersDrawer } from './components/FiltersDrawer/FiltersDrawer';
import { useAtom, useAtomValue } from 'jotai';
import { aggregatedReportFilterTypeState, aggregatedReportState } from '../../state/UIState';
import { useParams } from 'react-router';
import {
  AGGREGATED_REPORTS,
  useAggregatedReportById,
  useUpdateAggregatedReport,
} from '../../queries/useAggregatedReports';
import { AggregatedReportSkeletonLoader } from '../../components/SkeletonLoader/AggregatedReport.SkeletonLoader';
import { useEffect } from 'react';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { useQueryClient } from 'react-query';

const Wrapper = styled('div')`
  width: 100%;
  overflow-x: hidden;
`;
const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

export const SingleAggregatedReport = () => {
  const filterType = useAtomValue(aggregatedReportFilterTypeState);
  const params = useParams();
  const {
    isLoading,
    data: aggregatedReportData,
    isFetching,
  } = useAggregatedReportById(Number(params.reportId));
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);
  const debouncedAggregatedReport = useDebouncedValue(aggregatedReport, 500);
  const { mutate: updateAggregatedReport } = useUpdateAggregatedReport();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!aggregatedReportData || isLoading || isFetching) return;
    setAggregatedReport(aggregatedReportData);

    return () => {
      setAggregatedReport(null);
      queryClient.refetchQueries(`${AGGREGATED_REPORTS}-${aggregatedReportData.id}`);
    };
  }, [aggregatedReportData, isFetching, isLoading, queryClient, setAggregatedReport]);

  useEffect(() => {
    if (aggregatedReport && debouncedAggregatedReport && !isLoading && !isFetching) {
      const updatedReport = {
        id: debouncedAggregatedReport.id,
        name: debouncedAggregatedReport.name,
        description: debouncedAggregatedReport.description,
        selectedQuestions: debouncedAggregatedReport.selectedQuestions,
        issuerFilters: debouncedAggregatedReport.issuerFilters,
        groupedBy: debouncedAggregatedReport.groupedBy,
        widgets: debouncedAggregatedReport.widgets,
      };
      updateAggregatedReport(updatedReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAggregatedReport]);

  if (isLoading || isFetching) return <AggregatedReportSkeletonLoader />;

  return (
    <Wrapper>
      <ReportHeader />
      <FiltersBar />
      <ContentWrapper>
        {Boolean(filterType) && <FiltersDrawer />}
        <Breakdown />
      </ContentWrapper>
    </Wrapper>
  );
};
