import { createColumnHelper } from '@tanstack/react-table';
import { AggregatedReport } from '../../types';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { Typography } from '../../components/Typography/Typography';
import { formatDate } from '../../utils/formatters';
import { AvatarCell } from '../../components/Table/CellRenderers/AvatarCell';

const columnHelper = createColumnHelper<AggregatedReport>();

export const useReportsColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='subtitle1' color={colors.accent[50]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Report
          </Typography>
        ),
        meta: {
          width: '30%',
        },
      }),
      columnHelper.accessor('description', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue()}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Description
          </Typography>
        ),
        meta: {
          width: '30%',
          maxWidth: '200px',
        },
      }),
      columnHelper.accessor('created_at', {
        cell: (info) => (
          <Typography variant='body' color={colors.primary[90]}>
            {info.getValue() ? formatDate(info.getValue() || '') : ''}
          </Typography>
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Date created
          </Typography>
        ),
        meta: {
          width: '20%',
        },
      }),
      columnHelper.accessor('createdBy', {
        cell: (info) => <AvatarCell name={info.getValue()?.fullName || ''} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Author
          </Typography>
        ),
        meta: {
          width: '20%',
        },
        filterFn: (row: any, columnId, selectedFilters) => {
          const value: string = row.getValue(columnId)?.fullName || '';
          if (!selectedFilters?.length) return true;
          return selectedFilters.includes(value);
        },
        enableColumnFilter: true,
      }),
    ],
    [colors.accent, colors.primary]
  );

  return columns;
};
