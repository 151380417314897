import { styled, useTheme } from '@mui/material';
import { HeaderWrapper } from '../../components/Layout/Layout';
import { HEADER_HEIGHT } from '../../constants/layoutSizes';
import { Typography } from '../../components/Typography/Typography';
import { useEffect, useMemo, useRef } from 'react';
import { NumberOfCompanies } from '../../components/NumberOfCompanies/NumberOfCompanies';
import { CompaniesLastUpdate } from '../../components/CompaniesLastUpdate/CompaniesLastUpdate';
import { PendingCompaniesTable } from './components/PendingCompaniesTable';
import { usePaginatedCompanies } from './usePaginatedCompanies';
import { useAtomValue } from 'jotai';
import {
  pendingCompanySearchState,
  selectedAnalystsFilterState,
  selectedCountriesFilterState,
  selectedSectorsFilterState,
  selectedStageFilterState,
  selectedTypeFilterState,
} from '../../state/UIState';

const Wrapper = styled('div')``;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 28px;
`;

export const PendingEvaluation = () => {
  const { colors } = useTheme();
  const tableRef = useRef<HTMLDivElement>(null);

  const selectedSectors = useAtomValue(selectedSectorsFilterState);
  const selectedCountries = useAtomValue(selectedCountriesFilterState);
  const selectedAnalysts = useAtomValue(selectedAnalystsFilterState);
  const selectedType = useAtomValue(selectedTypeFilterState);
  const selectedStage = useAtomValue(selectedStageFilterState);
  const searchTerm = useAtomValue(pendingCompanySearchState);

  const filters = useMemo(() => {
    return {
      term: searchTerm,
      type: selectedType?.value,
      stage: selectedStage?.value,
      sectors: selectedSectors.map((sector) => sector.id)?.join(', '),
      countries: selectedCountries.map((country) => country.value)?.join(', '),
      analysts: selectedAnalysts.map((analyst) => analyst.value)?.join(', '),
    };
  }, [
    searchTerm,
    selectedAnalysts,
    selectedCountries,
    selectedSectors,
    selectedStage?.value,
    selectedType?.value,
  ]);

  const {
    data: filteredCompanies,
    hasNextPage,
    fetchNextPage,
    isLoading,
    totalNumberOfCompanies,
  } = usePaginatedCompanies(filters);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableRef?.current &&
        tableRef?.current.scrollTop + tableRef?.current.clientHeight >=
          tableRef?.current.scrollHeight
      ) {
        if (hasNextPage) {
          fetchNextPage();
        }
      }
    };

    const container = tableRef?.current;
    container?.addEventListener('scroll', handleScroll);
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, tableRef]);

  return (
    <Wrapper>
      <HeaderWrapper height={HEADER_HEIGHT}>
        <TitleWrapper>
          <Typography variant='h3' color={colors.primary[90]}>
            Pending Evaluation
          </Typography>
          <NumberOfCompanies companiesNum={totalNumberOfCompanies} />
        </TitleWrapper>

        <CompaniesLastUpdate />
      </HeaderWrapper>
      <PendingCompaniesTable
        companiesData={filteredCompanies}
        isLoading={isLoading}
        tableRef={tableRef}
      />
    </Wrapper>
  );
};
