import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { useBreakdownData } from '../../hooks/useBreakdownData';

import { BreakdownTable } from './BreakdownTable/BreakdownTable';
import { GroupedBreakdown } from './GroupedBreakdown/GroupedBreakdown';
import { useAtomValue } from 'jotai';
import { aggregatedReportFilterTypeState } from '../../../../state/UIState';

const Wrapper = styled('div')<{ isFilterDrawerOpen?: boolean }>`
  padding: 16px 40px;
  max-width: ${({ isFilterDrawerOpen }) => (isFilterDrawerOpen ? 'calc(100% - 432px)' : '100%')};
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Breakdown = () => {
  const { colors } = useTheme();
  const filterType = useAtomValue(aggregatedReportFilterTypeState);

  const { breakdownTableData, aggregatedReportBreakdownGroups } = useBreakdownData();

  return (
    <Wrapper isFilterDrawerOpen={Boolean(filterType)}>
      <Typography variant='h4' color={colors.primary[90]}>
        Breakdown
      </Typography>
      {aggregatedReportBreakdownGroups?.length ? (
        <GroupedBreakdown />
      ) : (
        <BreakdownTable tableData={breakdownTableData} />
      )}
    </Wrapper>
  );
};
