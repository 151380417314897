export const readFile = (
  fileToUpload: File,
  callback: (fileBuffer: string | ArrayBuffer | null) => void
) => {
  const reader = new FileReader();
  reader.onload = function () {
    callback(reader.result);
  };
  reader.readAsArrayBuffer(fileToUpload);
};
