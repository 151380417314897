import { FC, useMemo, useState } from 'react';
import { Company, SelectItem, USER_ROLE } from '../../../../types';
import { useUpdateCompany } from '../../../../queries/useCompanies';
import { AssignUserModal } from '../../../AssignUserModal/AssignUserModal';
import { FormField } from '../../../FormField/FormField';
import { SingleSelect } from '../../../SingleSelect/SingleSelect';
import { useUsers } from '../../../../queries/useUsers';
import { UserAvatar } from '../../../UserAvatar/UserAvatar';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../Typography/Typography';

const OptionContentWrapper = styled('div')`
  display: flex;
`;

const OptionWrapper = styled('li')`
  &&&.MuiAutocomplete-option {
    padding: 6px 6px 6px 0;
    height: 40px;
    margin: 4px 0;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const TitleOptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface Props {
  onClose: () => void;
  company: Company;
  isOpen: boolean;
}

export const AnalystModal: FC<Props> = ({ onClose, company, isOpen }) => {
  const updateCompany = useUpdateCompany();
  const { data: users } = useUsers();
  const { colors } = useTheme();

  const isReassign = useMemo(() => Boolean(company?.analyst), [company?.analyst]);

  const options = useMemo(() => {
    return (
      users
        ?.filter((user) => user?.role?.name === USER_ROLE.ANALYST)
        ?.map((u) => ({
          id: u?.id,
          value: u?.fullName,
          icon: <UserAvatar size='medium' name={u?.fullName} />,
        })) || []
    );
  }, [users]);

  const initialAnalystOption = useMemo(() => {
    if (!company?.analyst) return null;
    return options?.find((option) => option.value === company?.analyst) || null;
  }, [company?.analyst, options]);

  const [selectedAnalyst, setSelectedAnalyst] = useState<SelectItem | null>(initialAnalystOption);

  const onSubmit = () => {
    updateCompany.mutate({ id: company.id, analyst: selectedAnalyst?.value });
  };

  return (
    <AssignUserModal
      isOpen={isOpen}
      title={
        isReassign ? 'Reassign ESG Analyst to the Company' : 'Assign ESG Analyst to the Company'
      }
      confirmBtnText={isReassign ? 'Reassign' : 'Assign'}
      onClose={onClose}
      isConfirmDisabled={!selectedAnalyst || company?.analyst === selectedAnalyst?.value}
      companyName={company?.name}
      companyLogo={company?.logoUrl}
      handleSubmit={onSubmit}
      assignedUser={company?.analyst}
    >
      <FormField label={isReassign ? 'New ESG Analyst' : 'ESG Analyst'}>
        <SingleSelect
          options={options}
          value={selectedAnalyst ?? null}
          onChange={(_, option) => setSelectedAnalyst(option)}
          fieldPlaceholder='Select Analyst'
          renderOption={(props, option) => {
            return (
              <OptionWrapper key={option.id} {...props}>
                <OptionContentWrapper>
                  <TitleOptionWrapper>
                    {option?.icon && option?.icon}
                    <Typography variant='body' color={colors.primary[90]}>
                      {option?.value ?? ''}
                    </Typography>
                  </TitleOptionWrapper>
                </OptionContentWrapper>
              </OptionWrapper>
            );
          }}
        />
      </FormField>
    </AssignUserModal>
  );
};
