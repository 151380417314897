import { GOVERNANCE_SCORE_TYPE, SelectItem } from '../../types';
import { SingleSelect } from '../SingleSelect/SingleSelect';

interface Props {
  selectedType: SelectItem | null;
  onChangeTypeFilter: (item: SelectItem | null) => void;
  width?: string;
  placeholder?: string;
}

export const GovernanceScoreFilter = ({
  selectedType,
  onChangeTypeFilter,
  width,
  placeholder,
}: Props) => {
  const governanceScoreTypes = [
    { id: GOVERNANCE_SCORE_TYPE.GOOD, value: GOVERNANCE_SCORE_TYPE.GOOD },
    { id: GOVERNANCE_SCORE_TYPE.BAD, value: GOVERNANCE_SCORE_TYPE.BAD },
  ];

  return (
    <SingleSelect
      style={{ width: width || '250px' }}
      options={governanceScoreTypes}
      value={selectedType}
      onChange={(_, type) => onChangeTypeFilter(type)}
      disablePortal
      fieldPlaceholder={placeholder || 'Filter by Governance Score'}
    />
  );
};
