import { styled } from '@mui/material';
import { AggregatedReportBreakdownGroup } from '../../../../../../types';
import { GroupedBreakdownTable } from './GroupedBreakdownTable';
import { Subgroups } from './Subgroups/Subgroups';
import { RefObject } from 'react';

interface Props {
  group: AggregatedReportBreakdownGroup;
  tablesMap: Record<string, RefObject<HTMLDivElement>>;
  onHorizontalScroll: React.UIEventHandler<HTMLDivElement>;
}

const Container = styled('div')`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.accent[5]};
`;

export const GroupDetails = ({ group, tablesMap, onHorizontalScroll }: Props) => {
  if (!group?.subgroups?.length)
    return (
      <GroupedBreakdownTable
        onHorizontalScroll={onHorizontalScroll}
        tableRef={tablesMap[group.groupName]}
        tableData={group.tableData || []}
      />
    );
  return (
    <Container>
      <Subgroups group={group} subgroups={group.subgroups || []} tablesMap={tablesMap} onHorizontalScroll={onHorizontalScroll} />
    </Container>
  );
};
