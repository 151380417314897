import { styled } from '@mui/material';
import { Loader } from '../Loader';

const LoaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
`;

const Spinner = styled('div')`
  width: 28px;
  height: 28px;
`;

export const TableLoader = () => {
  return (
    <LoaderWrapper>
      <Spinner>
        <Loader />
      </Spinner>
    </LoaderWrapper>
  );
};
