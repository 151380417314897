import { Button, styled, useTheme } from '@mui/material';
import { HeaderWrapper } from '../../components/Layout/Layout';
import { HeaderTitle } from '../../components/Layout/HeaderTitle';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { AllAggregatedReports } from './AllAggregatedReports';
import { Typography } from '../../components/Typography/Typography';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { CREATE_REPORT_PARAMS } from '../../types';

const Wrapper = styled('div')``;

const ContentWrapper = styled('div')`
  padding: 24px 40px;
`;

export const AggregatedReports = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle
          title='Reports'
          description='Create and customize your reports to the regulatory organizations, investors, and internal needs'
        />
        <Button
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
          onClick={() =>
            navigate(
              `/${ROUTES.CREATE_AGGREGATED_REPORT}?${
                CREATE_REPORT_PARAMS.REPORT_NAME
              }=${'New Report'}`
            )
          }
        >
          Create Report
        </Button>
      </HeaderWrapper>
      <ContentWrapper>
        <Typography variant='h4' color={colors.primary[90]}>
          All Reports
        </Typography>
        <AllAggregatedReports />
      </ContentWrapper>
    </Wrapper>
  );
};
