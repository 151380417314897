import { styled } from '@mui/material';
import { RiskLevelFilter } from './components/RiskLevelFilter';
import { OpportunitiesLevelFilter } from './components/OpportunitiesLevelFilter';
import { GovScoreFilter } from './components/GovScoreFilter';
import { TypeFilter } from './components/TypeFilter';
import { ActivePositionFilter } from './components/ActivePositionFilter';
import { CompanySectorsFilter } from './components/CompanySectorsFilter';
import { GeographyFilter } from './components/GeographyFIlter';
import { CompaniesFilter } from './components/CompaniesFilter';

const Wrapper = styled('div')`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


export const IssuersFilters = () => {
  return (
    <Wrapper>
      <CompaniesFilter />
      <RiskLevelFilter />
      <OpportunitiesLevelFilter />
      <GovScoreFilter />
      <TypeFilter />
      <ActivePositionFilter />
      <CompanySectorsFilter />
      <GeographyFilter />
    </Wrapper>
  );
};
