import { IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Typography } from '../Typography/Typography';
import { ReactNode } from 'react';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const ControlsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
`;

interface Props {
  title: string;
  isExpanded?: boolean;
  onExpandToggle: () => void;
  suffixButton?: ReactNode;
  children?: ReactNode;
  collapsedContent?: ReactNode;
}

export const Accordion = ({
  title,
  isExpanded,
  onExpandToggle,
  suffixButton,
  children,
  collapsedContent,
}: Props) => {
  const { colors } = useTheme();
  return (
    <Container>
      <ControlsWrapper>
        <TitleWrapper>
          <IconButton style={{ width: '30px', height: '30px' }} onClick={onExpandToggle}>
            {isExpanded ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {title}
          </Typography>
        </TitleWrapper>
        {suffixButton}
      </ControlsWrapper>
      {!isExpanded && collapsedContent}
      {isExpanded && <ContentWrapper>{children}</ContentWrapper>}
    </Container>
  );
};
