import { useNavigate } from 'react-router';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useCallback, useEffect, useState } from 'react';
import { ROUTES } from '../../constants/routes';
import { ControlButtons } from './components/ControlButtons';
import { useResetCreateReportUiState } from './hooks/useResetCreateReportUIState';
import { CREATE_REPORT_PARAMS } from '../../types';
import { LayoutWithActionsFooter } from '../../components/LayoutWithActionsFooter/LayoutWithActionsFooter';
import { AssessmentsSelect } from './components/AssessmentsSelect';
import { AggregatedReportModal } from '../../components/AggregatedReportModal';
import { useAtom } from 'jotai';
import { reportDescriptionState, reportNameState } from '../../state/UIState';

export const CreateAggregatedReport = () => {
  const navigate = useNavigate();
  const { params: queryParams, setParam } = useQueryParams();
  const resetState = useResetCreateReportUiState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportName, setReportName] = useAtom(reportNameState);
  const [reportDescription, setReportDescription] = useAtom(reportDescriptionState);

  useEffect(() => {
    const reportNameFromParams = queryParams[CREATE_REPORT_PARAMS.REPORT_NAME];
    const reportDescriptionFromParams = queryParams[CREATE_REPORT_PARAMS.REPORT_DESCRIPTION];
    if (reportNameFromParams && !reportName) {
      setReportName(reportNameFromParams as string);
    }

    if (reportDescriptionFromParams && !reportDescription) {
      setReportDescription(reportDescriptionFromParams as string);
    }
  }, [queryParams, reportDescription, reportName, setReportDescription, setReportName]);

  const onConfirm = useCallback(
    ({ name, description }: { name: string; description: string }) => {
      setReportName(name);
      setParam({ param: CREATE_REPORT_PARAMS.REPORT_NAME, value: name });
      setParam({ param: CREATE_REPORT_PARAMS.REPORT_DESCRIPTION, value: description });
      setReportDescription(description);
      setIsModalOpen(false);
    },
    [setParam, setReportDescription, setReportName]
  );

  const onClick = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.AGGREGATED_REPORTS}`);
  }, [navigate, resetState]);

  return (
    <LayoutWithActionsFooter
      headerTitle={reportName}
      onBackClick={onClick}
      onEditHeader={() => setIsModalOpen(true)}
      controlButtons={<ControlButtons />}
    >
      <AssessmentsSelect />
      {isModalOpen && (
        <AggregatedReportModal
          onClose={() => setIsModalOpen(false)}
          onConfirm={onConfirm}
          title='Edit Report Settings'
          confirmBtnText='Save Changes'
          reportName={reportName || ''}
          reportDescription={reportDescription || ''}
        />
      )}
    </LayoutWithActionsFooter>
  );
};
