import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { AppConfig, LoadingId } from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';

export const APP_CONFIG = 'app_config';

async function fetchAppConfig(): Promise<AppConfig> {
  const { data } = await Http.axios.get<AppConfig>(`/config`);
  return data;
}

export function useAppConfig() {
  return useQuery(APP_CONFIG, () => fetchAppConfig(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function updateAppConfig(appConfig: Partial<AppConfig>): Promise<AppConfig> {
  const { data } = await Http.axios.patch<Partial<AppConfig>, AppConfig>(`/config/`, appConfig);
  return data;
}

export function useUpdateAppConfig() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateAppConfig, {
    onMutate: async (newConfig) => {
      startLoading(LoadingId.updateAppConfig);
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(APP_CONFIG);
      // Snapshot the previous value
      const previousConfig = queryClient.getQueryData<AppConfig>(APP_CONFIG);
      // Optimistically update to the new value
      queryClient.setQueryData(APP_CONFIG, {
        ...newConfig,
      });

      return { previousConfig };
    },
    onError: (error, _, context) => {
      console.error({ error });
      // Rollback to the previous value
      queryClient.setQueryData(APP_CONFIG, context?.previousConfig);
      pushErrorToast({ message: 'Failed to update app config' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(APP_CONFIG);
      stopLoading(LoadingId.updateAppConfig);
    },
  });
}
