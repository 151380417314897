import { Outlet, useNavigate, useParams } from 'react-router';
import { StepperLayout } from '../../components/StepperLayout/StepperLayout';
import { useAtom } from 'jotai';
import {
  excelCompaniesState,
  rowSelectionUploadCompaniesState,
  uploadCompaniesProgressState,
  uploadedFileState,
} from '../../state/UIState';
import { useCallback, useMemo } from 'react';
import { ROUTES } from '../../constants/routes';
import { ControlButtons } from './components/ControlButtons';

export const UploadCompaniesStepper = () => {
  const navigate = useNavigate();
  const [, setFile] = useAtom(uploadedFileState);
  const [excelData, setExcelData] = useAtom(excelCompaniesState);
  const [, setRowSelection] = useAtom(rowSelectionUploadCompaniesState);
  const [progress] = useAtom(uploadCompaniesProgressState);
  const params = useParams();

  const steps = useMemo(
    () => [
      {
        step: 1,
        text: 'Upload',
        isClickable: true,
      },
      {
        step: 2,
        text: 'Review',
        isClickable: excelData.length > 0,
      },
    ],
    [excelData.length]
  );

  const onClick = useCallback(() => {
    navigate(`/${ROUTES.COMPANIES}`);
    setFile(null);
    setExcelData([]);
    setRowSelection({});
  }, [navigate, setExcelData, setRowSelection, setFile]);

  const activeStep = Number(params.activeStep);

  const onClickStepNavigate = (step: number) => {
    navigate(`${step}`);
  };

  return (
    <StepperLayout
      onBackClick={onClick}
      headerTitle='Upload List of Companies'
      progressBarText='Complete all steps to upload companies.'
      progress={progress}
      steps={steps}
      activeStep={activeStep}
      onClickStepNavigate={onClickStepNavigate}
      controlButtons={<ControlButtons />}
    >
      <Outlet />
    </StepperLayout>
  );
};
