import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  reportNameState,
  reportDescriptionState,
  selectedAssessmentIdsState,
  assessmentStepsRowSelectionState,
} from '../../../state/UIState';

export function useResetCreateReportUiState() {
  const setReportName = useSetAtom(reportNameState);
  const setReportDescription = useSetAtom(reportDescriptionState);
  const setSelectedAssessmentIds = useSetAtom(selectedAssessmentIdsState);
  const setAssessmentRowSelection = useSetAtom(assessmentStepsRowSelectionState);

  const resetState = useCallback(() => {
    setReportName('');
    setReportDescription('');
    setSelectedAssessmentIds([]);
    setAssessmentRowSelection({});
  }, [setAssessmentRowSelection, setReportDescription, setReportName, setSelectedAssessmentIds]);

  return resetState;
}
