import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueCountries: SelectItem[];
  onChangeCountriesFilter: (countries: SelectItem[]) => void;
  selectedCountries: SelectItem[];
  width?: string;
  placeholder?: string;
}

export const CountriesFilter = ({
  sortedUniqueCountries,
  onChangeCountriesFilter,
  selectedCountries,
  width,
  placeholder,
}: Props) => {
  return (
    <Multiselect
      style={{ width: width || '220px' }}
      options={sortedUniqueCountries}
      value={selectedCountries}
      onChange={(_, val) => {
        onChangeCountriesFilter(val as SelectItem[]);
      }}
      disablePortal
      optionsName='Countries'
      fieldPlaceholder={placeholder || 'Filter by Geography'}
    />
  );
};
