import { FC, useCallback, useState } from 'react';
import { DownloadExcelLink } from './DownloadExcelLink';
import { QuestionTableData } from '../../../../types';
import { UploadExcelArea } from '../../../../components/UploadExcelArea/UploadExcelArea';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { FileItem } from '../../../../components/FileItem/FileItem';
import { Modal } from '../../../../components/Modal/Modal';
import ExcelJS from 'exceljs';
import { readFile } from '../../../../utils/readFile';
interface Props {
  onClose: () => void;
  tableData: QuestionTableData;
  onSynchronize: (uploadedRows: any[][]) => void;
}

export const ImportTableDataModal: FC<Props> = ({ onClose, tableData, onSynchronize }) => {
  const { pushErrorToast } = useToastMessage();
  const [uploadedRows, setUploadedRows] = useState<any[][]>([]);
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (files: File[]) => {
      if (!files.length) {
        pushErrorToast({ message: 'Invalid file type' });
        return;
      }

      const file = files[0];
      setFile(file);

      const workbook = new ExcelJS.Workbook();

      try {
        readFile(file, async (fileBuffer) => {
          if (!fileBuffer || typeof fileBuffer === 'string') return;
          const fileData = await workbook.xlsx.load(fileBuffer);
          const worksheet = fileData.getWorksheet('Core KPIs');

          const rows = worksheet?.getRows(1, worksheet.actualRowCount);

          if (rows?.length !== tableData.rows.length) {
            pushErrorToast({ message: 'Invalid Excel file' });
            return;
          }

          const dataRows = rows?.map((row) =>
            (row?.values as ExcelJS.CellValue[])?.filter?.(
              (r: ExcelJS.CellValue) => typeof r === 'string' || typeof r === 'number'
            )
          );

          setUploadedRows(dataRows as any[][]);
        });
      } catch (e) {
        console.error(e);
      }
    },
    [pushErrorToast, tableData.rows]
  );

  const onConfirm = () => {
    if (!uploadedRows?.length) return;

    onSynchronize(uploadedRows);
    setUploadedRows([]);
    onClose();
  };

  const onClearFile = useCallback(() => {
    setFile(null);
    setUploadedRows([]);
  }, []);

  return (
    <Modal
      title='Synchronize tables'
      confirmBtnText='Synchronize'
      isConfirmDisabled={!uploadedRows?.length}
      onClose={onClose}
      onConfirm={onConfirm}
      isOpen
    >
      <DownloadExcelLink tableData={tableData} />
      {!file && <UploadExcelArea onDrop={onDrop} />}
      {file && (
        <FileItem file={file} onClear={onClearFile} style={{ margin: '20px 0', height: '90px' }} />
      )}
    </Modal>
  );
};
