import { useCallback, useMemo } from 'react';
import { GovernanceScoreFilter } from '../../../../../../components/Filters/GovernanceScoreFilter';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const GovScoreFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const selectedGovernanceScoreType = useMemo(() => {
    if (!aggregatedReport) return null;

    const selectedGovScoreType = aggregatedReport?.issuerFilters?.[
      AGGREGATED_REPORT_ISSUERS_FILTER.GOVERNANCE_SCORE_TYPE
    ] as string;

    if (!selectedGovScoreType) return null;

    return {
      id: selectedGovScoreType,
      value: selectedGovScoreType,
    };
  }, [aggregatedReport]);

  const onChangeGovernanceScoreTypeFilter = useCallback(
    (scoreType: SelectItem | null) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.GOVERNANCE_SCORE_TYPE]: scoreType?.value || null,
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <GovernanceScoreFilter
      width='100%'
      selectedType={selectedGovernanceScoreType}
      onChangeTypeFilter={onChangeGovernanceScoreTypeFilter}
      placeholder='Governance Score'
    />
  );
};
