import { SectorsFilter } from '../../../../../../components/SectorsFilter';
import { AGGREGATED_REPORT_ISSUERS_FILTER, Sector, SelectItem } from '../../../../../../types';
import { useCallback, useMemo } from 'react';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const CompanySectorsFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const sortedUniqueSectors: SelectItem[] = useMemo(() => {
    const allValues: any[] = aggregatedReport?.companies?.map((company) => company.sectors) || [];
    if (!allValues.length) return [];
    const map = allValues.reduce((acc, curr) => {
      curr?.forEach((element: Sector) => {
        acc[element.name] = element.name;
      });
      return acc;
    }, {});
    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
  }, [aggregatedReport?.companies]);

  const selectedSectors = useMemo(() => {
    if (!aggregatedReport) return [];

    const selectedSectorsFilter =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR];

    const hasSelectedSectorsFilter =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR]?.length;

    if (!hasSelectedSectorsFilter) return [];

    return sortedUniqueSectors?.filter((r) => selectedSectorsFilter?.includes(r?.value));
  }, [aggregatedReport, sortedUniqueSectors]);

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR]: sectors?.length
            ? sectors?.map((r) => r?.value)
            : [],
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[]>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <SectorsFilter
      sortedUniqueSectors={sortedUniqueSectors}
      onChangeSectorsFilter={onChangeSectorsFilter}
      selectedSectors={selectedSectors}
      width='100%'
      placeholder='Sectors'
    />
  );
};
