import { styled, TextField } from '@mui/material';
import { Modal } from './Modal/Modal';
import { useState } from 'react';
import { FormField } from './FormField/FormField';

const Wrapper = styled('div')``;

interface Props {
  onClose: () => void;
  onConfirm: ({ name, description }: { name: string; description: string }) => void;
  title: string;
  confirmBtnText: string;
  reportName?: string;
  reportDescription?: string;
}

export const AggregatedReportModal = ({
  onClose,
  onConfirm,
  title,
  confirmBtnText,
  reportName,
  reportDescription,
}: Props) => {
  const [name, setName] = useState(reportName || '');
  const [description, setDescription] = useState(reportDescription || '');

  const onChangeReportName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={true}
      title={title}
      onConfirm={() => onConfirm({ name, description })}
      cancelBtnText='Cancel'
      confirmBtnText={confirmBtnText}
      isConfirmDisabled={!name}
    >
      <Wrapper>
        <FormField label='Name' style={{ marginBottom: '14px' }}>
          <TextField value={name} onChange={onChangeReportName} style={{ width: '100%' }} />
        </FormField>
        <FormField label='Description'>
          <TextField value={description} onChange={onDescriptionChange} style={{ width: '100%' }} />
        </FormField>
      </Wrapper>
    </Modal>
  );
};
