import { useCallback, useEffect, useMemo } from 'react';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import {
  useCoreKpiCategories,
  useCreateCoreKpiCategory,
  useUpdateCoreKpiCategories,
  useUpdateCoreKpiCategory,
} from '../../../queries/useCoreKpiCategories';
import { CoreKpiCategory } from '../../../types';
import { useAtom } from 'jotai';
import { activeKpiCategoryState, sortedKpiCategoriesState } from '../../../state/UIState';

export function useSortedCoreKpiCategories() {
  const { data: appConfig, isLoading: isConfigLoading } = useAppConfig();
  const { data: coreKpiCategories, isLoading: areCategoriesLoading } = useCoreKpiCategories();
  const [sortedItems, setSortedItems] = useAtom(sortedKpiCategoriesState);
  const [, setActiveCategory] = useAtom(activeKpiCategoryState);
  const updatePageConfig = useUpdateAppConfig();
  const updateCoreKpiCategories = useUpdateCoreKpiCategories();
  const createCoreKpiCategory = useCreateCoreKpiCategory();
  const updateCoreKpiCategory = useUpdateCoreKpiCategory();
  useEffect(() => {
    if (!coreKpiCategories || !appConfig || isConfigLoading || areCategoriesLoading) {
      return;
    }
    setSortedItems(coreKpiCategories);
    setActiveCategory((prev) => {
      const prevActive = coreKpiCategories?.find((category) => category.id === prev?.id);
      return prevActive || coreKpiCategories[0];
    });
  }, [
    coreKpiCategories,
    appConfig,
    setSortedItems,
    isConfigLoading,
    areCategoriesLoading,
    setActiveCategory,
  ]);

  const onReorder = useCallback(
    async (newOrder: CoreKpiCategory[]) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      setSortedItems(newOrder);
      const payload = newOrder.map((item) => ({
        id: item.id,
        order: item.order,
      }));
      await updateCoreKpiCategories.mutateAsync(
        {
          data: payload,
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
        }
      );
      await updatePageConfig.mutateAsync({
        data: {
          ...appConfig?.data,
          isCoreKpiSurveyUpToDate: false,
        },
      });
    },
    [appConfig?.data, setSortedItems, sortedItems, updateCoreKpiCategories, updatePageConfig]
  );

  const onAddCategory = useCallback(async () => {
    if (!appConfig?.data) {
      return;
    }
    const newCategory = await createCoreKpiCategory.mutateAsync({
      name: 'New Category',
      order: sortedItems.length + 1,
    });
    const newOrder = [...sortedItems, { ...newCategory, isNew: true }];
    const payload = newOrder.map((item) => ({
      id: item.id,
      order: item.order,
    }));
    await updateCoreKpiCategories.mutateAsync(
      {
        data: payload,
      },
      {
        onSuccess: () => {
          setSortedItems(newOrder);
          setActiveCategory(newCategory);
        },
      }
    );
    await updatePageConfig.mutateAsync({
      data: {
        ...appConfig?.data,
        isCoreKpiSurveyUpToDate: false,
      },
    });
  }, [
    appConfig?.data,
    createCoreKpiCategory,
    setActiveCategory,
    setSortedItems,
    sortedItems,
    updateCoreKpiCategories,
    updatePageConfig,
  ]);

  const onRemoveCategory = useCallback(
    async (id: number) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      const newOrder = sortedItems.filter((item) => item.id !== id);
      await updateCoreKpiCategory.mutateAsync({ id, isDeleted: true });
      const payload = newOrder.map((item, i) => ({
        id: item.id,
        order: i + 1,
      }));
      await updateCoreKpiCategories.mutateAsync(
        {
          data: payload,
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
          onSuccess: () => {
            setActiveCategory(newOrder[0] ? newOrder[0] : null);
          },
        }
      );
      await updatePageConfig.mutateAsync({
        data: {
          ...appConfig?.data,
          isCoreKpiSurveyUpToDate: false,
        },
      });
    },
    [
      appConfig?.data,
      setActiveCategory,
      setSortedItems,
      sortedItems,
      updateCoreKpiCategories,
      updateCoreKpiCategory,
      updatePageConfig,
    ]
  );

  const onRenameCategory = useCallback(
    async (id: number, name: string) => {
      await updateCoreKpiCategory.mutateAsync(
        { id, name },
        {
          onSuccess: async () => {
            if (!appConfig?.data) {
              return;
            }
            await updatePageConfig.mutateAsync({
              data: {
                ...appConfig?.data,
                isCoreKpiSurveyUpToDate: false,
              },
            });
          },
        }
      );
    },
    [appConfig?.data, updateCoreKpiCategory, updatePageConfig]
  );

  const value = useMemo(() => {
    return {
      items: sortedItems as CoreKpiCategory[],
      isLoading: isConfigLoading || areCategoriesLoading,
      onReorder,
      onAddCategory,
      onRemoveCategory,
      onRenameCategory,
    };
  }, [
    areCategoriesLoading,
    isConfigLoading,
    onAddCategory,
    onRemoveCategory,
    onReorder,
    sortedItems,
    onRenameCategory,
  ]);

  return value;
}
