import { Button, styled } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';

import { useCallback } from 'react';
import { ROUTES } from '../../../constants/routes';
import { useNavigate } from 'react-router';
import { HeaderTitle } from '../../../components/Layout/HeaderTitle';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SurveysHeader = () => {
  const navigate = useNavigate();

  const onCreateSurveyClick = useCallback(() => {
    navigate(`/${ROUTES.CREATE_SURVEY}/build`);
  }, [navigate]);

  return (
    <MainSection>
      <HeaderTitle
        title='Surveys'
        description='ESG survey evaluates sustainability via ESG practices.'
      />
      <ControllsWrapper>
        <Button
          onClick={onCreateSurveyClick}
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
        >
          Create Survey
        </Button>
      </ControllsWrapper>
    </MainSection>
  );
};
