import { useState } from 'react';
import { RoundedAccordion } from '../../../../../components/RoundedAccordion/RoundedAccordion';
import { AggregatedReportBreakdownGroup } from '../../../../../types';
import { GroupSummary } from './GroupSummary';
import { GroupDetails } from './GroupDetails/GroupDetails';

interface Props {
  group: AggregatedReportBreakdownGroup;
  tablesMap: Record<string, HTMLDivElement>;
  onScroll: React.UIEventHandler<HTMLDivElement>;
}

export const GroupAccordion = ({ group, tablesMap, onScroll }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <RoundedAccordion
      isExpanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      accordionSummary={<GroupSummary group={group} />}
      accordionDetails={<GroupDetails onScroll={onScroll} tablesMap={tablesMap} group={group} />}
      hasDetails
      containerStyles={{ borderRadius: '10px' }}
    />
  );
};
