import { styled } from '@mui/material';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SelectItem, USER_ROLE } from '../../../types';
import { StageFilter } from '../../../components/Filters/StageFilter';
import { SectorsFilter } from '../../../components/Filters/SectorsFilter';
import { CountriesFilter } from '../../../components/Filters/CountriesFilter';
import { AnalystFilter } from '../../../components/Filters/AnalystFilter';
import { ResetFiltersButton } from '../../../components/ResetFiltersButton/ResetFiltersButton';
import { useCallback, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  pendingCompanySearchState,
  selectedAnalystsFilterState,
  selectedCountriesFilterState,
  selectedSectorsFilterState,
  selectedStageFilterState,
  selectedTypeFilterState,
} from '../../../state/UIState';
import { CompanyTypeFilter } from '../../../components/Filters/CompanyTypeFilter';
import { useIssuersPendingEvaluation } from '../../../hooks/useIssuersPendingEvaluation';

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export const CompanyFilters = () => {
  const { companiesData: companies } = useIssuersPendingEvaluation();

  const [selectedSectors, setSelectedSectors] = useAtom(selectedSectorsFilterState);
  const [selectedCountries, setSelectedCountries] = useAtom(selectedCountriesFilterState);
  const [selectedAnalysts, setSelectedAnalysts] = useAtom(selectedAnalystsFilterState);
  const [selectedType, setSelectedType] = useAtom(selectedTypeFilterState);
  const [selectedStage, setSelectedStage] = useAtom(selectedStageFilterState);
  const [searchTerm, setSearchTerm] = useAtom(pendingCompanySearchState);

  const activeRole = useAtomValue(activeRoleState);

  const sortedUniqueStages = useMemo(() => {
    const unique = companies
      ?.map((c) => c.stage)
      .filter((stage, index, self) => self.indexOf(stage) === index)
      .sort()
      .map((value, id) => ({ id, value }));
    return unique;
  }, [companies]);

  const onChangeStageFilter = useCallback(
    (stage: SelectItem | null) => {
      setSelectedStage(stage);
    },
    [setSelectedStage]
  );

  const sortedUniqueSectors = useMemo(() => {
    const allSectors = companies
      ?.map((c) => c.sectors)
      ?.flat()
      ?.filter((sector, index, self) => index === self.findIndex((s) => s.id === sector.id))
      ?.sort()
      ?.map((value) => ({ id: value.id, value: value.name }));

    return allSectors;
  }, [companies]);

  const sortedUniqueCountries = useMemo(() => {
    const allCountries = companies
      ?.map((c) => c.country)
      ?.filter((country, index, self) => Boolean(country) && self.indexOf(country) === index)
      ?.sort()
      ?.map((value, id) => ({ id, value }));
    return allCountries;
  }, [companies]);

  const sortedUniqueAnalysts = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    )
      return [];
    const allValues = companies
      ?.map((c) => c.analyst)
      ?.filter((analyst, index, self) => Boolean(analyst) && self.indexOf(analyst) === index)
      ?.sort()
      ?.map((value, id) => ({ id, value }));
    return allValues;
  }, [activeRole, companies]);

  const sortedUniqueTypes = useMemo(() => {
    const unique = companies
      ?.map((c) => c.type)
      ?.filter((type, index, self) => self.indexOf(type) === index)
      ?.sort()
      ?.map((value, id) => ({ id, value }));
    return unique;
  }, [companies]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedType(type);
    },
    [setSelectedType]
  );

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      setSelectedSectors(sectors);
    },
    [setSelectedSectors]
  );

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCountries(countries);
    },
    [setSelectedCountries]
  );

  const onChangeAnalystsFilter = useCallback(
    (analysts: SelectItem[]) => {
      setSelectedAnalysts(analysts);
    },
    [setSelectedAnalysts]
  );

  const onResetFilters = useCallback(() => {
    setSelectedSectors([]);
    setSelectedCountries([]);
    setSelectedAnalysts([]);
    setSelectedType(null);
    setSelectedStage(null);
  }, [
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    setSelectedStage,
    setSelectedType,
  ]);

  const isResetDisabled = useMemo(() => {
    return (
      !selectedSectors.length &&
      !selectedCountries.length &&
      !selectedAnalysts.length &&
      !selectedType &&
      !selectedStage
    );
  }, [
    selectedAnalysts.length,
    selectedCountries.length,
    selectedSectors.length,
    selectedStage,
    selectedType,
  ]);

  if (
    activeRole &&
    [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
  ) {
    return (
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => setSearchTerm('')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm || ''}
        />
        <CompanyTypeFilter
          selectedType={selectedType}
          sortedUniqueTypes={sortedUniqueTypes || []}
          onChangeTypeFilter={onChangeTypeFilter}
        />
        <SectorsFilter
          sortedUniqueSectors={sortedUniqueSectors || []}
          onChangeSectorsFilter={onChangeSectorsFilter}
          selectedSectors={selectedSectors}
        />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
    );
  }

  return (
    <FiltersWrapper>
      <SearchInput
        placeholder='Search for a company'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => setSearchTerm('')}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm || ''}
      />
      <CompanyTypeFilter
        selectedType={selectedType}
        sortedUniqueTypes={sortedUniqueTypes || []}
        onChangeTypeFilter={onChangeTypeFilter}
      />
      <StageFilter
        selectedStage={selectedStage}
        sortedUniqueStages={sortedUniqueStages || []}
        onChangeStageFilter={onChangeStageFilter}
      />
      <SectorsFilter
        sortedUniqueSectors={sortedUniqueSectors || []}
        onChangeSectorsFilter={onChangeSectorsFilter}
        selectedSectors={selectedSectors}
      />
      <CountriesFilter
        sortedUniqueCountries={sortedUniqueCountries || []}
        onChangeCountriesFilter={onChangeCountriesFilter}
        selectedCountries={selectedCountries}
      />
      <AnalystFilter
        sortedUniqueAnalysts={sortedUniqueAnalysts || []}
        selectedAnalysts={selectedAnalysts}
        onChangeAnalystsFilter={onChangeAnalystsFilter}
      />
      <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
    </FiltersWrapper>
  );
};
