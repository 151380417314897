import { useAtom } from 'jotai';
import {
  activeChecklistCategoryState,
  checklistTableRowSelectionState,
  isConfirmChecklistBarActiveState,
  isMoveChecklistModeActiveState,
  selectedChecklistCategoryState,
} from '../../../state/UIState';
import {
  DataCollectionSideSection,
  SideSectionList,
} from '../../../components/DataCollectionLayout';
import { useCallback } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CategoryItem, ChecklistCategory } from '../../../types';
import { useSortedChecklistCategories } from '../hooks/useSortedChecklistCategories';

export const SideSection = () => {
  const { onAddCategory, items, onReorder, onRemoveCategory, onRenameCategory } =
    useSortedChecklistCategories();
  const [isConfirmMoveBarActive] = useAtom(isConfirmChecklistBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveChecklistModeActiveState);
  const [activeCategory, setActiveCategory] = useAtom(activeChecklistCategoryState);
  const [selectedChecklistCategory, setSelectedChecklistCategory] = useAtom(
    selectedChecklistCategoryState
  );
  const [, setRowSelection] = useAtom(checklistTableRowSelectionState);

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (items && over?.id && active.id !== over.id) {
        const oldIndex = items.findIndex((s) => s.id === active.id);
        const newIndex = items.findIndex((s) => s.id === over.id);
        const reorderedCategories = arrayMove(items, oldIndex, newIndex).map((category, index) => ({
          ...category,
          order: index + 1,
        }));
        onReorder(reorderedCategories);
      }
    },
    [items, onReorder]
  );

  function handleClick(item: CategoryItem) {
    if (isMoveModeActive && item.id === activeCategory?.id) {
      return;
    }
    if (isMoveModeActive) {
      setSelectedChecklistCategory(item as ChecklistCategory);
      return;
    }
    setActiveCategory(item as ChecklistCategory);
    setRowSelection({});
  }

  return (
    <DataCollectionSideSection
      title='Categories'
      isActive={isConfirmMoveBarActive}
      onAddCategory={onAddCategory}
    >
      <SideSectionList
        items={items}
        handleReorder={handleReorder}
        handleClick={handleClick}
        activeCategory={activeCategory}
        onRenameCategory={onRenameCategory}
        onRemoveCategory={onRemoveCategory}
        selectedCategory={selectedChecklistCategory}
        isMoveModeActive={isMoveModeActive}
        deleteModalTitle='Delete the checklist category?'
        deleteModalNote='All checklist items inside will be lost.'
      />
    </DataCollectionSideSection>
  );
};
