import { useCallback, useMemo } from 'react';
import { flattenTree } from '../../../../utils/treeUtilities';
import {
  CORE_KPI_FORMAT,
  KPISValueResponse,
  QuestionTableData,
  Response,
  Survey,
} from '../../../../types';
import { Typography } from '../../../../components/Typography/Typography';
import { styled, useTheme } from '@mui/material';
import { CoreKpiTable } from './CoreKpiTable';
import { ExportButton } from '../../../../components/ExportButton';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { useAtomValue } from 'jotai';
import { userState } from '../../../../state/UIState';
import {
  HEADER_COLUMN_ID,
  HEADER_ROW_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../constants/defaultValues';
import { getCoreKPICellValue } from '../../../../utils/getCoreKpiCellValue';
import { getFileName } from '../../../../utils/getFileName';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 40px 40px 40px;
`;

const SectionWrapper = styled('div')`
  margin-bottom: 20px;
`;

const TableWrapper = styled('div')`
  width: 100%;
`;

const ExportButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  survey: Survey;
  response: Record<string, Response>;
}

export const CoreKpiSingleAssessment = ({ survey, response }: Props) => {
  const { colors } = useTheme();

  const user = useAtomValue(userState);

  const coreKpiAssessmentData = useMemo(() => {
    const sections = flattenTree(survey.sections);
    return sections.map((section) => section);
  }, [survey.sections]);

  const onExportCoreKpi = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    coreKpiAssessmentData?.forEach((section) => {
      const worksheet = workbook.addWorksheet(section?.name);

      const headerColsIds = (response[section.questions[0].id] as QuestionTableData)?.columns;
      const responseRows = (response[section.questions[0].id] as QuestionTableData)?.rows;

      const headerRow = section?.questions?.[0]?.tableData?.rows?.find(
        (row) => row.id === HEADER_ROW_ID
      );

      const cols = headerColsIds?.map((headerId) => {
        let width = 10;

        if (headerId === HEADER_COLUMN_ID) {
          width = 25;
        }

        if (headerId === KPI_DEFINITION_COLUMN_ID) {
          width = 40;
        }

        if (headerId === KPI_FORMAT_COLUMN_ID) {
          width = 15;
        }
        return {
          header: headerRow?.[headerId] as string,
          key: headerId,
          width,
        };
      });

      worksheet.columns = cols;

      const rows = responseRows
        ?.filter((row) => row.id !== HEADER_ROW_ID)
        ?.map((row) => {
          const kpiValue = getCoreKPICellValue(
            (row?.[KPI_VALUE_COLUMN_ID] as KPISValueResponse)?.value,
            true,
            row?.[KPI_FORMAT_COLUMN_ID] as CORE_KPI_FORMAT
          );
          return {
            [HEADER_COLUMN_ID]: row?.[HEADER_COLUMN_ID],
            [KPI_DEFINITION_COLUMN_ID]: row?.[KPI_DEFINITION_COLUMN_ID],
            [KPI_FORMAT_COLUMN_ID]: row?.[KPI_FORMAT_COLUMN_ID],
            [KPI_METRIC_COLUMN_ID]: row?.[KPI_METRIC_COLUMN_ID],
            [KPI_VALUE_COLUMN_ID]: kpiValue,
          };
        });

      rows.forEach((row) => {
        worksheet.addRow(row);
      });

      worksheet.getColumn(HEADER_COLUMN_ID).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_DEFINITION_COLUMN_ID).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_FORMAT_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_METRIC_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };
      worksheet.getColumn(KPI_VALUE_COLUMN_ID).alignment = {
        vertical: 'top',
        horizontal: 'left',
      };

      worksheet.eachRow((row) => {
        const formatCell = row.getCell(KPI_FORMAT_COLUMN_ID);

        if (
          formatCell.value === CORE_KPI_FORMAT.NUMBER ||
          formatCell.value === CORE_KPI_FORMAT.PERCENTAGE
        ) { 
          row.getCell(KPI_VALUE_COLUMN_ID).alignment = {
            vertical: 'top',
            horizontal: 'right',
          };
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${getFileName(survey.name)}.xlsx`);
  }, [coreKpiAssessmentData, response, survey.name, user?.fullName]);

  return (
    <Wrapper>
      <ExportButtonWrapper>
        <ExportButton onExport={() => onExportCoreKpi()} isOutlined />
      </ExportButtonWrapper>
      {coreKpiAssessmentData?.map((section) => {
        return (
          <SectionWrapper key={section.id}>
            <Typography variant='body' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
              {section.name}
            </Typography>
            <TableWrapper>
              <CoreKpiTable
                response={response[section.questions[0].id] as QuestionTableData}
                questionId={section.questions[0].id}
              />
            </TableWrapper>
          </SectionWrapper>
        );
      })}
    </Wrapper>
  );
};
