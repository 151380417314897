import { styled } from '@mui/material';

import { FilterButton } from './FilterButton';
import { useAtom } from 'jotai';
import { aggregatedReportFilterTypeState } from '../../../../state/UIState';
import { AGGREGATED_REPORT_FILTER } from '../../../../types';
import { useBreakdownData } from '../../hooks/useBreakdownData';

const Wrapper = styled('div')`
  padding: 7px 40px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  display: flex;
`;

export const FiltersBar = () => {
  const [filterType, setFilterType] = useAtom(aggregatedReportFilterTypeState);
  const { selectedQuestions, filteredCompanies, groupedBy } = useBreakdownData();

  const onToggleFilterDrawer = (type: AGGREGATED_REPORT_FILTER) => {
    if (filterType === type) {
      setFilterType(null);
      return;
    }
    setFilterType(type);
  };

  return (
    <Wrapper>
      <FilterButton
        label='Questions'
        badgeNum={selectedQuestions?.length}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.QUESTIONS)}
      />
      <FilterButton
        label='Issuers'
        badgeNum={filteredCompanies?.length}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.ISSUERS)}
      />
      <FilterButton
        label='Groups'
        badgeNum={groupedBy?.length || 0}
        onClick={() => onToggleFilterDrawer(AGGREGATED_REPORT_FILTER.GROUPS)}
      />
    </Wrapper>
  );
};
