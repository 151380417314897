import { Multiselect } from '../Multiselect/Multiselect';
import { SelectItem } from '../../types';

interface Props {
  sortedUniqueOpportunities: SelectItem[];
  selectedOpportunities: SelectItem[];
  onChangeSelectedOpportunities: (opportunities: SelectItem[]) => void;
  width?: string;
  placeholder?: string;
}

export const OpportunitiesFilter = ({
  sortedUniqueOpportunities,
  selectedOpportunities,
  onChangeSelectedOpportunities,
  width,
  placeholder,
}: Props) => {
  return (
    <Multiselect
      style={{ width: width || '255px' }}
      options={sortedUniqueOpportunities}
      value={selectedOpportunities}
      onChange={(_, val) => onChangeSelectedOpportunities(val as SelectItem[])}
      disablePortal
      optionsName='ESG Opportunities'
      fieldPlaceholder={placeholder || 'Filter by ESG Opportunities'}
      selectedTextMaxWidth='9rem'
    />
  );
};
