import { Button, styled, useTheme } from '@mui/material';
import { HeaderWrapper } from '../../../components/Layout/Layout';
import { PAGE_HEADER_HEIGHT } from '../../../constants/layoutSizes';
import { Typography } from '../../../components/Typography/Typography';
import { NumberOfCompanies } from '../../../components/NumberOfCompanies/NumberOfCompanies';
import { CompaniesLastUpdate } from '../../../components/CompaniesLastUpdate/CompaniesLastUpdate';
import { SyncCompaniesButton } from './SyncCompaniesButton';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue-small.svg';

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SyncWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InnerTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 28px;
`;

interface Props {
  companiesNum: number;
}
export const CompaniesHeader = ({ companiesNum }: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <HeaderWrapper height={PAGE_HEADER_HEIGHT}>
      <TitleWrapper>
        <InnerTitleWrapper>
          <Typography variant='h3' color={colors.primary[90]}>
            Companies
          </Typography>
          <NumberOfCompanies companiesNum={companiesNum} />
        </InnerTitleWrapper>

        <Typography variant='body' color={colors.primary[70]}>
          Displays owned companies in the fund.
        </Typography>
      </TitleWrapper>
      <SyncWrapper>
        <CompaniesLastUpdate />

        <SyncCompaniesButton />
        <Button
          onClick={() => navigate(`/${ROUTES.EXPORT_COMPANIES}/1`)}
          startIcon={<DownloadIcon />}
          variant='outlined'
          style={{ width: '114px' }}
        >
          Export
        </Button>
        <Button
          onClick={() => navigate(`/${ROUTES.UPLOAD_COMPANIES}/1`)}
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
        >
          Upload Companies
        </Button>
      </SyncWrapper>
    </HeaderWrapper>
  );
};
