import { useMemo, useState } from 'react';
import { Modal } from '../../../../../../components/Modal/Modal';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { QUESTION_TYPE, SelectItem, WIDGET_TYPE } from '../../../../../../types';
import { SingleSelect } from '../../../../../../components/SingleSelect/SingleSelect';
import { ChipsSelectorV2 } from '../../../../../../components/ButtonSelector/ChipsSelectorV2';
import { FormField } from '../../../../../../components/FormField/FormField';
import { styled } from '@mui/material';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const widgetTypeOptions: SelectItem[] = [
  {
    id: WIDGET_TYPE.BAR_CHART,
    value: 'Bar Chart',
  },
  {
    id: WIDGET_TYPE.PIE_CHART,
    value: 'Pie Chart',
  },
  {
    id: WIDGET_TYPE.COMPANY_BREAKDOWN,
    value: 'Company Breakdown',
  },
];

interface Props {
  isOpen: boolean;
  title: string;
  confirmBtnText: string;
  onClose: () => void;
  handleSubmit: ({
    questionId,
    widgetType,
  }: {
    questionId: string;
    widgetType: WIDGET_TYPE;
  }) => void;
  widgetQuestionId?: string;
  widgetType?: WIDGET_TYPE;
}
export const WidgetModal = ({
  isOpen,
  title,
  confirmBtnText,
  onClose,
  handleSubmit,
  widgetQuestionId,
  widgetType,
}: Props) => {
  const { selectedQuestions } = useBreakdownData();

  const initialSelectedQuestion = useMemo(() => {
    const init = selectedQuestions?.find((q) => q?.id === widgetQuestionId);

    if (!init) return null;

    return { id: init?.id, value: init?.name };
  }, [selectedQuestions, widgetQuestionId]);

  const initialWidgetType = useMemo(() => {
    return widgetTypeOptions?.find((option) => option.id === widgetType);
  }, [widgetType]);

  const [selectedQuestion, setSelectedQuestion] = useState<SelectItem | null>(
    initialSelectedQuestion
  );
  const [selectedType, setSelectedType] = useState<SelectItem | null>(
    initialWidgetType || widgetTypeOptions[0]
  );

  const questionOptions: SelectItem[] = useMemo(() => {
    return selectedQuestions
      ?.filter((q) => q?.type !== QUESTION_TYPE.TEXT && q?.type !== QUESTION_TYPE.TABLE)
      ?.map((q) => {
        if (!q) return null;
        return { id: q?.id, value: q?.name };
      })
      ?.filter((q) => Boolean(q)) as SelectItem[];
  }, [selectedQuestions]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={() =>
        handleSubmit({
          questionId: selectedQuestion?.id as string,
          widgetType: selectedType?.id as WIDGET_TYPE,
        })
      }
      isConfirmDisabled={!selectedQuestion || !selectedType}
    >
      <Container>
        <FormField label={'Question'}>
          <SingleSelect
            style={{ width: '100%' }}
            options={questionOptions}
            value={selectedQuestion}
            onChange={(_, questionOption) => setSelectedQuestion(questionOption)}
            disablePortal
            fieldPlaceholder={'Select Question'}
          />
        </FormField>
        <FormField label={'Widget Type'}>
          <ChipsSelectorV2
            options={widgetTypeOptions}
            selectedItem={selectedType}
            onSelect={(type) => setSelectedType(type)}
          />
        </FormField>
      </Container>
    </Modal>
  );
};
