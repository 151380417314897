import { useAtom } from 'jotai';
import { selectedChecklistItemState } from '../../../state/UIState';

import { useCallback, useState } from 'react';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { useMoveChecklistItem } from './useMoveChecklistItem';
import { useMoveChecklistItems } from './useMoveChecklistItems';
import { useUpdateChecklistSurveys } from './useUpdateChecklistSurveys';
import {
  useCreateChecklistDbScreenshot,
  useResetChecklistDbTablesFromScreenshot,
} from '../../../queries/useChecklistItems';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useToastMessage } from '../../../hooks/useToastMessage';

export const useConfirmBarActions = () => {
  const [selectedItem] = useAtom(selectedChecklistItemState);
  const createDbScreenshot = useCreateChecklistDbScreenshot();
  const resetFromScreenshot = useResetChecklistDbTablesFromScreenshot();
  const { data: appConfig } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { pushSuccessToast } = useToastMessage();
  const [areControlsDisabled, setAreControlsDisabled] = useState(false);

  const { onCancel } = useOnCancelConfirm();

  const { moveChecklistItem } = useMoveChecklistItem();
  const { moveChecklistItems } = useMoveChecklistItems();

  const { onUpdateChecklistSurveys: updateChecklistSurveys } = useUpdateChecklistSurveys();

  const onUpdateChecklistSurveys = useCallback(async () => {
    setAreControlsDisabled(true);
    createDbScreenshot.mutate();
    await updateChecklistSurveys();
    setAreControlsDisabled(false);
  }, [createDbScreenshot, updateChecklistSurveys]);

  const onConfirm = useCallback(async () => {
    if (selectedItem) return moveChecklistItem();
    return moveChecklistItems();
  }, [moveChecklistItem, moveChecklistItems, selectedItem]);

  const onResetFromScreenshot = useCallback(async () => {
    setAreControlsDisabled(true);
    await resetFromScreenshot.mutateAsync();
    if (!appConfig?.data) return;
    await updateAppConfig.mutateAsync({
      data: { ...appConfig.data, areCheckListSurveysUpToDate: true },
    });
    pushSuccessToast({
      message:
        'All changes have been successfully discarded, and checklist has been reverted to the previously applied state.',
    });
    setAreControlsDisabled(false);
  }, [appConfig?.data, pushSuccessToast, resetFromScreenshot, updateAppConfig]);

  return {
    onCancel,
    onConfirm,
    onUpdateChecklistSurveys,
    onResetFromScreenshot,
    areControlsDisabled,
  };
};
