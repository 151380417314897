import { IconButton, styled, useTheme } from '@mui/material';
import { Breadcrumbs } from '../../../components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../../constants/routes';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as EditIconSvg } from '../../../assets/icons/edit.svg';

import { useState } from 'react';
import { AggregatedReportModal } from '../../../components/AggregatedReportModal';
import { ExportButton } from './ExportButton';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../state/UIState';

const Container = styled('div')`
  padding: 20px 40px;
`;

const TitleContainer = styled('div')`
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const EditIcon = styled(EditIconSvg)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

export const ReportHeader = () => {
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onConfirm = ({ name, description }: { name: string; description: string }) => {
    if (!aggregatedReport) return;
    setAggregatedReport({ ...aggregatedReport, name, description });
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Breadcrumbs
        prevRoutes={[{ prevRoute: `/${ROUTES.AGGREGATED_REPORTS}`, prevRouteName: 'Reports' }]}
        currentRouteName={aggregatedReport?.name || ''}
      />
      <TitleContainer>
        <TitleWrapper>
          <Typography variant='h4' color={colors.primary[90]}>
            {aggregatedReport?.name || ''}
          </Typography>
          <IconButton onClick={() => setIsModalOpen(true)}>
            <EditIcon />
          </IconButton>
        </TitleWrapper>
        <ExportButton />
      </TitleContainer>
      {isModalOpen && (
        <AggregatedReportModal
          onClose={onCloseModal}
          onConfirm={onConfirm}
          title='Edit Report'
          confirmBtnText='Confirm'
          reportName={aggregatedReport?.name || ''}
          reportDescription={aggregatedReport?.description || ''}
        />
      )}
    </Container>
  );
};
