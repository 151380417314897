import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  title: string;
  description?: string;
}

export const HeaderTitle = ({ title, description }: Props) => {
  const { colors } = useTheme();

  return (
    <TitleWrapper>
      <Typography variant='h3' color={colors.primary[90]}>
        {title}
      </Typography>
      {description && (
        <Typography variant='body' color={colors.primary[70]}>
          {description}
        </Typography>
      )}
    </TitleWrapper>
  );
};
