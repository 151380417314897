import { styled } from '@mui/material';
import { HeaderWrapper } from '../Layout/Layout';
import { ReactNode } from 'react';
import { HeaderTitle } from '../Layout/HeaderTitle';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

interface Props {
  title: string;
  description: string;
  children?: ReactNode;
}

export const DataCollectionHeader = ({ title, description, children }: Props) => {
  return (
    <HeaderWrapper>
      <Wrapper>
        <HeaderTitle title={title} description={description} />
        {children}
      </Wrapper>
    </HeaderWrapper>
  );
};
