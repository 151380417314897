import { styled } from '@mui/material';
import { StagesManagement } from '../components/StagesManagement';

import { PortCoStagesList } from './PortCoStagesList/PortCoStagesList';
import { useCallback, useState } from 'react';

import { useCreateStage, useWorkflowStages } from '../../../queries/useWorkflowStages';
import { COMPANY_TYPE } from '../../../types';
import { ConfirmActionBar } from '../../../components/ConfirmActionBar/ConfirmActionBar';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useUpdateWorkflow, useWorkflows } from '../../../queries/useWorkflows';
import {
  useCreateWorkflowsDbScreenshot,
  useResetWorkflowsDbTablesFromScreenshot,
} from '../../../queries/useWorkflowSteps';
import { useToastMessage } from '../../../hooks/useToastMessage';

const Wrapper = styled('div')``;

export const PortCoWorkflow = () => {
  const { data: stages } = useWorkflowStages();
  const { mutate: createStage } = useCreateStage();
  const { data: appConfigData } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const { data: workflows } = useWorkflows();
  const { mutateAsync: updateWorkflow } = useUpdateWorkflow();
  const createWorkflowsDbScreenshot = useCreateWorkflowsDbScreenshot();
  const resetFromScreenshot = useResetWorkflowsDbTablesFromScreenshot();
  const { pushSuccessToast } = useToastMessage();
  const [areControlsDisabled, setAreControlsDisabled] = useState(false);

  const onUpdateWorkflows = async () => {
    setAreControlsDisabled(true);
    createWorkflowsDbScreenshot.mutate({ type: COMPANY_TYPE.CONTROLLED });

    const workflowId = workflows?.find(
      (workflow) => workflow.companyType === COMPANY_TYPE.CONTROLLED
    )?.id;

    const configStages = stages?.map((stage) => {
      return {
        ...stage,
        steps: stage?.steps?.map((step) => {
          if (step?.survey) {
            return {
              ...step,
              survey: {
                id: step?.survey?.id,
                name: step?.survey?.name,
              },
            };
          }
          return step;
        }),
      };
    });

    await updateWorkflow({
      id: workflowId,
      config: { stages: configStages || [] },
    });
    if (!appConfigData) return;

    await updateAppConfig.mutateAsync({
      data: { ...appConfigData?.data, isControlledWorkflowUpToDate: true },
    });
    setAreControlsDisabled(false);
  };

  const onDiscardChanges = useCallback(async () => {
    setAreControlsDisabled(true);
    await resetFromScreenshot.mutateAsync({ type: COMPANY_TYPE.CONTROLLED });
    if (!appConfigData?.data) return;
    await updateAppConfig.mutateAsync({
      data: { ...appConfigData.data, isControlledWorkflowUpToDate: true },
    });
    pushSuccessToast({
      message:
        'All changes have been successfully discarded, and workflows have been reverted to the previously applied state.',
    });
    setAreControlsDisabled(false);
  }, [appConfigData?.data, pushSuccessToast, resetFromScreenshot, updateAppConfig]);

  const onAddStage = useCallback(() => {
    const payload = {
      name: `Stage ${(stages?.length || 0) + 1}`,
      order: (stages?.length || 0) + 1,
    };

    createStage(payload, {
      onSuccess: async () => {
        if (!appConfigData) return;

        await updateAppConfig.mutateAsync({
          data: { ...appConfigData?.data, isControlledWorkflowUpToDate: false },
        });
      },
    });
  }, [appConfigData, createStage, stages?.length, updateAppConfig]);

  if (!stages) return null;

  return (
    <Wrapper>
      <ConfirmActionBar
        text='Update the Workflow to reflect changes for all new companies.'
        confirmText='Update'
        onConfirm={onUpdateWorkflows}
        cancelText='Discard Changes'
        onCancel={onDiscardChanges}
        cancelButtonStyle={{ width: '150px' }}
        isActive={!appConfigData?.data?.isControlledWorkflowUpToDate}
        isConfirmDisabled={areControlsDisabled}
        isCancelDisabled={areControlsDisabled}
      />
      <StagesManagement onAddStage={onAddStage}>
        <PortCoStagesList stages={stages} />
      </StagesManagement>
    </Wrapper>
  );
};
