import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useAtomValue } from 'jotai';
import { activeRoleState } from '../../../state/UIState';
import { Company, USER_ROLE } from '../../../types';
import { useMemo } from 'react';
import { CompaniesTable } from '../../../components/CompaniesTable/CompaniesTable';
import { CompanyFilters } from './CompanyFilters';

const Wrapper = styled('div')`
  padding: 24px 40px;
`;

interface Props {
  companiesData?: Company[];
  isLoading: boolean;
  tableRef?: React.RefObject<HTMLDivElement>;
}

export const PendingCompaniesTable = ({ companiesData, isLoading, tableRef }: Props) => {
  const { colors } = useTheme();
  const activeRole = useAtomValue(activeRoleState);

  const showTitle = useMemo(() => {
    return activeRole !== USER_ROLE.ANALYST;
  }, [activeRole]);

  return (
    <Wrapper>
      {showTitle && (
        <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
          Pending Assignment
        </Typography>
      )}
      <CompanyFilters />
      <CompaniesTable companiesData={companiesData} isLoading={isLoading} tableRef={tableRef} />
    </Wrapper>
  );
};
