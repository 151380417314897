import { styled, useTheme } from '@mui/material';
import { AggregatedReportBreakdownGroup } from '../../../../../types';
import { Typography } from '../../../../../components/Typography/Typography';

interface Props {
  group: AggregatedReportBreakdownGroup;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const GroupSummary = ({ group }: Props) => {
  const { colors } = useTheme();
  return (
    <Container>
      <Typography
        variant='overline'
        color={colors.primary[70]}
        style={{ textTransform: 'uppercase' }}
      >
        {group.groupCategory}
      </Typography>
      <Typography variant='subtitle2' color={colors.primary[90]}>
        {group.groupName}
      </Typography>
    </Container>
  );
};
