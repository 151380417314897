import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { ExclusionCategory, LoadingId } from '../types';

export const EXCLUSION_CATEGORIES = 'exclusionCategories';

async function fetchExclusionCategories(): Promise<ExclusionCategory[]> {
  const { data } = await Http.axios.get<ExclusionCategory[]>(`/exclusion-category`);

  return data?.map((category) => ({
    ...category,
    conditions: category?.conditions
      ?.sort((a, b) => a?.order - b?.order)
      ?.map((condition) => {
        return {
          ...condition,
          exclusions: condition?.exclusions?.sort((a, b) => a?.order - b?.order),
        };
      }),
  }));
}

export function useExclusionCategories() {
  return useQuery(EXCLUSION_CATEGORIES, () => fetchExclusionCategories(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createExclusionCategory(
  payload: Partial<ExclusionCategory>
): Promise<ExclusionCategory> {
  const { data } = await Http.axios.post<Partial<ExclusionCategory>, ExclusionCategory>(
    `/exclusion-category`,
    payload
  );
  return data;
}

export function useCreateExclusionCategory() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createExclusionCategory, {
    onMutate: async () => {
      startLoading(LoadingId.createExclusionCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create exclusion category' });
    },
    onSuccess: (newExclusionCategory) => {
      queryClient.setQueryData(
        EXCLUSION_CATEGORIES,
        (oldCategories: ExclusionCategory[] | undefined) =>
          oldCategories ? [...oldCategories, newExclusionCategory] : [newExclusionCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.createExclusionCategory);
    },
  });
}

async function updateExclusionCategory(
  exclusionCategory: Partial<ExclusionCategory>
): Promise<ExclusionCategory> {
  const { id, ...payload } = exclusionCategory;
  const { data } = await Http.axios.patch<Partial<ExclusionCategory>, ExclusionCategory>(
    `/exclusion-category/${id}`,
    payload
  );
  return data;
}

export function useUpdateExclusionCategory() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusionCategory, {
    onMutate: async () => {
      startLoading(LoadingId.updateExclusionCategory);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update exclusion category' });
    },
    onSuccess: (updatedExclusionCategory) => {
      queryClient.setQueryData(
        EXCLUSION_CATEGORIES,
        (oldCategories: ExclusionCategory[] | undefined) =>
          oldCategories
            ? [
                ...oldCategories.map((category) =>
                  category.id === updatedExclusionCategory.id ? updatedExclusionCategory : category
                ),
              ]
            : [updatedExclusionCategory]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusionCategory);
    },
  });
}

async function updateExclusionCategories(exclusionCategories: {
  data: Partial<ExclusionCategory>[];
}): Promise<ExclusionCategory[]> {
  const { data } = await Http.axios.patch<
    { data: Partial<ExclusionCategory>[] },
    ExclusionCategory[]
  >(`/exclusion-category/bulk`, exclusionCategories);
  return data;
}

export function useUpdateExclusionCategories() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusionCategories, {
    onMutate: async (payload) => {
      const prevCategories = queryClient.getQueryData(EXCLUSION_CATEGORIES) as ExclusionCategory[];
      const sortedCategories = payload.data;

      const updatedCategories = sortedCategories?.map((sortedCategory) => {
        return prevCategories?.find((prevCategory) => prevCategory?.id === sortedCategory?.id);
      });
      queryClient.setQueryData(EXCLUSION_CATEGORIES, updatedCategories);
      startLoading(LoadingId.updateExclusionCategories);

      return { prevCategories };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData(EXCLUSION_CATEGORIES, context?.prevCategories);
      pushErrorToast({ message: 'Failed to update exclusion categories' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusionCategories);
    },
  });
}
