import { useMemo } from 'react';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { AggregatedReportWidget } from '../../../../../../types';

export const useWidgetQuestion = (widget: AggregatedReportWidget) => {
  const { selectedQuestions } = useBreakdownData();

  return useMemo(
    () => selectedQuestions?.find((q) => q?.id === widget?.questionId),
    [selectedQuestions, widget?.questionId]
  );
};
