import { useParams } from 'react-router';
import { Task } from '../TaskGroup';
import { TaskReportIndicator } from '../TaskGroup/TaskReportIndicator';
import { useSASBReport } from '../../../hooks/useSASBReport';
import { useReportTaskClickHandler } from '../../../hooks/useReportTaskClickHandler';
import { useCompanyById } from '../../../queries/useCompanies';
import { useOnReportClick } from '../../../hooks/useOnReportClick';
import { useSASBSurvey } from '../../../hooks/useSASBSurvey';
import { useMemo } from 'react';
import { REPORT_STATUS } from '../../../types';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const SASBStep = ({ hasAccess, hasEvaluationAccess, isStepCompleted }: Props) => {
  const params = useParams();
  const SASBSurvey = useSASBSurvey(Number(params.id));
  const SASBReport = useSASBReport(Number(params.id));
  const { data: company } = useCompanyById(Number(params.id));
  const { onReportTaskClickHandler } = useReportTaskClickHandler();
  const { onReportClick } = useOnReportClick();

  const isInitialAccess = useMemo(() => {
    return (
      !SASBReport ||
      SASBReport?.status === REPORT_STATUS.NOT_STARTED ||
      SASBReport?.status === REPORT_STATUS.IN_PROGRESS ||
      SASBReport?.status === REPORT_STATUS.REJECTED ||
      SASBReport?.status === REPORT_STATUS.SUBMITTED
    );
  }, [SASBReport]);

  const isEvaluationAccess = useMemo(
    () => SASBReport?.status === REPORT_STATUS.IN_REVIEW,
    [SASBReport?.status]
  );

  const hasInitialAccess = isInitialAccess && hasAccess;
  const hasEvalAccess = isEvaluationAccess && hasEvaluationAccess && !isStepCompleted;

  const isBlocked = !company?.industry || !company?.sectors?.length;

  const isDisabled = isBlocked || (!hasInitialAccess && !hasEvalAccess);

  return (
    <Task
      taskName={'Investment Specific Assessment'}
      onTaskNameClick={
        SASBReport && SASBReport?.status !== REPORT_STATUS.NOT_STARTED
          ? () => onReportClick(SASBReport)
          : undefined
      }
      isHighlighted={
        (hasInitialAccess || hasEvalAccess) &&
        SASBReport?.status !== REPORT_STATUS.APPROVED &&
        SASBReport?.status !== REPORT_STATUS.SUBMITTED
      }
      disabled={isDisabled}
      infoText={isBlocked ? 'Fill in the company information first.' : ''}
    >
      <TaskReportIndicator
        onButtonClick={() => {
          if (SASBReport && SASBReport?.status === REPORT_STATUS.NOT_STARTED) {
            onReportClick(SASBReport);
            return;
          }
          onReportTaskClickHandler(SASBReport, SASBSurvey?.id, SASBSurvey?.name, company?.id);
        }}
        report={SASBReport}
        buttonText='Proceed to Assessment'
        disabled={isDisabled}
      />
    </Task>
  );
};
