import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  styled,
  AccordionProps,
  AccordionDetailsProps,
} from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-right.svg';

const Wrapper = styled('div')`
  overflow-x: auto;
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))`
  &:before {
    display: none;
  }
`;

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))`
  flex-direction: row-reverse;
  border: none;
  background-color: ${({ theme }) => theme.colors.accent[5]};
  border-radius: 10px;
  padding: 10px 16px 16px 16px;
  align-items: flex-end;

  &.Mui-expanded {
    border-radius: 10px 10px 0 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    margin-bottom: 4px;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
))`
  padding: 0;
`;

interface Props {
  isExpanded?: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  accordionSummary: React.ReactNode;
  accordionDetails: React.ReactNode;
  hasDetails?: boolean;
  containerStyles?: React.CSSProperties;
  summaryStyles?: React.CSSProperties;
}

export const RoundedAccordion = ({
  isExpanded,
  onChange,
  hasDetails,
  accordionSummary,
  accordionDetails,
  containerStyles,
  summaryStyles,
}: Props) => {
  return (
    <Wrapper style={containerStyles}>
      <Accordion expanded={isExpanded} onChange={onChange}>
        <AccordionSummary
          expandIcon={hasDetails ? <ArrowIcon /> : null}
          style={{ cursor: hasDetails ? 'pointer' : 'default', ...summaryStyles }}
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: hasDetails ? '0 0 0 16px' : ' 0 0 0 24px',
            },
            '&.Mui-expanded': {
              borderRadius: hasDetails ? '10px 10px 0 0' : '10px',
            },
          }}
        >
          {accordionSummary}
        </AccordionSummary>

        {hasDetails && <AccordionDetails>{accordionDetails}</AccordionDetails>}
      </Accordion>
    </Wrapper>
  );
};
