import { Column, Worksheet } from 'exceljs';
import {
  ASSESSMENT_WORKSHEET_KEYS,
  QUESTION_TYPE,
  QuestionOption,
  Response,
  SingleAssessmentQuestionRow,
} from '../types';

const getAssessmentAnswerValue = (type: QUESTION_TYPE, answer?: Response) => {
  if (type === QUESTION_TYPE.SINGLE) {
    return (answer as QuestionOption)?.name || '';
  }

  if (type === QUESTION_TYPE.MULTI) {
    return (answer as QuestionOption[])?.map((option) => option.name).join(', ') ?? '';
  }

  return (answer as string) || '';
};

export const formatAssessmentWorksheet = ({
  worksheet,
  showOverrideColumn,
  hasEvaluation,
  exportData,
}: {
  worksheet: Worksheet;
  showOverrideColumn?: boolean;
  hasEvaluation?: boolean;
  exportData: SingleAssessmentQuestionRow[];
}) => {
  const cols: Array<Partial<Column>> = [
    {
      header: 'Question',
      key: ASSESSMENT_WORKSHEET_KEYS.QUESTION,
      width: 100,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
    {
      header: 'Answer',
      key: ASSESSMENT_WORKSHEET_KEYS.ANSWER,
      width: 30,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
  ];

  if (showOverrideColumn) {
    cols.push({
      header: 'Override',
      key: ASSESSMENT_WORKSHEET_KEYS.OVERRIDE,
      width: 30,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        },
      },
    });
    cols.push({
      header: 'Overridden By',
      key: ASSESSMENT_WORKSHEET_KEYS.OVERRIDDEN_BY,
      width: 15,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        },
      },
    });
  }

  if (hasEvaluation) {
    cols.push({
      header: 'Evaluation Score',
      key: ASSESSMENT_WORKSHEET_KEYS.EVALUATION_SCORE,
      width: 15,
      style: {
        alignment: {
          wrapText: true,
          vertical: 'top',
          horizontal: 'left',
        },
      },
    });
  }

  worksheet.columns = cols;

  const filteredData = exportData.filter((row) => row.type !== QUESTION_TYPE.TABLE);

  filteredData.forEach((row) => {
    let worksheetRow: Partial<Record<ASSESSMENT_WORKSHEET_KEYS, string>> = {
      question: row.question,
      answer: getAssessmentAnswerValue(row.type, row.answer),
    };

    if (showOverrideColumn) {
      worksheetRow = {
        ...worksheetRow,
        override: getAssessmentAnswerValue(row.type, row.overrideAnswer),
        overriddenBy: row.overriddenBy,
      };
    }

    if (hasEvaluation) {
      worksheetRow = {
        ...worksheetRow,
        evaluationScore:
          row.evaluationScore !== undefined && row.evaluationScore !== null
            ? String(row.evaluationScore)
            : '',
      };
    }

    worksheet.addRow(worksheetRow);
  });
};
