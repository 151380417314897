import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { Badge } from './Badge';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface Props {
  label: string;
  badgeNum?: number;
  onClick?: () => void;
}

export const FilterButton = ({ label, badgeNum, onClick }: Props) => {
  const { colors } = useTheme();
  return (
    <Button variant='text' onClick={onClick}>
      <Wrapper>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          {label}
        </Typography>
        {Boolean(badgeNum) && badgeNum && <Badge badgeNum={badgeNum} />}
      </Wrapper>
    </Button>
  );
};
