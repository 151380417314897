import { useMutation, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { Http } from '../services/Http';
import { Exclusion, ExclusionCategory, ExternalFunds, LoadingId } from '../types';
import { EXCLUSION_CATEGORIES } from './useExclusionCategories';
import { useToastMessage } from '../hooks/useToastMessage';

export interface ExclusionPayload {
  id: number;
  created_at: string;
  updated_at: string;
  exclusion: string;
  isActive: boolean;
  externalInvestorId: string;
  externalInvestorName: string;
  externalFunds: ExternalFunds[];
  isDeleted: boolean;
  order: number;
  condition: number;
}

async function updateExclusion(exclusion: Partial<ExclusionPayload>): Promise<Exclusion> {
  const { id, ...payload } = exclusion;
  const { data } = await Http.axios.patch<Partial<ExclusionPayload>, Exclusion>(
    `/exclusion/${id}`,
    payload
  );
  return data;
}

export function useUpdateExclusion(categoryId?: number) {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusion, {
    onMutate: async (payload) => {
      const prevExclusionCategories = queryClient.getQueryData(
        EXCLUSION_CATEGORIES
      ) as ExclusionCategory[];

      const category = prevExclusionCategories?.find((category) => category.id === categoryId);
      const condition = category?.conditions?.find(
        (condition) => condition.id === payload.condition
      );

      const exclusion = condition?.exclusions?.find((exclusion) => exclusion.id === payload.id);

      const updatedExclusion = {
        ...exclusion,
        ...payload,
      };

      const updatedCategories = prevExclusionCategories?.map((prevCategory) => {
        if (prevCategory.id === category?.id) {
          return {
            ...category,
            conditions: category.conditions.map((prevCondition) => {
              if (prevCondition.id === condition?.id) {
                return {
                  ...prevCondition,
                  exclusions: prevCondition?.exclusions?.map((prevExclusion) => {
                    if (prevExclusion?.id === exclusion?.id) {
                      return updatedExclusion;
                    }

                    return prevExclusion;
                  }),
                };
              }
              return prevCondition;
            }),
          };
        }

        return prevCategory;
      });

      queryClient.setQueryData(EXCLUSION_CATEGORIES, updatedCategories);
      startLoading(LoadingId.updateExclusion);

      return { prevExclusionCategories };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData(EXCLUSION_CATEGORIES, context?.prevExclusionCategories);
      pushErrorToast({ message: 'Failed to update exclusion' });
    },

    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusion);
    },
  });
}

async function createExclusion(payload: Partial<ExclusionPayload>): Promise<Exclusion> {
  const { data } = await Http.axios.post<Partial<ExclusionPayload>, Exclusion>(
    `/exclusion`,
    payload
  );
  return data;
}

export function useCreateExclusion() {
  const { startLoading, stopLoading } = useLoadingBar();
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createExclusion, {
    onMutate: async () => {
      startLoading(LoadingId.createExclusion);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create exclusion' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.createExclusion);
    },
  });
}

async function updateExclusions(payload: {
  data: Partial<ExclusionPayload>[];
  category: number;
}): Promise<Exclusion[]> {
  const payloadData = { data: payload.data };
  const { data } = await Http.axios.patch<{ data: Partial<ExclusionPayload>[] }, Exclusion[]>(
    `/exclusion/bulk`,
    payloadData
  );
  return data;
}

export function useUpdateExclusions() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateExclusions, {
    onMutate: async (payload) => {
      const prevCategories = queryClient.getQueryData(EXCLUSION_CATEGORIES) as ExclusionCategory[];
      const sortedExclusions = payload.data;
      const updatedCategories = prevCategories?.map((prevCategory) => {
        if (prevCategory.id === payload.category) {
          return {
            ...prevCategory,
            conditions: prevCategory.conditions.map((prevCondition) => {
              if (prevCondition.id === sortedExclusions[0].condition) {
                return {
                  ...prevCondition,
                  exclusions: sortedExclusions.map((sortedExclusion) => {
                    return prevCondition.exclusions.find(
                      (prevExclusion) => prevExclusion.id === sortedExclusion.id
                    );
                  }),
                };
              }
              return prevCondition;
            }),
          };
        }
        return prevCategory;
      });

      queryClient.setQueryData(EXCLUSION_CATEGORIES, updatedCategories);

      startLoading(LoadingId.updateExclusions);

      return { prevCategories };
    },
    onError: (error: Error, _variables, context) => {
      console.error({ error });
      queryClient.setQueryData(EXCLUSION_CATEGORIES, context?.prevCategories);
      pushErrorToast({ message: 'Failed to update exclusions' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.updateExclusions);
    },
  });
}

async function createExclusionsDbScreenshot(): Promise<void> {
  await Http.axios.post<null, void>(`/exclusion/screenshot`);
}

export function useCreateExclusionsDbScreenshot() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createExclusionsDbScreenshot, {
    onMutate: async () => {
      startLoading(LoadingId.createExclusionsDbScreenshot);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create exclusions database screenshot' });
    },
    onSettled: () => {
      stopLoading(LoadingId.createExclusionsDbScreenshot);
    },
  });
}

async function resetExclusionsDbTablesFromScreenshot(): Promise<void> {
  await Http.axios.post<null, void>(`/exclusion/reset-from-screenshot`);
}

export function useResetExclusionsDbTablesFromScreenshot() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(resetExclusionsDbTablesFromScreenshot, {
    onMutate: async () => {
      startLoading(LoadingId.resetExclusionsDbTablesFromScreenshot);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to reset exclusions database tables from screenshot' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(EXCLUSION_CATEGORIES);
      stopLoading(LoadingId.resetExclusionsDbTablesFromScreenshot);
    },
  });
}
