import { useEffect, useMemo, useRef } from 'react';

import { useAllCompanies } from '../../queries/useCompanies';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  activeRoleState,
  companiesState,
  companySearchState,
  selectedCompanyAnalystsFilterState,
  selectedCompanyCountriesFilterState,
  selectedCompanySectorsFilterState,
  selectedCompanyStageFilterState,
  selectedCompanyTypeFilterState,
} from '../../state/UIState';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../components/Typography/Typography';
import { CompaniesHeader } from './components/CompaniesHeader';
import { USER_ROLE } from '../../types';
import { CompaniesTable } from '../../components/CompaniesTable/CompaniesTable';
import { CompanyFilters } from './components/CompanyFilters';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';

const Wrapper = styled('div')`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  #refresh {
    animation: spin 2s linear infinite;
  }
`;

const ContentWrapper = styled('div')`
  padding: 24px 40px;
`;

export const Companies = () => {
  const setCompanies = useSetAtom(companiesState);
  const activeRole = useAtomValue(activeRoleState);
  const { colors } = useTheme();
  const searchTerm = useAtomValue(companySearchState);
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const selectedType = useAtomValue(selectedCompanyTypeFilterState);
  const selectedStage = useAtomValue(selectedCompanyStageFilterState);
  const selectedSectors = useAtomValue(selectedCompanySectorsFilterState);
  const selectedCountries = useAtomValue(selectedCompanyCountriesFilterState);
  const selectedAnalysts = useAtomValue(selectedCompanyAnalystsFilterState);

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useAllCompanies({
    term: debouncedSearchTerm,
    type: selectedType?.value,
    stage: selectedStage?.value,
    sectors: selectedSectors?.map((sector) => sector.id)?.join(','),
    countries: selectedCountries?.map((country) => country.value)?.join(','),
    analysts: selectedAnalysts?.map((analyst) => analyst.value)?.join(','),
  });

  const companiesData = useMemo(() => data?.pages?.flatMap((page) => page.data), [data?.pages]);

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCompanies(companiesData || []);
  }, [companiesData, setCompanies]);

  const companiesNum = useMemo(() => {
    return data?.pages?.[0]?.pagination?.total || 0;
  }, [data?.pages]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableRef?.current &&
        tableRef?.current.scrollTop + tableRef?.current.clientHeight >=
          tableRef?.current.scrollHeight
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    };

    const container = tableRef?.current;
    container?.addEventListener('scroll', handleScroll);
    return () => container?.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, tableRef]);

  const showTitle = useMemo(() => {
    return activeRole !== USER_ROLE.ANALYST;
  }, [activeRole]);

  return (
    <Wrapper>
      <CompaniesHeader companiesNum={companiesNum} />
      <ContentWrapper>
        {showTitle && (
          <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
            Pending Assignment
          </Typography>
        )}
        <CompanyFilters />
        <CompaniesTable
          companiesData={companiesData}
          isLoading={isLoading}
          tableRef={tableRef}
          isFetchingNextPage={isFetchingNextPage}
        />
      </ContentWrapper>
    </Wrapper>
  );
};
