import { useAtom } from 'jotai';
import { selectedCoreKpiState } from '../../../state/UIState';

import { useCallback, useState } from 'react';
import { useOnCancelConfirm } from './useOnCancelConfirm';
import { useMoveSingleKpi } from './useMoveSingleKpi';
import { useMoveKpis } from './useMoveKpis';
import { useUpdateKpiSurvey } from './useUpdateKpiSurvey';
import {
  useCreateCoreKpiDbScreenshot,
  useResetCoreKpiDbTablesFromScreenshot,
} from '../../../queries/useCoreKpis';
import { useAppConfig, useUpdateAppConfig } from '../../../queries/useAppConfig';
import { useToastMessage } from '../../../hooks/useToastMessage';

export const useConfirmBarActions = () => {
  const [selectedKpi] = useAtom(selectedCoreKpiState);

  const { pushSuccessToast } = useToastMessage();

  const resetFromScreenshot = useResetCoreKpiDbTablesFromScreenshot();
  const { data: appConfig } = useAppConfig();
  const updateAppConfig = useUpdateAppConfig();
  const [areControlsDisabled, setAreControlsDisabled] = useState(false);

  const { onCancel } = useOnCancelConfirm();

  const { moveSingleKpi } = useMoveSingleKpi();
  const { moveKpis } = useMoveKpis();
  const createCoreKpiDbScreenshot = useCreateCoreKpiDbScreenshot();

  const { onUpdateKpiSurvey: updateKpiSurvey } = useUpdateKpiSurvey();

  const onUpdateKpiSurvey = useCallback(async () => {
    setAreControlsDisabled(true);
    createCoreKpiDbScreenshot.mutate();
    await updateKpiSurvey();
    setAreControlsDisabled(false);
  }, [createCoreKpiDbScreenshot, updateKpiSurvey]);

  const onConfirm = useCallback(async () => {
    if (selectedKpi) return moveSingleKpi();
    return moveKpis();
  }, [moveKpis, moveSingleKpi, selectedKpi]);

  const onResetFromScreenshot = useCallback(async () => {
    setAreControlsDisabled(true);
    await resetFromScreenshot.mutateAsync();
    if (!appConfig?.data) return;
    await updateAppConfig.mutateAsync({
      data: { ...appConfig.data, isCoreKpiSurveyUpToDate: true },
    });
    pushSuccessToast({
      message:
        'All changes have been successfully discarded, and KPIs have been reverted to the previously applied state.',
    });
    setAreControlsDisabled(false);
  }, [appConfig?.data, pushSuccessToast, resetFromScreenshot, updateAppConfig]);

  return { onCancel, onConfirm, onUpdateKpiSurvey, onResetFromScreenshot, areControlsDisabled };
};
