import { CompanyTypeFilter } from '../../../../../../components/CompanyTypeFilter';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useCallback, useMemo } from 'react';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const TypeFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const uniqueTypes: SelectItem[] = useMemo(() => {
    const companies = aggregatedReport?.companies || [];
    return [...new Set(companies.map((c) => c.type))]
      ?.filter((o) => o)
      .map((type, i) => ({
        id: i + 1,
        value: type,
      }));
  }, [aggregatedReport?.companies]);

  const selectedType = useMemo(() => {
    if (!aggregatedReport) return null;

    const type = aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.TYPE];

    if (!type) return null;

    return uniqueTypes?.find((t) => t.value === type) || null;
  }, [aggregatedReport, uniqueTypes]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.TYPE]: type?.value || null,
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <CompanyTypeFilter
      selectedType={selectedType}
      sortedUniqueTypes={uniqueTypes}
      onChangeTypeFilter={onChangeTypeFilter}
      width='100%'
      placeholder='Type'
    />
  );
};
