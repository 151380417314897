import { useCallback, useMemo } from 'react';
import { CountriesFilter } from '../../../../../../components/CountriesFilter';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const GeographyFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const uniqueCountries: SelectItem[] = useMemo(() => {
    const companies = aggregatedReport?.companies || [];
    return [...new Set(companies.map((c) => c.country))]
      ?.filter((o) => o)
      .map((country, i) => ({
        id: i + 1,
        value: country,
      }));
  }, [aggregatedReport?.companies]);

  const selectedCountries = useMemo(() => {
    if (!aggregatedReport) return [];

    const selectedCountryFilters =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY];

    const hasCountriesFilter =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY]?.length;

    if (!hasCountriesFilter) return [];

    return uniqueCountries?.filter((r) => selectedCountryFilters?.includes(r?.value));
  }, [aggregatedReport, uniqueCountries]);

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY]: countries?.map((r) => r?.value),
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[]>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <CountriesFilter
      sortedUniqueCountries={uniqueCountries}
      onChangeCountriesFilter={onChangeCountriesFilter}
      selectedCountries={selectedCountries}
      width='100%'
      placeholder='Geography'
    />
  );
};
