import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { useAtomValue } from 'jotai';
import { aggregatedReportFilterTypeState } from '../../../../state/UIState';
import { AGGREGATED_REPORT_FILTER } from '../../../../types';
import { useMemo } from 'react';
import { QuestionsFilters } from './QuestionsFilters/QuestionsFilters';
import { IssuersFilters } from './IssuersFilters/IssuersFilters';
import { Groups } from './Groups/Groups';

const Container = styled('div')`
  width: 432px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  padding: 24px;
  border-right: ${({ theme }) => `1px solid ${theme.colors.primary[20]}`};
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100%;
`;

export const FiltersDrawer = () => {
  const { colors } = useTheme();
  const filterType = useAtomValue(aggregatedReportFilterTypeState);

  const title = useMemo(() => {
    if (filterType === AGGREGATED_REPORT_FILTER.QUESTIONS) return 'Questions';
    if (filterType === AGGREGATED_REPORT_FILTER.ISSUERS) return 'Issuers';
    if (filterType === AGGREGATED_REPORT_FILTER.GROUPS) return 'Groups';
    return '';
  }, [filterType]);

  const filtersContent = useMemo(() => {
    if (filterType === AGGREGATED_REPORT_FILTER.QUESTIONS) return <QuestionsFilters />;
    if (filterType === AGGREGATED_REPORT_FILTER.ISSUERS) return <IssuersFilters />;
    if (filterType === AGGREGATED_REPORT_FILTER.GROUPS) return <Groups />;
    return null;
  }, [filterType]);

  return (
    <Container>
      <Typography variant='h4' color={colors.primary[90]}>
        {title}
      </Typography>
      {filtersContent}
    </Container>
  );
};
