import { useEffect, useMemo, useState } from 'react';
import { useIssuersPendingEvaluation } from '../../hooks/useIssuersPendingEvaluation';
import { Company, CompanyFiltersPayload, Sector } from '../../types';

const NUM_PER_PAGE = 40;

const filterBySectors = (companySectors: Sector[], filters: CompanyFiltersPayload = {}) => {
  if (filters.sectors?.length) {
    return companySectors?.some((sector) => {
      return filters.sectors
        ?.split(',')
        .map((s) => s.trim())
        ?.includes(String(sector.id));
    });
  }

  return true;
};

const filterByStage = (companyStage: string, filters: CompanyFiltersPayload = {}) => {
  if (filters.stage) {
    return companyStage === filters.stage;
  }

  return true;
};
const filterByType = (companyType: string, filters: CompanyFiltersPayload = {}) => {
  if (filters.type) {
    return companyType === filters.type;
  }

  return true;
};
const filterByCountries = (companyCountry: string, filters: CompanyFiltersPayload = {}) => {
  if (filters.countries?.length) {
    return filters.countries
      ?.split(',')
      .map((s) => s.trim())
      ?.includes(companyCountry);
  }

  return true;
};

const filterByAnalysts = (companyAnalyst: string, filters: CompanyFiltersPayload = {}) => {
  if (filters.analysts?.length) {
    return filters.analysts
      ?.split(',')
      .map((s) => s.trim())
      ?.includes(companyAnalyst);
  }

  return true;
};

const filterByTerm = (companyName: string, filters: CompanyFiltersPayload = {}) => {
  if (filters.term) {
    return companyName.toLowerCase().includes(filters.term.toLowerCase());
  }

  return true;
};

export const usePaginatedCompanies = (filters?: CompanyFiltersPayload) => {
  const { companiesData, isLoading } = useIssuersPendingEvaluation();
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [page, setPage] = useState(1);

  const fetchNextPage = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    const getPaginatedCompanies = () => {
      const filteredComps = companiesData?.filter((company) => {
        const filteredBySectors = filterBySectors(company.sectors, filters);
        const filteredByStage = filterByStage(company.stage, filters);
        const filteredByType = filterByType(company.type, filters);
        const filteredByCountries = filterByCountries(company.country, filters);
        const filteredByAnalysts = filterByAnalysts(company.analyst, filters);
        const filteredByTerm = filterByTerm(company.name, filters);

        return (
          filteredBySectors &&
          filteredByStage &&
          filteredByType &&
          filteredByCountries &&
          filteredByAnalysts &&
          filteredByTerm
        );
      });

      const paginatedCompanies = filteredComps?.slice(0, NUM_PER_PAGE * page);
      setFilteredCompanies(paginatedCompanies || []);
    };

    getPaginatedCompanies();
  }, [
    companiesData,
    filters,
    filters?.analysts,
    filters?.countries,
    filters?.sectors,
    filters?.stage,
    filters?.term,
    filters?.type,
    page,
  ]);

  const hasNextPage = useMemo(() => {
    return (companiesData?.length || 0) > NUM_PER_PAGE * page;
  }, [companiesData?.length, page]);

  const totalNumberOfCompanies = useMemo(() => {
    return companiesData?.length || 0;
  }, [companiesData?.length]);

  return { data: filteredCompanies, hasNextPage, fetchNextPage, isLoading, totalNumberOfCompanies };
};
