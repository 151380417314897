import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';

const Container = styled('div')`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.warning[10]};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled('div')`
  width: 22px;
  display: flex;
  align-items: center;
`;

export const WarningMessage = () => {
  const { colors } = useTheme();
  return (
    <Container>
      <IconWrapper>
        <WarningIcon />
      </IconWrapper>
      <Typography variant='subtitle2' color={colors.warning[50]} style={{ marginLeft: '8px' }}>
        Uploading a new Excel file will completely overwrite the existing data displayed in the KPI
        Matrix
      </Typography>
    </Container>
  );
};
