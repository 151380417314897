import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useCallback, useMemo } from 'react';
import { RiskFilter } from '../../../../../../components/RiskFilter';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const RiskLevelFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const uniqueRisks: SelectItem[] = useMemo(() => {
    const companies = aggregatedReport?.companies || [];
    return [...new Set(companies.map((c) => c.esgRiskLevel))]
      ?.filter((r) => r)
      .map((risk, i) => ({
        id: i + 1,
        value: risk,
      }));
  }, [aggregatedReport?.companies]);

  const selectedRisks = useMemo(() => {
    if (!aggregatedReport) return [];

    const selectedRiskFilters =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL];

    const hasFilteredRisks =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL]?.length;

    if (!hasFilteredRisks) return [];

    return uniqueRisks?.filter((r) => selectedRiskFilters?.includes(r?.value));
  }, [aggregatedReport, uniqueRisks]);

  const onChangeSelectedRisks = useCallback(
    (risks: SelectItem[]) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL]: risks?.length
            ? risks?.map((r) => r?.value)
            : [],
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[]>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <RiskFilter
      sortedUniqueRisks={uniqueRisks}
      selectedRisks={selectedRisks}
      onChangeSelectedRisks={onChangeSelectedRisks}
      width='100%'
      placeholder='ESG Risk Levels'
    />
  );
};
