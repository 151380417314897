import { useCallback, useEffect, useMemo } from 'react';
import { useAppConfig, useUpdateAppConfig } from '../queries/useAppConfig';
import { ExclusionCategory } from '../types';
import { useAtom } from 'jotai';
import { activeExclusionCategoryState, sortedExclusionsCategoriesState } from '../state/UIState';
import {
  useCreateExclusionCategory,
  useExclusionCategories,
  useUpdateExclusionCategories,
  useUpdateExclusionCategory,
} from '../queries/useExclusionCategories';

export function useSortedExclusionCategories() {
  const { data: appConfig, isLoading: isConfigLoading } = useAppConfig();
  const { data: exclusionCategories, isLoading: areCategoriesLoading } = useExclusionCategories();
  const [sortedItems, setSortedItems] = useAtom(sortedExclusionsCategoriesState);
  const [, setActiveCategory] = useAtom(activeExclusionCategoryState);
  const updatePageConfig = useUpdateAppConfig();
  const updateExclusionCategories = useUpdateExclusionCategories();
  const createExclusionCategory = useCreateExclusionCategory();
  const updateExclusionCategory = useUpdateExclusionCategory();

  useEffect(() => {
    if (!exclusionCategories?.length || !appConfig || isConfigLoading || areCategoriesLoading) {
      return;
    }
    setSortedItems(exclusionCategories);

    setActiveCategory((prev) => {
      const prevActive = exclusionCategories?.find((category) => category.id === prev?.id);
      return prevActive || exclusionCategories?.[0];
    });
  }, [
    appConfig,
    setSortedItems,
    isConfigLoading,
    areCategoriesLoading,
    setActiveCategory,
    exclusionCategories,
  ]);

  const onReorder = useCallback(
    async (newOrder: ExclusionCategory[]) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      setSortedItems(newOrder);
      const payload = newOrder.map((item) => ({
        id: item.id,
        order: item.order,
      }));
      await updateExclusionCategories.mutateAsync(
        {
          data: payload,
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
        }
      );
      await updatePageConfig.mutateAsync({
        data: {
          ...appConfig?.data,
          isExclusionSurveyUpToDate: false,
        },
      });
    },
    [appConfig?.data, setSortedItems, sortedItems, updateExclusionCategories, updatePageConfig]
  );

  const onAddCategory = useCallback(async () => {
    if (!appConfig?.data) {
      return;
    }
    const newCategory = await createExclusionCategory.mutateAsync({
      name: 'New Category',
      order: sortedItems.length + 1,
    });
    const newOrder = [...sortedItems, { ...newCategory, isNew: true }];

    const payload = newOrder.map((item) => ({
      id: item.id,
      order: item.order,
    }));
    await updateExclusionCategories.mutateAsync(
      {
        data: payload,
      },
      {
        onSuccess: () => {
          setSortedItems(newOrder);
          setActiveCategory(newCategory);
        },
      }
    );
    await updatePageConfig.mutateAsync({
      data: {
        ...appConfig?.data,
        isExclusionSurveyUpToDate: false,
      },
    });
  }, [
    appConfig?.data,
    createExclusionCategory,
    setActiveCategory,
    setSortedItems,
    sortedItems,
    updateExclusionCategories,
    updatePageConfig,
  ]);

  const onRemoveCategory = useCallback(
    async (id: number) => {
      if (!appConfig?.data) {
        return;
      }
      const oldOrder = [...sortedItems];
      const newOrder = sortedItems.filter((item) => item.id !== id);
      await updateExclusionCategory.mutateAsync({ id, isDeleted: true });
      const payload = newOrder.map((item, i) => ({
        id: item.id,
        order: i + 1,
      }));
      await updateExclusionCategories.mutateAsync(
        {
          data: payload,
        },
        {
          onError: () => {
            setSortedItems(oldOrder);
          },
          onSuccess: () => {
            setActiveCategory(newOrder[0] ? newOrder[0] : null);
          },
        }
      );
      await updatePageConfig.mutateAsync({
        data: {
          ...appConfig?.data,
          isExclusionSurveyUpToDate: false,
        },
      });
    },
    [
      appConfig?.data,
      setActiveCategory,
      setSortedItems,
      sortedItems,
      updateExclusionCategories,
      updateExclusionCategory,
      updatePageConfig,
    ]
  );

  const onRenameCategory = useCallback(
    async (id: number, name: string) => {
      await updateExclusionCategory.mutateAsync(
        { id, name },
        {
          onSuccess: async () => {
            if (!appConfig?.data) {
              return;
            }
            await updatePageConfig.mutateAsync({
              data: {
                ...appConfig?.data,
                isExclusionSurveyUpToDate: false,
              },
            });
          },
        }
      );
    },
    [updateExclusionCategory, appConfig?.data, updatePageConfig]
  );

  const value = useMemo(() => {
    return {
      items: sortedItems as ExclusionCategory[],
      isLoading: isConfigLoading || areCategoriesLoading,
      onReorder,
      onAddCategory,
      onRemoveCategory,
      onRenameCategory,
    };
  }, [
    sortedItems,
    isConfigLoading,
    areCategoriesLoading,
    onReorder,
    onAddCategory,
    onRemoveCategory,
    onRenameCategory,
  ]);

  return value;
}
