import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import {
  reportDescriptionState,
  reportNameState,
  selectedAssessmentIdsState,
  userState,
} from '../../../state/UIState';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { TReportSurveyData } from '../../../types';
import { useResetCreateReportUiState } from '../hooks/useResetCreateReportUIState';
import { ROUTES } from '../../../constants/routes';
import {
  AggregatedReportPayload,
  useCreateAggregatedReport,
} from '../../../queries/useAggregatedReports';
import { useSurveys } from '../../../queries/useSurveys';
import { getAggregatedReportSurveysMap } from '../../../utils/getAggregatedReportSurveysMap';

export const ControlButtons = () => {
  const selectedAssessmentIds = useAtomValue(selectedAssessmentIdsState);
  const [reportName] = useAtom(reportNameState);
  const [reportDescription] = useAtom(reportDescriptionState);
  const navigate = useNavigate();
  const { mutate: createAggregatedReport } = useCreateAggregatedReport();
  const { data: surveys } = useSurveys();
  const user = useAtomValue(userState);

  const resetState = useResetCreateReportUiState();

  const onCancel = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.AGGREGATED_REPORTS}`);
  }, [navigate, resetState]);

  const isNextDisabled = useMemo(() => {
    return !selectedAssessmentIds?.length;
  }, [selectedAssessmentIds?.length]);

  const selectedSurveys = useMemo(() => {
    return surveys?.filter((survey) => selectedAssessmentIds?.includes(survey.id));
  }, [selectedAssessmentIds, surveys]);

  const allSurveysMap: Record<string, TReportSurveyData> = useMemo(() => {
    if (!selectedSurveys?.length) return {};
    return getAggregatedReportSurveysMap(selectedSurveys);
  }, [selectedSurveys]);

  const allSurveysQuestionIds: string[] = useMemo(() => {
    return allSurveysMap
      ? Object.values(allSurveysMap)
          .flatMap((q) => q.questions)
          ?.map((question) => question.id)
      : [];
  }, [allSurveysMap]);

  const onNextClick = useCallback(() => {
    const report: AggregatedReportPayload = {
      name: reportName,
      description: reportDescription,
      surveys: selectedAssessmentIds,
      selectedQuestions: allSurveysQuestionIds,
      issuerFilters: null,
      createdBy: user?.id,
    };
    createAggregatedReport(report, {
      onSuccess: (data) => {
        resetState();
        navigate(`/${ROUTES.SINGLE_AGGREGATED_REPORT}/${data.id}`);
      },
      onError: () => {
        resetState();
      },
    });
  }, [
    allSurveysQuestionIds,
    createAggregatedReport,
    navigate,
    reportDescription,
    reportName,
    resetState,
    selectedAssessmentIds,
    user?.id,
  ]);

  return (
    <>
      <Button variant='outlined' style={{ width: '166px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '166px' }}
        disabled={isNextDisabled}
        onClick={onNextClick}
      >
        Create Report
      </Button>
    </>
  );
};
