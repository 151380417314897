import { Button, useTheme } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { Typography } from './Typography/Typography';
import { ReactComponent as DownloadIcon } from '../assets/icons/download-blue-small.svg';

interface Props {
  onExport: () => void;
  isOutlined?: boolean;
}

export const ExportButton: FC<Props> = ({ onExport, isOutlined }) => {
  const { colors } = useTheme();

  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onExport();
    },
    [onExport]
  );

  return (
    <Button
      variant={isOutlined ? 'outlined' : 'text'}
      startIcon={<DownloadIcon />}
      style={{ minWidth: 'auto' }}
      onClick={onClick}
    >
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Export as Excel
      </Typography>
    </Button>
  );
};
