import { RefObject, useCallback, useMemo, useState } from 'react';
import { Table } from '../Table/Table';

import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
  Row,
} from '@tanstack/react-table';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as ResetIcon } from '../../assets/icons/close-gray-circle-small.svg';
import { useAtom, useAtomValue } from 'jotai';
import { Company, USER_ROLE } from '../../types';
import {
  activeRoleState,
  companiesTableColumnFiltersState,
  companiesTableSortingState,
} from '../../state/UIState';
import { useDeleteCompany, useResetCompany } from '../../queries/useCompanies';
import { useCompaniesTableColumns } from './useCompaniesTableColumns';
import { ROUTES } from '../../constants/routes';
import { HEADER_HEIGHT, PAGE_HEADER_HEIGHT } from '../../constants/layoutSizes';
import { CompaniesSkeletonLoader } from '../SkeletonLoader/Companies.SkeletonLoader';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal/ConfirmDeleteModal';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  companiesData?: Company[];
  isLoading: boolean;
  tableRef?: RefObject<HTMLDivElement>;
  isFetchingNextPage?: boolean;
}

export const CompaniesTable = ({
  companiesData,
  isLoading,
  tableRef,
  isFetchingNextPage,
}: Props) => {
  const navigate = useNavigate();
  const [sorting, setSorting] = useAtom(companiesTableSortingState);
  const [columnFilters, setColumnFilters] = useAtom(companiesTableColumnFiltersState);

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const deleteCompany = useDeleteCompany();
  const resetCompany = useResetCompany();

  const activeRole = useAtomValue(activeRoleState);

  const actions = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    ) {
      return;
    }

    return [
      {
        id: 'RESET',
        value: 'Reset',
        icon: <ResetIcon />,
      },
      {
        id: 'DELETE',
        value: 'Delete',
        icon: <DeleteIcon />,
      },
    ];
  }, [activeRole]);

  const handleSelectAction = useCallback(
    (actionId: string, row: Row<Company> | null) => {
      if (!row) return;
      switch (actionId) {
        case 'DELETE':
          setIsConfirmDeleteModalOpen(true);
          setSelectedCompany(row.original);
          return;
        case 'RESET':
          resetCompany.mutate(row.original.id);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [resetCompany]
  );

  const handleDeleteCompany = useCallback(() => {
    if (!selectedCompany) return;
    setIsConfirmDeleteModalOpen(false);
    deleteCompany.mutate(selectedCompany.id);
  }, [selectedCompany, deleteCompany]);

  const columns = useCompaniesTableColumns();

  const table = useReactTable({
    data: companiesData || [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (company) => String(company.id),
  });

  const prevRouteName = useMemo(() => {
    const route = location.pathname?.split('/')?.[1];

    if (route === ROUTES.PENDING_EVALUATION) return 'Pending Evaluation';

    return 'Companies';
  }, []);

  const onRowClick = useCallback(
    (row: Row<Company>) => {
      const companyId = row.id;
      navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_OVERVIEW}`, {
        state: { prevRoute: location.pathname, prevRouteName: prevRouteName || '' },
      });
    },
    [navigate, prevRouteName]
  );

  const tableHeight = useMemo(() => {
    if (
      activeRole &&
      [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
    ) {
      return `calc(100vh - ${HEADER_HEIGHT}px - 88px)`;
    }

    return `calc(100vh - ${PAGE_HEADER_HEIGHT}px - 138px)`;
  }, [activeRole]);

  if (isLoading || !companiesData) return <CompaniesSkeletonLoader />;

  return (
    <Wrapper>
      <Table
        table={table}
        onRowClick={onRowClick}
        actions={actions}
        onActionClick={handleSelectAction}
        height={tableHeight}
        tableRef={tableRef}
        isFetchingNextPage={isFetchingNextPage}
      />

      <ConfirmDeleteModal
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={handleDeleteCompany}
        title='Delete the company?'
        note='Deleting this company will also delete the associated request and all incomplete reports.'
        confirmLabel='Yes, Delete'
        cancelLabel='No, Cancel'
        isOpen={isConfirmDeleteModalOpen}
      />
    </Wrapper>
  );
};
