import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueRisks: SelectItem[];
  selectedRisks: SelectItem[];
  onChangeSelectedRisks: (risks: SelectItem[]) => void;
  width?: string;
  placeholder?: string;
}

export const RiskFilter = ({
  sortedUniqueRisks,
  selectedRisks,
  onChangeSelectedRisks,
  width,
  placeholder,
}: Props) => {
  return (
    <Multiselect
      style={{ width: width || '240px' }}
      options={sortedUniqueRisks}
      value={selectedRisks}
      onChange={(_, val) => onChangeSelectedRisks(val as SelectItem[])}
      disablePortal
      optionName='ESG Risk Level'
      selectedTextMaxWidth='9rem'
      fieldPlaceholder={placeholder || 'Filter by ESG Risk Levels'}
    />
  );
};
