import { useMutation, useQueryClient } from 'react-query';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { Http } from '../services/Http';
import { CHECKLIST_ITEM_FREQUENCY, ChecklistItem, LoadingId } from '../types';
import { CHECKLIST_CATEGORIES } from './useChecklistCategories';

export interface ChecklistItemPayload {
  id?: number;
  name: string;
  instructions: string;
  category: number;
  frequency: CHECKLIST_ITEM_FREQUENCY;
  order: number;
  isDeleted: boolean;
}

async function createChecklistItem(payload: Partial<ChecklistItemPayload>): Promise<ChecklistItem> {
  const { data } = await Http.axios.post<Partial<ChecklistItemPayload>, ChecklistItem>(
    `/check-list-item`,
    payload
  );
  return data;
}

export function useCreateChecklistItem() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createChecklistItem, {
    onMutate: async () => {
      startLoading(LoadingId.createChecklistItem);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create checklist item' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.createChecklistItem);
    },
  });
}

async function updateChecklistItem(
  checklistItem: Partial<ChecklistItemPayload>
): Promise<ChecklistItem> {
  const { id, ...payload } = checklistItem;
  const { data } = await Http.axios.patch<Partial<ChecklistItemPayload>, ChecklistItem>(
    `/check-list-item/${id}`,
    payload
  );
  return data;
}

export function useUpdateChecklistItem() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateChecklistItem, {
    onMutate: async () => {
      startLoading(LoadingId.updateChecklistItem);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update checklistItem' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.updateChecklistItem);
    },
  });
}

async function updateChecklistItems(checklistItem: {
  data: Partial<ChecklistItemPayload>[];
}): Promise<ChecklistItem[]> {
  const { data } = await Http.axios.patch<
    { data: Partial<ChecklistItemPayload>[] },
    ChecklistItem[]
  >(`/check-list-item/bulk`, checklistItem);
  return data;
}

export function useUpdateChecklistItems() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateChecklistItems, {
    onMutate: async () => {
      startLoading(LoadingId.updateChecklistItems);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to update checklist items' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.updateChecklistItems);
    },
  });
}

async function createChecklistDbScreenshot(): Promise<void> {
  await Http.axios.post<null, void>(`/check-list-item/screenshot`);
}

export function useCreateChecklistDbScreenshot() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createChecklistDbScreenshot, {
    onMutate: async () => {
      startLoading(LoadingId.createChecklistDbScreenshot);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create checklist database screenshot' });
    },
    onSettled: () => {
      stopLoading(LoadingId.createChecklistDbScreenshot);
    },
  });
}

async function resetChecklistDbTablesFromScreenshot(): Promise<void> {
  await Http.axios.post<null, void>(`/check-list-item/reset-from-screenshot`);
}

export function useResetChecklistDbTablesFromScreenshot() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(resetChecklistDbTablesFromScreenshot, {
    onMutate: async () => {
      startLoading(LoadingId.resetChecklistDbTablesFromScreenshot);
    },
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to reset checklist database tables from screenshot' });
    },
    onSettled: () => {
      queryClient.invalidateQueries(CHECKLIST_CATEGORIES);
      stopLoading(LoadingId.resetChecklistDbTablesFromScreenshot);
    },
  });
}
