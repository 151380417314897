import { Button, useTheme } from '@mui/material';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import { useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { COMPANY_TYPE, SVP_Issuer, User } from '../../../types';
import { useSyncCompanies } from '../../../queries/useCompanies';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useQueryClient } from 'react-query';
import { APP_CONFIG, useAppConfig } from '../../../queries/useAppConfig';
import { userState } from '../../../state/UIState';
import { useAtomValue } from 'jotai';

export const SyncCompaniesButton = () => {
  const [isSyncing, setIsSyncing] = useState(false);
  const { colors } = useTheme();
  const auth = useOktaAuth();
  const syncCompanies = useSyncCompanies();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();
  const { data: appConfig } = useAppConfig();
  const user = useAtomValue(userState);

  const onSyncIssuers = async () => {
    try {
      setIsSyncing(true);
      const token = auth.authState?.accessToken?.accessToken;
      if (!token) return;
      const { data } = await axios.get(`/issuers`, {
        baseURL: process.env.REACT_APP_SVP_API_BASE_URL,
        headers: {
          Authorization: `Bearer ${token}`,
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SVP_API_SUBSCRIPTION_KEY,
        },
      });
      const issuers: SVP_Issuer[] = data.data;
      const companiesPayload = issuers
        .filter((issuer) => issuer.name || issuer.companyName)
        .map((issuer) => ({
          externalId: issuer.uniqueId,
          salesforceId: issuer.salesforceId,
          name: issuer.name || issuer.companyName,
          type: COMPANY_TYPE.NOT_CONTROLLED,
          sector: issuer.sector,
        }));

      await syncCompanies.mutateAsync(
        { data: companiesPayload, userId: (user as User)?.id },
        {
          onSuccess: (companies) => {
            const message = companies.length
              ? `Companies synced successfully. ${companies.length} new ${
                  companies.length === 1 ? 'company has' : 'companies have'
                } been added.`
              : 'Companies synced successfully. There are no new issuers.';
            pushSuccessToast({ message });
          },
        }
      );
      queryClient.setQueryData(APP_CONFIG, {
        data: { ...appConfig?.data, lastCompaniesSync: new Date().toISOString() },
      });
      queryClient.invalidateQueries(APP_CONFIG);
    } catch (error) {
      console.error(error);
      pushErrorToast({ message: 'Error syncing issuers' });
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <Button
      variant='text'
      onClick={onSyncIssuers}
      startIcon={<RefreshIcon id={isSyncing ? 'refresh' : ''} />}
      disabled={isSyncing}
    >
      <Typography variant='subtitle2' color={isSyncing ? colors.accent[30] : colors.accent[50]}>
        Sync Issuers
      </Typography>
    </Button>
  );
};
