import { styled } from '@mui/material';
import { GroupsSelect } from './GroupsSelect';

import { useCallback, useMemo } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { GroupItem } from './GroupItem';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const Wrapper = styled('div')`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

const GroupsListWrapper = styled('div')`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Groups = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const orderedGroups = useMemo(() => {
    return aggregatedReport?.groupedBy?.sort((a, b) => a.order - b.order);
  }, [aggregatedReport?.groupedBy]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleReorder = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!active?.id || !over?.id || !orderedGroups?.length || !aggregatedReport) return;

      const oldIndex = orderedGroups?.findIndex((item) => item.id === active.id);
      const newIndex = orderedGroups?.findIndex((item) => item.id === over.id);

      const reorderedItems = arrayMove(orderedGroups, oldIndex, newIndex);

      const updatedGroups = reorderedItems.map((item, i) => ({
        ...item,
        order: i + 1,
      }));

      setAggregatedReport({ ...aggregatedReport, groupedBy: updatedGroups });
    },
    [orderedGroups, aggregatedReport, setAggregatedReport]
  );

  return (
    <Wrapper>
      <GroupsSelect />

      <GroupsListWrapper>
        <DndContext sensors={sensors} onDragEnd={handleReorder} collisionDetection={closestCenter}>
          <SortableContext items={orderedGroups ?? []} strategy={verticalListSortingStrategy}>
            {orderedGroups?.map((group) => {
              return <GroupItem key={group.id} group={group} />;
            })}
          </SortableContext>
        </DndContext>
      </GroupsListWrapper>
    </Wrapper>
  );
};
