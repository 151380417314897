import { styled } from '@mui/material';

import { ReactNode } from 'react';
import { CREATE_REQUEST_FOOTER, HEADER_HEIGHT } from '../../constants/layoutSizes';
import { HeaderWithBackBtn } from './HeaderWithBackBtn';

const MainContentWrapper = styled('div')`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;
const Wrapper = styled('div')``;

const Content = styled('div')`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  height: 100%;
  overflow: hidden;
`;
const ContentWrapper = styled('div')`
  height: calc(100% - ${CREATE_REQUEST_FOOTER}px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[30]};
`;

const ButtonsWrapper = styled('div')`
  height: ${CREATE_REQUEST_FOOTER}px;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 0 40px;
`;

interface Props {
  onBackClick?: () => void;
  headerTitle: string;
  children: ReactNode;
  controlButtons?: ReactNode;
  sideBar?: ReactNode;
  onEditHeader?: () => void;
}

export const LayoutWithActionsFooter = ({
  onBackClick,
  headerTitle,
  sideBar,
  children,
  controlButtons,
  onEditHeader,
}: Props) => {
  return (
    <Wrapper>
      <HeaderWithBackBtn
        title={headerTitle}
        onBackClick={onBackClick}
        onEditHeader={onEditHeader}
      />
      <MainContentWrapper>
        {sideBar && sideBar}

        <Content>
          <ContentWrapper>{children}</ContentWrapper>
          <ButtonsWrapper>{controlButtons}</ButtonsWrapper>
        </Content>
      </MainContentWrapper>
    </Wrapper>
  );
};
