import { Checkbox, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { SurveyQuestion } from '../../../../../types';
import { useMemo } from 'react';
import { useBreakdownData } from '../../../hooks/useBreakdownData';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

interface Props {
  question: SurveyQuestion;
}

export const QuestionSelectItem = ({ question }: Props) => {
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const { allSurveysQuestions } = useBreakdownData();

  const onToggleSelect = () => {
    if (!aggregatedReport) return;

    let updatedSelectedQuestions = aggregatedReport?.selectedQuestions;

    if (aggregatedReport?.selectedQuestions?.includes(question.id)) {
      updatedSelectedQuestions = aggregatedReport.selectedQuestions =
        aggregatedReport.selectedQuestions?.filter((qId) => qId !== question.id);
    } else {
      const selectedQuestions = aggregatedReport?.selectedQuestions
        ? [...aggregatedReport.selectedQuestions, question.id]
        : [question.id];
      updatedSelectedQuestions = allSurveysQuestions
        ?.filter((q) => selectedQuestions.includes(q.id))
        .map((q) => q.id);
    }

    setAggregatedReport({ ...aggregatedReport, selectedQuestions: updatedSelectedQuestions });
  };

  const isSelected = useMemo(() => {
    return aggregatedReport?.selectedQuestions?.includes(question.id);
  }, [aggregatedReport?.selectedQuestions, question.id]);

  return (
    <Wrapper>
      <Checkbox checked={isSelected} onClick={onToggleSelect} style={{ padding: 0 }} />
      <Typography variant='body' color={colors.primary[90]}>
        {question.name}
      </Typography>
    </Wrapper>
  );
};
