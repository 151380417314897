import { useQuery } from 'react-query';
import { Http } from '../services/Http';
import { CompanyFilterOptions } from '../types';

const COMPANY_FILTER_OPTIONS = 'companyFilterOptions';

async function fetchCompanyFilterOptions(): Promise<CompanyFilterOptions> {
  const { data } = await Http.axios.get<CompanyFilterOptions>(`/company/filter-options`);

  return data;
}

export function useCompanyFilterOptions() {
  return useQuery(`${COMPANY_FILTER_OPTIONS}`, fetchCompanyFilterOptions, {
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });
}
