import { Button, styled } from '@mui/material';
import { useState } from 'react';
import { WidgetModal } from './WidgetModal';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';
import { generateId } from '../../../../../../utils/generateId';
import { WIDGET_TYPE } from '../../../../../../types';

const Wrapper = styled('div')``;

export const AddWidget = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const handleSubmit = ({
    questionId,
    widgetType,
  }: {
    questionId: string;
    widgetType: WIDGET_TYPE;
  }) => {
    if (!aggregatedReport) return;

    const widget = {
      id: generateId(),
      questionId,
      widgetType,
    };

    setAggregatedReport({
      ...aggregatedReport,
      widgets: [...(aggregatedReport?.widgets || []), widget],
    });

    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      <Button
        style={{ display: 'flex', width: '100%' }}
        variant='outlined'
        onClick={() => setIsModalOpen(true)}
      >
        + Add Widget
      </Button>
      <WidgetModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title='Add Widget'
        confirmBtnText='Add Widget'
        handleSubmit={handleSubmit}
      />
    </Wrapper>
  );
};
