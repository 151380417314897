import { styled } from '@mui/material';
import { useAtom } from 'jotai';
import {
  isConfirmBarActiveState,
  isMoveModeActiveState,
  selectedCoreKpiCategoryState,
  selectedCoreKpiState,
  selectedCoreKpisState,
} from '../../state/UIState';
import { useEffect, useMemo } from 'react';
import { MainContent } from './components/MainContent';
import { useAppConfig } from '../../queries/useAppConfig';

import { DataCollectionContent, DataCollectionHeader } from '../../components/DataCollectionLayout';
import { useConfirmBarActions } from './hooks/useConfirmBarActions';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const CoreKpis = () => {
  const [isActive, setIsActive] = useAtom(isConfirmBarActiveState);
  const [isMoveModeActive] = useAtom(isMoveModeActiveState);
  const [selectedCategory] = useAtom(selectedCoreKpiCategoryState);
  const [selectedKpi] = useAtom(selectedCoreKpiState);
  const [selectedKpis] = useAtom(selectedCoreKpisState);
  const { data: appConfigData } = useAppConfig();

  const { onCancel, onConfirm, onUpdateKpiSurvey, onResetFromScreenshot, areControlsDisabled } =
    useConfirmBarActions();

  const confirmActionBarText = useMemo(() => {
    if (isMoveModeActive && selectedKpi && selectedCategory)
      return `Move this KPI to ${selectedCategory?.name}`;
    if (isMoveModeActive && selectedKpi) return `Select Category to move this KPI`;
    if (isMoveModeActive && selectedKpis.length >= 1 && selectedCategory)
      return `Move these KPIs to ${selectedCategory?.name}`;
    if (isMoveModeActive && selectedKpis.length >= 1) return `Select Category to move these KPIs`;
    if (isActive && !appConfigData?.data.isCoreKpiSurveyUpToDate)
      return 'Update the Survey to reflect changes in Core KPIs for all active requests.';
    return '';
  }, [
    appConfigData?.data.isCoreKpiSurveyUpToDate,
    isActive,
    isMoveModeActive,
    selectedCategory,
    selectedKpi,
    selectedKpis.length,
  ]);

  useEffect(() => {
    if (!appConfigData?.data) return;
    setIsActive(!appConfigData.data.isCoreKpiSurveyUpToDate);
  }, [appConfigData, setIsActive]);

  return (
    <Wrapper>
      <DataCollectionHeader
        title='Manage Core KPIs'
        description='Used for all companies’ data display'
      />
      <DataCollectionContent
        text={confirmActionBarText}
        confirmText={isMoveModeActive ? 'Move' : 'Update'}
        cancelText={isMoveModeActive ? 'Cancel' : 'Discard Changes'}
        onCancel={isMoveModeActive ? onCancel : onResetFromScreenshot}
        onConfirm={isMoveModeActive ? onConfirm : onUpdateKpiSurvey}
        isActive={isActive}
        isConfirmDisabled={(isMoveModeActive && !selectedCategory) || areControlsDisabled}
        isCancelDisabled={areControlsDisabled}
        cancelButtonStyle={{ width: '150px' }}
      >
        <MainContent />
      </DataCollectionContent>
    </Wrapper>
  );
};
