import { IconButton, styled, useTheme } from '@mui/material';
import { TAggregatedReportGroup } from '../../../../../types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useRef } from 'react';
import { ReactComponent as DragIcon } from '../../../../../assets/icons/drag.svg';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-gray.svg';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const DragWrapper = styled('div')`
  position: relative;
  padding: 0 25px;
`;

const SortableWrapper = styled('div')``;

const GroupContainer = styled('div')`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GroupNameWrapper = styled('div')`
  padding: 4px 12px;
`;

const DeleteIconWrapper = styled(IconButton)`
  padding: 8px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
  display: flex;
  align-items: center;
  width: 32px;
  border-radius: 0;
`;

interface Props {
  group: TAggregatedReportGroup;
}

export const GroupItem = ({ group }: Props) => {
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const { setNodeRef, transform, transition, attributes, listeners, isDragging } =
    useSortable(group);

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const onRemoveGroup = useCallback(
    (group: TAggregatedReportGroup) => {
      if (!aggregatedReport) return;
      const updatedGroups = aggregatedReport?.groupedBy?.filter((g) => g.id !== group.id);

      setAggregatedReport({ ...aggregatedReport, groupedBy: updatedGroups });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <SortableWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...style, opacity: isDragging ? 0.5 : 1, zIndex: isDragging ? 2 : 1 }}
    >
      <DragWrapper ref={wrapperRef}>
        <DragIcon style={{ position: 'absolute', left: '7px', top: '5', cursor: 'pointer' }} />

        <GroupContainer>
          <GroupNameWrapper>
            <Typography variant='body' color={colors.primary[90]}>
              {group.name}
            </Typography>
          </GroupNameWrapper>
          <DeleteIconWrapper onClick={() => onRemoveGroup(group)}>
            <CloseIcon />
          </DeleteIconWrapper>
        </GroupContainer>
      </DragWrapper>
    </SortableWrapper>
  );
};
