import { SelectItem } from '../types';
import { SingleSelect } from './SingleSelect/SingleSelect';

interface Props {
  selectedType: SelectItem | null;
  sortedUniqueTypes: SelectItem[];
  onChangeTypeFilter: (type: SelectItem | null) => void;
  width?: string;
  placeholder?: string;
}

export const CompanyTypeFilter = ({
  selectedType,
  sortedUniqueTypes,
  onChangeTypeFilter,
  width,
  placeholder,
}: Props) => {
  return (
    <SingleSelect
      style={{ width: width || '200px' }}
      options={sortedUniqueTypes}
      value={selectedType}
      onChange={(_, type) => onChangeTypeFilter(type)}
      disablePortal
      fieldPlaceholder={placeholder || 'Filter by Type'}
    />
  );
};
