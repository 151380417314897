import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  AggregatedReportWidget,
  QUESTION_TYPE,
  WidgetCompanyAnswer,
} from '../../../../../../types';
import { useCompaniesAnswers } from '../hooks/useCompaniesAnswers';
import { useWidgetQuestion } from '../hooks/useWidgetQuestion';
import { WidgetCard } from './WidgetCard';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { CompanyCell } from '../../../../../../components/Table/CellRenderers/CompanyCell';
import { Typography } from '../../../../../../components/Typography/Typography';
import { AnswerCell } from '../../../../../../components/Table/CellRenderers/AnswerCell';
import { Table } from '../../../../../../components/Table/Table';

const TableWrapper = styled('div')`
  padding-top: 10px;
`;

interface Props {
  widget: AggregatedReportWidget;
}

const columnHelper = createColumnHelper<WidgetCompanyAnswer>();

export const CompanyBreakdownWidget = ({ widget }: Props) => {
  const widgetQuestion = useWidgetQuestion(widget);
  const { colors } = useTheme();
  const [showAll, setShowAll] = useState(false);
  const tableInnerRef = useRef<HTMLTableElement | null>(null);
  const [showWidgetButton, setShowWidgetButton] = useState(false);

  const { companiesAnswers } = useCompaniesAnswers(widget);

  useEffect(() => {
    if (!tableInnerRef.current) return;

    const tableHeight = tableInnerRef.current?.clientHeight;
    setShowWidgetButton(tableHeight > 300);
  }, [tableInnerRef]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('companyName', {
        cell: (info) => <CompanyCell name={info.getValue()} logo={info.row.original.companyLogo} />,
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Company
          </Typography>
        ),
        meta: {
          width: '30%',
        },
      }),
      columnHelper.accessor('answer', {
        cell: (info) => (
          <AnswerCell
            answer={info.getValue()}
            type={widgetQuestion?.type || QUESTION_TYPE.SINGLE}
          />
        ),
        header: () => (
          <Typography variant='overline' color={colors.primary[70]}>
            Answer
          </Typography>
        ),
        meta: {
          width: '70%',
        },
        enableSorting: false,
      }),
    ],
    [colors.primary, widgetQuestion?.type]
  );

  const table = useReactTable({
    data: companiesAnswers,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onShowAllToggle = useCallback(() => {
    setShowAll((prev) => !prev);
  }, []);

  const buttonText = useMemo(() => {
    if (showAll) return 'Show less';
    return 'Show more';
  }, [showAll]);

  if (!widgetQuestion) return null;

  return (
    <WidgetCard widget={widget}>
      <TableWrapper>
        <Table tableInnerRef={tableInnerRef} table={table} height={showAll ? 'auto' : '300px'} />
        {showWidgetButton && (
          <Button variant='text' onClick={onShowAllToggle} style={{ marginTop: '10px' }}>
            <Typography variant='subtitle2' color={colors.accent[50]}>
              {buttonText}
            </Typography>
          </Button>
        )}
      </TableWrapper>
    </WidgetCard>
  );
};
