import { Dispatch, SetStateAction, useMemo } from 'react';
import { Objective } from '../../../../../types';
import { ObjectiveSummary } from './components/ObjectiveSummary/ObjectiveSummary';
import { KeyTasksTable } from './components/KeyTasksTable/KeyTasksTable';
import { RoundedAccordion } from '../../../../../components/RoundedAccordion/RoundedAccordion';

interface Props {
  objective: Objective;
  expandedObjectiveId: number | null;
  setExpandedObjectiveId: Dispatch<SetStateAction<number | null>>;
}

export const ObjectivesAccordion = ({
  objective,
  expandedObjectiveId,
  setExpandedObjectiveId,
}: Props) => {
  const hasKeyTasks = useMemo(() => {
    return Boolean(objective?.keyTasks?.length);
  }, [objective?.keyTasks?.length]);

  const handleChange = (id: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (!hasKeyTasks) return;
    setExpandedObjectiveId(newExpanded ? id : null);
  };

  return (
    <RoundedAccordion
      isExpanded={expandedObjectiveId === objective?.id}
      onChange={handleChange(objective.id)}
      accordionSummary={
        <ObjectiveSummary objective={objective} setExpandedObjectiveId={setExpandedObjectiveId} />
      }
      accordionDetails={hasKeyTasks && <KeyTasksTable keyTasks={objective?.keyTasks} />}
      hasDetails={hasKeyTasks}
    />
  );
};
