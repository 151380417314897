import { Button, styled, useTheme } from '@mui/material';
import { useBreakdownData } from '../../../hooks/useBreakdownData';
import { useEffect, useMemo, useState } from 'react';
import { Accordion } from '../../../../../components/Accordion/Accordion';
import { QuestionSelectItem } from './QuestionSelectItem';
import { Typography } from '../../../../../components/Typography/Typography';
import { TReportSurveyData } from '../../../../../types';
import { SearchInput } from '../../../../../components/SearchInput/SearchInput';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const Wrapper = styled('div')`
  padding: 20px 0;
`;

const QuestionsWrapper = styled('div')`
  padding: 16px 0;
`;

const QuestionsList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const QuestionsFilters = () => {
  const { allSurveysMap, allSurveysQuestions } = useBreakdownData();
  const [expanded, setExpanded] = useState<number | null>(null);
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);
  const [filteredSurveysMap, setFilteredSurveysMap] =
    useState<Record<string, TReportSurveyData>>(allSurveysMap);
  const [searchValue, setSearchValue] = useState('');

  const { colors } = useTheme();

  useEffect(() => {
    setFilteredSurveysMap(allSurveysMap);
  }, [allSurveysMap, setFilteredSurveysMap]);

  const allSurveys = useMemo(() => {
    return filteredSurveysMap ? Object.values(filteredSurveysMap) : [];
  }, [filteredSurveysMap]);

  const onSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const filteredQuestions = allSurveysQuestions?.filter((question) =>
      question.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const allSurveys = allSurveysMap ? Object.values(allSurveysMap) : [];
    const filteredSurveys = allSurveys?.filter((survey) => {
      return survey.questions.some((question) =>
        filteredQuestions?.some((q) => q.id === question.id)
      );
    });

    const filteredSurveyMap = filteredSurveys?.reduce(
      (acc: Record<string, TReportSurveyData>, curr) => {
        const surveyData = {
          id: curr.id,
          name: curr.name,
          questions: curr.questions.filter((question) => {
            const filteredQuestionIds = filteredQuestions?.map((q) => q.id);
            return filteredQuestionIds?.includes(question.id);
          }),
        };
        return {
          ...acc,
          [curr.id]: surveyData,
        };
      },
      {}
    );
    setFilteredSurveysMap(filteredSurveyMap);
  };

  const onToggleExpanded = (surveyId: number) => {
    if (expanded === surveyId) {
      setExpanded(null);
      return;
    }
    setExpanded(surveyId);
  };

  const getIsAllSelected = (survey: TReportSurveyData) => {
    const isAllSelected = survey?.questions?.every((q) =>
      aggregatedReport?.selectedQuestions?.includes(q.id)
    );
    return isAllSelected;
  };

  const onToggleSelectAll = (survey: TReportSurveyData) => {
    if (!aggregatedReport) return;
    const isAllSelected = getIsAllSelected(survey);
    const surveyQuestionsIds = survey.questions.map((q) => q.id) || [];

    if (isAllSelected) {
      const updatedSelectedQuestions = aggregatedReport?.selectedQuestions?.filter(
        (qId) => !surveyQuestionsIds.includes(qId)
      );

      setAggregatedReport({ ...aggregatedReport, selectedQuestions: updatedSelectedQuestions });

      return;
    }

    const selectedQuestions = aggregatedReport?.selectedQuestions
      ? [...aggregatedReport.selectedQuestions, ...surveyQuestionsIds]
      : surveyQuestionsIds;

    const updatedSelectedQuestions = allSurveysQuestions
      ?.filter((q) => selectedQuestions.includes(q.id))
      .map((q) => q.id);

    setAggregatedReport({ ...aggregatedReport, selectedQuestions: updatedSelectedQuestions });
  };

  return (
    <Wrapper>
      <SearchInput
        placeholder='Search for a question'
        style={{ width: '100%', marginRight: '12px' }}
        onClear={() => onSearch('')}
        onChange={(e) => onSearch(e.target.value)}
        value={searchValue}
      />
      <QuestionsWrapper>
        {allSurveys?.map((survey) => {
          const isAllSelected = getIsAllSelected(survey);
          return (
            <Accordion
              key={survey.id}
              title={survey.name}
              isExpanded={expanded === survey.id}
              onExpandToggle={() => onToggleExpanded(survey.id)}
              suffixButton={
                <Button variant='text' onClick={() => onToggleSelectAll(survey)}>
                  <Typography variant='subtitle2' color={colors.accent[50]}>
                    {isAllSelected ? 'Deselect All' : 'Select All'}
                  </Typography>
                </Button>
              }
            >
              <QuestionsList>
                {survey?.questions?.map((question) => {
                  return <QuestionSelectItem key={question.id} question={question} />;
                })}
              </QuestionsList>
            </Accordion>
          );
        })}
      </QuestionsWrapper>
    </Wrapper>
  );
};
