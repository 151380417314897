import { QUESTION_TYPE, QuestionTableData, SurveyQuestion, SurveySection } from '../types';
import { generateId } from '../utils/generateId';

const DEAFAULT_QUESTION_OPTION = {
  id: generateId(),
  name: 'Option 1',
  isFlagged: false,
};

export const getDefaultQuestionOption = (): typeof DEAFAULT_QUESTION_OPTION => ({
  ...DEAFAULT_QUESTION_OPTION,
  id: generateId(),
});

export const getDefaultSingleTypeQuestionOptions = () => [
  {
    name: 'Yes',
    id: generateId(),
    isFlagged: false,
  },
  {
    name: 'No',
    id: generateId(),
    isFlagged: false,
  },
];

export const HEADER_COLUMN_ID = 'header_column';
export const HEADER_ROW_ID = 'header_row';
export const KPI_DEFINITION_COLUMN_ID = 'kpi_definition_column';
export const KPI_METRIC_COLUMN_ID = 'kpi_metric_column';
export const KPI_VALUE_COLUMN_ID = 'kpi_value_column';
export const KPI_FORMAT_COLUMN_ID = 'kpi_format_column';
export const KPI_EXCEL_NOTES_COLUMN_ID = 'kpi_excel_notes_column';

export const getDefaultTableData = (): QuestionTableData => {
  return {
    columns: [HEADER_COLUMN_ID, generateId()],
    rows: [
      {
        id: HEADER_ROW_ID,
      },
      {
        id: generateId(),
      },
    ],
  };
};

const DEAFAULT_SURVEY_QUESTION: Partial<SurveyQuestion> = {
  name: 'Question 1',
  type: QUESTION_TYPE.TEXT,
  options: [],
  tableData: getDefaultTableData(),
  includeInstructions: false,
  instructions: '',
  isActive: true,
};

export const getDefaultSurveyQuestion = (sectionId: string): SurveyQuestion =>
  ({
    ...DEAFAULT_SURVEY_QUESTION,
    id: generateId(),
    sectionId: sectionId,
  } as SurveyQuestion);

const DEAFAULT_SURVEY_SECTION: Partial<SurveySection> = {
  name: 'Section 1',
};

export const getDefaultSurveySection = (): SurveySection => {
  const id = generateId();
  return {
    ...DEAFAULT_SURVEY_SECTION,
    id,
    questions: [],
    subSections: [],
    depth: 0,
    parentId: null,
    collapsed: true,
  } as SurveySection;
};

export const DEFAULT_SURVEY_TITLE = 'New Survey';

export const COMPANY_EXCEL_HEADERS = [
  { header: 'Name', key: 'name', width: 40 },
  { header: 'Analyst', key: 'analyst', width: 25 },
  { header: 'Type (Not Controlled/Controlled)', key: 'type', width: 30 },
];

export const COMPANY_KPIS_EXCEL_HEADERS = [
  { header: 'KPI ID', key: 'id' },
  { header: 'KPI', key: 'name' },
  { header: 'METRIC', key: 'metric' },
  { header: 'UNIT', key: 'format' },
];

export const REQUIRED_COMPANY_EXCEL_FIELDS = ['Name'];
