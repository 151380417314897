import { styled } from '@mui/material';
import { AggregatedReportBreakdownGroup } from '../../../../../../types';
import { GroupedBreakdownTable } from './GroupedBreakdownTable';
import { Subgroups } from './Subgroups/Subgroups';

interface Props {
  group: AggregatedReportBreakdownGroup;
  tablesMap: Record<string, HTMLDivElement>;
  onScroll: React.UIEventHandler<HTMLDivElement>;
}

const Container = styled('div')`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.accent[5]};
`;

export const GroupDetails = ({ group, tablesMap, onScroll }: Props) => {
  if (!group?.subgroups?.length)
    return (
      <GroupedBreakdownTable
        onScroll={onScroll}
        tableRef={tablesMap[group.groupName]}
        tableData={group.tableData || []}
      />
    );
  return (
    <Container>
      <Subgroups group={group} subgroups={group.subgroups || []} tablesMap={tablesMap} onScroll={onScroll} />
    </Container>
  );
};
