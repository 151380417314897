import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ChartDataItem } from '../../types';
import { PieChart } from '../Charts/PieChart/PieChart';

const margin = { top: 60, right: 60, bottom: 50, left: 60 };

const PieWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 2px;
  padding: 16px 24px;
`;

const TotalWrapper = styled('div')`
  position: absolute;
  top: ${margin.top}px;
  right: ${margin.right}px;
  bottom: ${margin.bottom}px;
  left: ${margin.left}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
`;

interface Props {
  title: string;
  totalLabel?: string;
  totalValue?: number;
  data: ChartDataItem[];
}

export const ComposurePieChart = ({ title, totalLabel, totalValue, data }: Props) => {
  const { primary } = useTheme().colors;

  return (
    <PieWrapper>
      <Typography variant={'subtitle2'} color={primary[90]}>
        {title}
      </Typography>
      <PieChart data={data} />
      <TotalWrapper>
        {totalLabel && (
          <Typography variant={'subtitle2'} color={primary[70]}>
            {totalLabel}
          </Typography>
        )}
        <Typography
          variant={'numbers'}
          style={{ fontSize: '32px', fontWeight: 600, marginTop: '10px' }}
          color={primary[80]}
        >
          {totalValue}
        </Typography>
      </TotalWrapper>
    </PieWrapper>
  );
};
