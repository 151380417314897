import { Fade, IconButton, styled, useTheme } from '@mui/material';
import { FC, useCallback } from 'react';
import { Typography } from '../../../../../components/Typography/Typography';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/add-gray.svg';
import { SurveyQuestionTemplate } from '../../../../../types';
import { useAtom } from 'jotai';
import {
  activeQuestionIdState,
  activeSectionIdState,
  isApplyingEvaluationRulesModeActiveState,
  isMultiActionMoveModeActiveState,
  isSingleActionMoveModeActiveState,
  sectionState,
  selectedQuestionsState,
} from '../../../../../state/UIState';
import { generateId } from '../../../../../utils/generateId';
import { getDefaultTableData } from '../../../../../constants/defaultValues';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';

interface Props {
  questions: SurveyQuestionTemplate[];
}

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const QuestionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0px 2px 4px;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  border-radius: 4px;
`;

export const TemplateQuestionList: FC<Props> = ({ questions }) => {
  const { colors } = useTheme();
  const [activeSectionId] = useAtom(activeSectionIdState);
  const [activeSection, setSection] = useAtom(sectionState(activeSectionId));
  const [, setActiveQuestionId] = useAtom(activeQuestionIdState);
  const [isApplyingModeActive] = useAtom(isApplyingEvaluationRulesModeActiveState);
  const [isSingleActionMoveModeActive] = useAtom(isSingleActionMoveModeActiveState);
  const [isMultiActionMoveModeActive] = useAtom(isMultiActionMoveModeActiveState);
  const [selectedQuestions] = useAtom(selectedQuestionsState);

  const onAddQuestionToSurvey = useCallback(
    (question: SurveyQuestionTemplate) => {
      if (!activeSection) return;
      const newQuestionId = generateId();
      const updatedSection = {
        ...activeSection,
        questions: [
          ...activeSection.questions,
          {
            id: newQuestionId,
            name: question.name,
            type: question.type,
            instructions: question.instructions,
            tableData: question?.tableData || getDefaultTableData(),
            includeInstructions: question.includeInstructions,
            includeEvaluation: question.includeEvaluation,
            evaluationRules: question.evaluationRules || null,
            sectionId: activeSection.id,
            isActive: true,
            isRequired: true,
            options:
              question.options?.map((option) => ({
                id: generateId(),
                name: option.name,
              })) || [],
          },
        ],
      };
      setSection(updatedSection);
      setActiveQuestionId(newQuestionId);
    },
    [activeSection, setActiveQuestionId, setSection]
  );

  return (
    <Wrapper>
      {questions.map((question) => (
        <QuestionWrapper key={question.id}>
          <Typography variant='subtitle2' color={colors.primary[90]}>
            {question.name}
          </Typography>
          <Tooltip
            title='Add Question to Survey'
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [70, -13],
                  },
                },
              ],
            }}
          >
            <IconButton
              style={{
                width: '30px',
                height: '30px',
                visibility:
                  isApplyingModeActive ||
                  isSingleActionMoveModeActive ||
                  isMultiActionMoveModeActive ||
                  selectedQuestions.length
                    ? 'hidden'
                    : 'visible',
              }}
              onClick={() => onAddQuestionToSurvey(question)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </QuestionWrapper>
      ))}
    </Wrapper>
  );
};
