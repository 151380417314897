import { createColumnHelper, DisplayColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { CompanyCell } from '../Table/CellRenderers/CompanyCell';
import { useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { PortfolioCompanyData } from './AssessmentSummaryTable';
import { RiskLevelCell } from './RiskLevelCell';
import { COMPANY_TYPE } from '../../types';
import { FlagsIndicator } from '../FlagsIndicator/FlagsIndicator';
import { useAllSteps } from '../../hooks/useAllSteps';
import { formatDate } from '../../utils/formatters';
import { getGovernanceScoreLabel, showGovernanceScore } from '../../utils/governanceScoreUtils';
import { generateId } from '../../utils/generateId';
import { filterByGovernanceScoreType } from '../../utils/filterByGovernanceScoreType';

const columnHelper = createColumnHelper<PortfolioCompanyData>();

export const useAssessmentSummaryColumns = (companiesType?: COMPANY_TYPE) => {
  const { colors } = useTheme();

  const { allUniqueAssessmentSteps: allAssessmentSteps } = useAllSteps(companiesType);

  return useMemo(() => {
    const nameCol = columnHelper.accessor('name', {
      cell: (info) => (
        <CompanyCell
          name={info.getValue()}
          logo={info.row.original.logoUrl}
          labelColor={colors.accent[50]}
        />
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          {companiesType === COMPANY_TYPE.CONTROLLED ? 'Company' : 'Issuers'}
        </Typography>
      ),
      meta: {
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
      },
      enableColumnFilter: true,
    });

    const esgRiskCol = columnHelper.accessor('esgRiskLevel', {
      cell: (info) => <RiskLevelCell riskLevel={info.getValue()} />,
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          ESG Risk Level
        </Typography>
      ),
      meta: {
        width: '156px',
        minWidth: '156px',
        maxWidth: '156px',
      },
      enableColumnFilter: true,
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
    });

    const esgOpportunitiesCol = columnHelper.accessor('esgOpportunitiesLevel', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          ESG Opportunities
        </Typography>
      ),
      meta: {
        width: '156px',
        minWidth: '156px',
        maxWidth: '156px',
      },
      enableColumnFilter: true,
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
    });
    const analystColumn = columnHelper.accessor('analyst', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Analyst
        </Typography>
      ),
      meta: {
        width: '200px',
        minWidth: '200px',
        maxWidth: '200px',
      },
      enableColumnFilter: true,
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId) || '';
        return selectedFilters.includes(value);
      },
    });

    const governanceScoreCol = columnHelper.accessor('governanceScore', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]} style={{ textAlign: 'right' }}>
          {showGovernanceScore(info.getValue()) ? getGovernanceScoreLabel(info.getValue()) : ''}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Good Governance Assessment
        </Typography>
      ),
      meta: {
        width: '160px',
        minWidth: '160px',
        maxWidth: '160px',
        tdStyles: {
          paddingRight: '50px',
        },
      },
      enableColumnFilter: true,
      filterFn: (row: any, columnId, selectedFilters) => {
        const value: string = row.getValue(columnId);

        return filterByGovernanceScoreType(parseInt(value), selectedFilters);
      },
    });

    const screeningCompletedDateColumn = columnHelper.accessor('screeningCompletedDate', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]}>
          {formatDate(info.getValue() || '')}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Screening Completed Date
        </Typography>
      ),
      meta: {
        width: '180px',
        minWidth: '180px',
        maxWidth: '180px',
      },
      filterFn: 'equalsString',
    });

    const issuerTypeCol = columnHelper.accessor('type', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Issuer Type
        </Typography>
      ),
      meta: {
        width: '156px',
        minWidth: '156px',
        maxWidth: '156px',
      },
      filterFn: 'equalsString',
    });

    const activePosition = columnHelper.accessor('isActivePosition', {
      cell: (info) => (
        <Typography variant='subtitle1' color={colors.primary[90]}>
          {info.getValue()}
        </Typography>
      ),
      header: () => (
        <Typography variant='overline' color={colors.primary[70]}>
          Is Active Position
        </Typography>
      ),
      meta: {
        width: '156px',
        minWidth: '156px',
        maxWidth: '156px',
      },
      filterFn: 'equalsString',
    });

    const staticColumns =
      companiesType === COMPANY_TYPE.CONTROLLED
        ? [nameCol, esgRiskCol, esgOpportunitiesCol]
        : [
            nameCol,
            analystColumn,
            esgRiskCol,
            esgOpportunitiesCol,
            governanceScoreCol,
            screeningCompletedDateColumn,
            issuerTypeCol,
            activePosition,
          ];

    const dynamicColumns: DisplayColumnDef<PortfolioCompanyData>[] =
      allAssessmentSteps
        ?.map?.((step) => {
          return columnHelper.display({
            id: String(step?.survey?.id || generateId()),
            cell: (info) => {
              const flagNum = info.row.original.assessmentReportFlags?.find(
                (reportFlag) => reportFlag.surveyId === step?.survey?.id
              )?.flagsNum;
              return flagNum === undefined ? null : <FlagsIndicator flagsNum={flagNum || 0} />;
            },
            header: () => (
              <Typography variant='overline' color={colors.primary[70]}>
                {step?.name}
              </Typography>
            ),
            meta: {
              width: '215px',
              minWidth: '215px',
              maxWidth: '215px',
            },
            enableSorting: false,
          });
        })
        ?.filter((col) => Boolean(col)) || [];

    return [...staticColumns, ...dynamicColumns];
  }, [allAssessmentSteps, colors.accent, colors.primary, companiesType]);
};
