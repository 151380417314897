import {
  QUESTION_TYPE,
  QuestionOption,
  Report,
  REVIEW_TYPE,
  SingleAssessmentQuestionRow,
  SurveyQuestion,
} from '../types';
import { flattenTree } from './treeUtilities';

export const getAssessmentExportQuestions = (report: Report): SingleAssessmentQuestionRow[] => {
  const sections = flattenTree(report.survey.sections);
  const questions = sections.map((section) => section.questions).flat();

  if (!questions?.length) return [];

  const formatQuestion = (question: SurveyQuestion, order: string) => {
    return {
      id: question.id,
      question: `${order}. ${question.name}`,
      answer: report?.response?.[question.id] || '',
      overrideAnswer: report.review?.[question.id]?.overrideResponse,
      overriddenBy:
        report.review?.[question.id]?.type === REVIEW_TYPE.OVERRIDE
          ? report.review?.[question?.id]?.reviewedBy?.fullName
          : '',
      overrideReason:
        report.review?.[question?.id]?.type === REVIEW_TYPE.OVERRIDE
          ? report.review?.[question?.id]?.reason
          : '',
      type: question.type,
      evaluationScore: report.evaluation?.[question?.id]?.weight,
      evaluationColor: report.evaluation?.[question?.id]?.color,
      evaluationLabel: report.evaluation?.[question?.id]?.name,
    };
  };

  return questions
    .map((question, i) => {
      if (question.type === QUESTION_TYPE.SINGLE) {
        const selectedOption = question.options.find(
          (o) => (report?.response[question.id] as QuestionOption)?.id === o.id
        );

        const subQuestions = selectedOption?.subQuestions || [];

        const formattedSubQuestions = subQuestions.map((subQuestion, index) =>
          formatQuestion(subQuestion, `${i + 1}.${index + 1}`)
        );

        return [formatQuestion(question, `${i + 1}`), ...formattedSubQuestions];
      }

      return formatQuestion(question, `${i + 1}`);
    })
    .flatMap((q) => q);
};
