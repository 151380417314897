import { useAtom, useAtomValue } from 'jotai';
import {
  activeRoleState,
  companySearchState,
  selectedCompanyAnalystsFilterState,
  selectedCompanyCountriesFilterState,
  selectedCompanySectorsFilterState,
  selectedCompanyStageFilterState,
  selectedCompanyTypeFilterState,
} from '../../../state/UIState';
import { COMPANY_STAGE, COMPANY_TYPE, USER_ROLE } from '../../../types';
import { styled } from '@mui/system';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { CompanyTypeFilter } from '../../../components/Filters/CompanyTypeFilter';
import { StageFilter } from '../../../components/Filters/StageFilter';
import { useCompanyFilterOptions } from '../../../queries/useCompanyFilterOptions';
import { SectorsFilter } from '../../../components/Filters/SectorsFilter';
import { useCallback, useMemo } from 'react';
import { CountriesFilter } from '../../../components/Filters/CountriesFilter';
import { AnalystFilter } from '../../../components/Filters/AnalystFilter';
import { ResetFiltersButton } from '../../../components/ResetFiltersButton/ResetFiltersButton';

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const sortedUniqueTypes = [
  { id: COMPANY_TYPE.NOT_CONTROLLED, value: COMPANY_TYPE.NOT_CONTROLLED },
  { id: COMPANY_TYPE.CONTROLLED, value: COMPANY_TYPE.CONTROLLED },
];

const stages = [
  { id: COMPANY_STAGE.SCREENING, value: COMPANY_STAGE.SCREENING },
  { id: COMPANY_STAGE.ESG_COMPLETE, value: COMPANY_STAGE.ESG_COMPLETE },
];

export const CompanyFilters = () => {
  const activeRole = useAtomValue(activeRoleState);
  const [searchTerm, setSearchTerm] = useAtom(companySearchState);
  const [selectedType, setSelectedType] = useAtom(selectedCompanyTypeFilterState);
  const [selectedStage, setSelectedStage] = useAtom(selectedCompanyStageFilterState);
  const [selectedSectors, setSelectedSectors] = useAtom(selectedCompanySectorsFilterState);
  const [selectedCountries, setSelectedCountries] = useAtom(selectedCompanyCountriesFilterState);
  const [selectedAnalysts, setSelectedAnalysts] = useAtom(selectedCompanyAnalystsFilterState);

  const { data: companiesFilters } = useCompanyFilterOptions();

  const sortedUniqueSectors = useMemo(() => {
    return companiesFilters?.sectors
      ?.map((sector) => ({
        id: sector.id,
        value: sector.name,
      }))
      ?.sort((a, b) => a.value.localeCompare(b.value));
  }, [companiesFilters?.sectors]);

  const sortedUniqueCountries = useMemo(() => {
    return companiesFilters?.countries
      ?.map((country, i) => ({
        id: i,
        value: country,
      }))
      ?.sort((a, b) => a.value.localeCompare(b.value));
  }, [companiesFilters?.countries]);

  const sortedUniqueAnalysts = useMemo(() => {
    return companiesFilters?.analysts
      ?.map((analyst, i) => ({
        id: i,
        value: analyst,
      }))
      ?.sort((a, b) => a.value.localeCompare(b.value));
  }, [companiesFilters?.analysts]);

  const onResetFilters = useCallback(() => {
    setSelectedSectors([]);
    setSelectedCountries([]);
    setSelectedAnalysts([]);
    setSelectedType(null);
    setSelectedStage(null);
  }, [
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedSectors,
    setSelectedStage,
    setSelectedType,
  ]);

  const isResetDisabled = useMemo(() => {
    return (
      !selectedSectors.length &&
      !selectedCountries.length &&
      !selectedAnalysts.length &&
      !selectedType &&
      !selectedStage
    );
  }, [selectedAnalysts, selectedCountries, selectedSectors, selectedStage, selectedType]);

  if (
    activeRole &&
    [USER_ROLE.ANALYST, USER_ROLE.MANAGER, USER_ROLE.COMPLIANCE]?.includes(activeRole)
  ) {
    return (
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => setSearchTerm('')}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm || ''}
        />
        <CompanyTypeFilter
          selectedType={selectedType}
          sortedUniqueTypes={sortedUniqueTypes}
          onChangeTypeFilter={(val) => setSelectedType(val)}
        />

        <SectorsFilter
          sortedUniqueSectors={sortedUniqueSectors || []}
          onChangeSectorsFilter={(val) => setSelectedSectors(val)}
          selectedSectors={selectedSectors}
        />
        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
    );
  }

  return (
    <FiltersWrapper>
      <SearchInput
        placeholder='Search for a company'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => setSearchTerm('')}
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm || ''}
      />
      <CompanyTypeFilter
        selectedType={selectedType}
        sortedUniqueTypes={sortedUniqueTypes}
        onChangeTypeFilter={(val) => setSelectedType(val)}
      />
      <StageFilter
        selectedStage={selectedStage}
        sortedUniqueStages={stages}
        onChangeStageFilter={(val) => setSelectedStage(val)}
      />
      <SectorsFilter
        sortedUniqueSectors={sortedUniqueSectors || []}
        onChangeSectorsFilter={(val) => setSelectedSectors(val)}
        selectedSectors={selectedSectors}
      />
      <CountriesFilter
        sortedUniqueCountries={sortedUniqueCountries || []}
        onChangeCountriesFilter={(val) => setSelectedCountries(val)}
        selectedCountries={selectedCountries}
      />
      <AnalystFilter
        sortedUniqueAnalysts={sortedUniqueAnalysts || []}
        selectedAnalysts={selectedAnalysts}
        onChangeAnalystsFilter={(val) => setSelectedAnalysts(val)}
      />
      <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
    </FiltersWrapper>
  );
};
