import { IconButton, styled, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-gray-circle.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { companyKpisDataState, uploadedCompanyKpisFileState } from '../../../state/UIState';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { COMPANY_KPIS_EXCEL_HEADERS } from '../../../constants/defaultValues';
import { UploadExcelArea } from '../../../components/UploadExcelArea/UploadExcelArea';
import { useParams } from 'react-router';
import { useCompanyById } from '../../../queries/useCompanies';
import {
  CORE_KPI_FORMAT,
  CoreKpiRequestFrequency,
  KpisPeriodData,
  SURVEY_FREQUENCY,
} from '../../../types';
import { useKpiMatrix } from '../../SIngleCompany/hooks/useKpiMatrix';
import { readFile } from '../../../utils/readFile';
import ExcelJS from 'exceljs';

const Wrapper = styled('div')``;

const UploadedFileWrapper = styled('div')`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;
const DetailsWrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NameWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const UploadArea = () => {
  const { colors } = useTheme();
  const { pushErrorToast } = useToastMessage();
  const [file, setFile] = useAtom(uploadedCompanyKpisFileState);
  const [, setCompanyKpisData] = useAtom(companyKpisDataState);
  const { companyId } = useParams();
  const { data: company } = useCompanyById(Number(companyId));
  const { recalculateQuarterly, recalculateAnnualy } = useKpiMatrix();

  const resetState = useCallback(() => {
    setFile(null);
  }, [setFile]);

  const periodCols = useMemo(() => {
    if (!company) return [];
    return company.kpisData[company.kpisDataFrequency as CoreKpiRequestFrequency].map(
      (item) => item.period
    );
  }, [company]);

  const requiredHeaders = useMemo(() => {
    const baseHeaders = COMPANY_KPIS_EXCEL_HEADERS.map((header) => header.header);
    return [...baseHeaders, ...periodCols];
  }, [periodCols]);

  const getValue = (val?: string | number, format?: CORE_KPI_FORMAT) => {
    if (!val) return null;
    if (format === CORE_KPI_FORMAT.TEXT) return val;

    return parseFloat(val as string);
  };

  const updateCompanyKpisLocalState = useCallback(
    (data: Record<string, string | number>[]) => {
      if (!company) return;
      setCompanyKpisData((prev) => {
        if (!prev) return prev;
        const updatedCompanyKpisData = {
          ...prev,
          [company.kpisDataFrequency as CoreKpiRequestFrequency]: prev[
            company.kpisDataFrequency as CoreKpiRequestFrequency
          ].map(({ period, timestamp }) => {
            const updatedPeriodData: KpisPeriodData = { period, timestamp };
            data.forEach((item) => {
              const value = item[period.toLowerCase()];

              updatedPeriodData[Number(item['kpi id'])] = {
                value: getValue(value, item['unit'] as CORE_KPI_FORMAT),
                format: item['unit'] as CORE_KPI_FORMAT,
              };
            });
            return updatedPeriodData;
          }),
        };
        //if monthly data is uploaded, recalculate quarterly
        if (company.kpisDataFrequency === SURVEY_FREQUENCY.MONTHLY) {
          const updatedMonthlyPeriods = updatedCompanyKpisData[SURVEY_FREQUENCY.MONTHLY].map(
            (item) => item.period
          );
          updatedMonthlyPeriods.forEach((period) => {
            updatedCompanyKpisData[SURVEY_FREQUENCY.QUARTERLY] = recalculateQuarterly(
              updatedCompanyKpisData[SURVEY_FREQUENCY.MONTHLY],
              updatedCompanyKpisData[SURVEY_FREQUENCY.QUARTERLY],
              period
            )[0];
          });
        }
        // if monthly or quarterly data is uploaded, recalculate annualy
        if (
          company.kpisDataFrequency === SURVEY_FREQUENCY.MONTHLY ||
          company.kpisDataFrequency === SURVEY_FREQUENCY.QUARTERLY
        ) {
          const updatedQuarterlyPeriods = updatedCompanyKpisData[SURVEY_FREQUENCY.QUARTERLY]
            .map((item) => item.period)
            .filter((period) => period.includes('Q1'));
          updatedQuarterlyPeriods.forEach((period) => {
            updatedCompanyKpisData[SURVEY_FREQUENCY.ANNUALLY] = recalculateAnnualy(
              updatedCompanyKpisData[SURVEY_FREQUENCY.QUARTERLY],
              updatedCompanyKpisData[SURVEY_FREQUENCY.ANNUALLY],
              period
            );
          });
        }

        return updatedCompanyKpisData;
      });
    },
    [company, recalculateAnnualy, recalculateQuarterly, setCompanyKpisData]
  );

  const onDrop = useCallback(
    (files: File[]) => {
      resetState();
      if (!files.length) {
        pushErrorToast({ message: 'Invalid file type' });
        return;
      }
      const file = files[0];

      const workbook = new ExcelJS.Workbook();

      try {
        readFile(file, async (fileBuffer) => {
          if (!fileBuffer || typeof fileBuffer === 'string') return;

          const fileData = await workbook.xlsx.load(fileBuffer);
          const worksheet = fileData.getWorksheet(`${company?.name} kpis`);

          const rows = worksheet?.getRows(1, worksheet.actualRowCount);

          if (!rows || rows?.length < 2) {
            pushErrorToast({ message: 'Invalid Excel file' });
            return;
          }

          const [headersRow, ...rest] = rows;

          const headers = (headersRow?.values as ExcelJS.CellValue[])?.filter?.(
            (h: ExcelJS.CellValue) => h
          ) as string[];

          const dataRows = rest?.map((row) => row?.values as ExcelJS.CellValue[]);

          if (headers.length !== requiredHeaders.length) {
            pushErrorToast({ message: 'Invalid Excel file' });
            return;
          }

          requiredHeaders.forEach((header) => {
            if (!headers.includes(header)) {
              pushErrorToast({ message: `Invalid Excel file. Missing required header ${header}` });
              return;
            }
          });

          const data = dataRows.map((dataValues) => {
            const values = dataValues?.filter(
              (val) => typeof val === 'string' || typeof val === 'number'
            );
            const kpi = headers.reduce((obj: Record<string, string | number>, header, index) => {
              let value = values[index];

              if (index > 3 && values[3] !== CORE_KPI_FORMAT.TEXT && typeof value === 'string') {
                //replace comma with dot
                value = value.replace(',', '.');
                //remove all spaces from string and all non-numeric characters except for dot
                value = value.replace(/[^0-9.]/g, '');
              }

              obj[header.toLowerCase()] = value as string;
              return obj;
            }, {});
            return kpi;
          });

          setFile(file);
          updateCompanyKpisLocalState(data);
        });
      } catch (e) {
        console.error(e);
      }
    },
    [
      company?.name,
      pushErrorToast,
      requiredHeaders,
      resetState,
      setFile,
      updateCompanyKpisLocalState,
    ]
  );

  const getSizeLabel = (size: number) => {
    const sizeInKB = size / 1024;
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB > 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB > 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${size} Bytes`;
    }
  };

  return (
    <Wrapper>
      <UploadExcelArea onDrop={onDrop} />
      {file && (
        <UploadedFileWrapper>
          <DetailsWrapper>
            <NameWrapper>
              <FileIcon />
              <Typography variant='srOnly' color={colors.primary[90]}>
                {file.name}
              </Typography>
            </NameWrapper>
            <Typography variant='caption' color={colors.primary[70]}>
              {getSizeLabel(file.size)}
            </Typography>
          </DetailsWrapper>
          <IconButton onClick={resetState}>
            <CloseIcon />
          </IconButton>
        </UploadedFileWrapper>
      )}
    </Wrapper>
  );
};
