import { styled } from '@mui/material';
import { ConfirmActionBar } from '../ConfirmActionBar/ConfirmActionBar';
import { ReactNode } from 'react';

const MainContentWrapper = styled('div')`
  height: 100%;
`;

interface Props {
  text: string;
  confirmText: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isActive: boolean;
  isConfirmDisabled: boolean;
  isCancelDisabled?: boolean;
  children: ReactNode;
  cancelButtonStyle?: React.CSSProperties;
}

export const DataCollectionContent = ({
  text,
  confirmText,
  cancelText,
  onCancel,
  onConfirm,
  isActive,
  isConfirmDisabled,
  isCancelDisabled,
  children,
  cancelButtonStyle,
}: Props) => {
  return (
    <MainContentWrapper>
      <ConfirmActionBar
        text={text}
        confirmText={confirmText}
        cancelText={cancelText}
        onCancel={onCancel}
        onConfirm={onConfirm}
        isActive={isActive}
        isConfirmDisabled={isConfirmDisabled}
        isCancelDisabled={isCancelDisabled}
        cancelButtonStyle={cancelButtonStyle}
      />
      {children}
    </MainContentWrapper>
  );
};
