import { Button, styled } from '@mui/material';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg';
import { HeaderTitle } from '../../../components/Layout/HeaderTitle';

const MainSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ControllsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RequestsHeader = () => {
  const navigate = useNavigate();

  return (
    <MainSection>
      <HeaderTitle
        title='Requests'
        description='Requests to respond to ESG questionnaires ask companies to disclose sustainability information.'
      />

      <ControllsWrapper>
        <Button
          startIcon={<AddIcon />}
          variant='contained'
          style={{ width: '179px' }}
          onClick={() => navigate(`/${ROUTES.CREATE_REQUEST}/1`)}
        >
          Create Request
        </Button>
      </ControllsWrapper>
    </MainSection>
  );
};
