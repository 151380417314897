import { styled } from '@mui/material';
import { useBreakdownData } from '../../../hooks/useBreakdownData';
import { GroupAccordion } from './GroupAccordion';
import { createRef, useEffect, useState } from 'react';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const GroupedBreakdown = () => {
  const { aggregatedReportBreakdownGroups } = useBreakdownData();
  const [tablesMap, setTablesMap] = useState<Record<string, HTMLDivElement>>({});

  useEffect(() => {
    const subgroups = aggregatedReportBreakdownGroups?.flatMap((group) => group.subgroups || []);

    if (subgroups.length) {
      let subGroupsMap = {};
      aggregatedReportBreakdownGroups.forEach((group) => {
        group.subgroups?.forEach((subgroup) => {
          subGroupsMap = {
            ...subGroupsMap,
            [group.groupName + subgroup.groupName]: createRef<HTMLDivElement>(),
          };
        });
      });

      setTablesMap(subGroupsMap);

      return;
    }

    const aggregatedReportBreakdownGroupsMap = aggregatedReportBreakdownGroups.reduce(
      (acc, group) => {
        return {
          ...acc,
          [group.groupName]: createRef<HTMLDivElement>(),
        };
      },
      {}
    );

    setTablesMap({
      ...aggregatedReportBreakdownGroupsMap,
    });
  }, [aggregatedReportBreakdownGroups, aggregatedReportBreakdownGroups.length]);

  const syncScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const tables = Object.values(tablesMap);
    tables.forEach((ref) => {
      if ((ref as any)?.current) {
        (ref as any).current.scrollLeft = (e.target as HTMLDivElement).scrollLeft;
      }
    });
  };

  return (
    <Wrapper>
      {aggregatedReportBreakdownGroups?.map((group) => (
        <GroupAccordion
          onScroll={syncScroll}
          tablesMap={tablesMap}
          key={group.groupName}
          group={group}
        />
      ))}
    </Wrapper>
  );
};
