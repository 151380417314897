import { useIssuersPendingEvaluation } from '../../../hooks/useIssuersPendingEvaluation';
import { ReactComponent as PortfolioIcon } from '../../../assets/icons/sidebarMenuIcons/portfolio.svg';
import { ReactComponent as PortfolioActiveIcon } from '../../../assets/icons/sidebarMenuIcons/portfolio-active.svg';
import { ReactComponent as RequestsIcon } from '../../../assets/icons/sidebarMenuIcons/requests.svg';
import { ReactComponent as RequestsActiveIcon } from '../../../assets/icons/sidebarMenuIcons/requests-active.svg';
import { ReactComponent as SetupIcon } from '../../../assets/icons/sidebarMenuIcons/setup.svg';
import { ReactComponent as SetupActiveIcon } from '../../../assets/icons/sidebarMenuIcons/setup-active.svg';
import { ReactComponent as IssuersIcon } from '../../../assets/icons/sidebarMenuIcons/issuers.svg';
import { ReactComponent as IssuersActiveIcon } from '../../../assets/icons/sidebarMenuIcons/issuers-active.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/icons/sidebarMenuIcons/reports.svg';
import { ReactComponent as ReportsActiveIcon } from '../../../assets/icons/sidebarMenuIcons/reports-active.svg';
import { ROUTES } from '../../../constants/routes';
import { TMenuItem, USER_ROLE } from '../../../types';

export const useMenuItems = () => {
  const { companiesData } = useIssuersPendingEvaluation();

  const menuItems: TMenuItem[] = [
    {
      title: 'Issuers',
      icon: <IssuersIcon />,
      iconActive: <IssuersActiveIcon />,
      id: 'issuers-setup',
      showForRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.ANALYST, USER_ROLE.MANAGER],
      subItems: [
        {
          title: 'Evaluated Issuers',
          route: ROUTES.ISSUERS,
          id: ROUTES.ISSUERS,
          showForRoles: [
            USER_ROLE.ADMIN,
            USER_ROLE.COMPLIANCE,
            USER_ROLE.ANALYST,
            USER_ROLE.MANAGER,
          ],
        },
        {
          title: 'Pending Evaluation',
          route: ROUTES.PENDING_EVALUATION,
          id: ROUTES.PENDING_EVALUATION,
          showForRoles: [
            USER_ROLE.ADMIN,
            USER_ROLE.COMPLIANCE,
            USER_ROLE.ANALYST,
            USER_ROLE.MANAGER,
          ],
          badgeNum: companiesData?.length || 0,
        },
      ],
    },
    {
      title: 'Control Companies',
      icon: <PortfolioIcon />,
      iconActive: <PortfolioActiveIcon />,
      route: `${ROUTES.PORTFOLIO}/${ROUTES.REQUESTS}`,
      id: ROUTES.PORTFOLIO,
      showForRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.MANAGER],
    },
    {
      title: 'Reports',
      icon: <ReportsIcon />,
      iconActive: <ReportsActiveIcon />,
      route: `${ROUTES.AGGREGATED_REPORTS}`,
      id: ROUTES.AGGREGATED_REPORTS,
      showForRoles: [USER_ROLE.ADMIN],
    },
    {
      title: 'Requests',
      icon: <RequestsIcon />,
      iconActive: <RequestsActiveIcon />,
      route: ROUTES.REQUESTS,
      id: ROUTES.REQUESTS,
      showForRoles: [USER_ROLE.ADMIN],
    },
    // {
    //   title: 'Models & Simulations',
    //   icon: <ModelsIcon />,
    //   iconActive: <ModelsActiveIcon />,
    //   route: 'models',
    //   id: 'models',
    // },
    // {
    //   title: 'Reports',
    //   icon: <ReportsIcon />,
    //   iconActive: <ReportsActiveIcon />,
    //   route: ROUTES.REPORTS,
    //   id: ROUTES.REPORTS,
    // },
    // {
    //   title: 'Policies & Guidelines',
    //   icon: <PoliciesIcon />,
    //   iconActive: <PoliciesActiveIcon />,
    //   route: 'policies',
    //   id: 'policies',
    // },
    {
      title: 'Setup',
      icon: <SetupIcon />,
      iconActive: <SetupActiveIcon />,
      id: 'setup',
      showForRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.ANALYST, USER_ROLE.MANAGER],
      hasTopBorder: true,
      subItems: [
        {
          title: 'Companies',
          route: ROUTES.COMPANIES,
          id: ROUTES.COMPANIES,
          showForRoles: [
            USER_ROLE.ADMIN,
            USER_ROLE.COMPLIANCE,
            USER_ROLE.ANALYST,
            USER_ROLE.MANAGER,
          ],
        },
        {
          title: 'Surveys',
          route: ROUTES.SURVEYS,
          id: ROUTES.SURVEYS,
          showForRoles: [USER_ROLE.ADMIN],
        },
        {
          title: 'KPIs',
          route: ROUTES.CORE_KPIS,
          id: ROUTES.CORE_KPIS,
          showForRoles: [USER_ROLE.ADMIN],
        },
        {
          title: 'Checklist',
          route: ROUTES.CHECKLIST,
          id: ROUTES.CHECKLIST,
          showForRoles: [USER_ROLE.ADMIN],
        },
        {
          title: 'Exclusions',
          route: ROUTES.INVESTOR_EXCLUSIONS,
          id: ROUTES.INVESTOR_EXCLUSIONS,
          showForRoles: [USER_ROLE.ADMIN, USER_ROLE.COMPLIANCE, USER_ROLE.MANAGER],
        },
        {
          title: 'Workflows',
          route: `${ROUTES.WORKFLOWS}/${ROUTES.CONTROL_WORKFLOW}`,
          id: ROUTES.WORKFLOWS,
          showForRoles: [USER_ROLE.ADMIN],
        },
        {
          title: 'User Management',
          route: ROUTES.USER_MANAGEMENT,
          id: ROUTES.USER_MANAGEMENT,
          showForRoles: [USER_ROLE.ADMIN],
        },
        // {
        //   title: 'Workflows',
        //   route: 'workflows',
        //   id: 'workflows',
        // },
        // {
        //   title: 'Recommendations',
        //   route: 'recommendations',
        //   id: 'recommendations',
        // },
      ],
    },
  ];

  return menuItems;
};
