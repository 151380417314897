import { Button } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  exportColumnsState,
  exportCompaniesState,
  exportTypeState,
  userState,
} from '../../../state/UIState';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { ROUTES } from '../../../constants/routes';
import { useResetExportCompaniesState } from '../hooks/useResetExportCompaniesState';
import { useExternalInvestors } from '../../../queries/useInvestors';
import {
  CompanyExclusion,
  EXPORT_COMPANIES_TYPE,
  ExportCompanyColumn,
  ExportCompanyExclusionsColumn,
  Report,
} from '../../../types';
import { getCompanyAssessmentReports } from '../../../utils/getCompanyAssessmentReports';
import { getNumberOfFlaggedAnswers } from '../../../utils/getNumberOfFlaggedAnswers';
import { useAllSteps } from '../../../hooks/useAllSteps';
import { getGovernanceScoreLabel, showGovernanceScore } from '../../../utils/governanceScoreUtils';
import ExcelJS, { Alignment } from 'exceljs';
import FileSaver from 'file-saver';
import { getFormattedCompanyExclusions } from '../../../utils/getFormattedCompanyExclusions';
import { getExclusionsData } from './getExclusionsData';

type CompanyDetailsData = {
  detailLabel: string;
} & Record<string, string>;

type ReportFlagsData = {
  detailLabel: string;
  companyId: string;
  flags: string;
};

const getAssessmentFlags = (assessmentReport?: Report) => {
  if (!assessmentReport) return 0;
  return getNumberOfFlaggedAnswers(assessmentReport) || 0;
};

export const ControlButtons = () => {
  const params = useParams();
  const activeStep = Number(params.activeStep);
  const [selectedColumns] = useAtom(exportColumnsState);
  const [selectedCompanies] = useAtom(exportCompaniesState);
  const navigate = useNavigate();
  const [exportType] = useAtom(exportTypeState);
  const user = useAtomValue(userState);

  const { navigateAndPreserveParams } = useQueryParams();

  const { data: investors } = useExternalInvestors();

  const { allUniqueAssessmentSteps } = useAllSteps();

  const resetState = useResetExportCompaniesState();

  const onNext = useCallback(async () => {
    navigateAndPreserveParams(`/${ROUTES.EXPORT_COMPANIES}/${2}`);
  }, [navigateAndPreserveParams]);

  const formattedDetails: CompanyDetailsData[] = useMemo(() => {
    const selectedRows = selectedColumns as ExportCompanyColumn[];

    const reportFlags: ReportFlagsData[][] = [];

    const details = selectedRows?.map((row) => {
      let detail: CompanyDetailsData | null = {
        detailLabel: row.column,
      };

      selectedCompanies?.forEach((company) => {
        if (!company) return;
        let value = company[row.value];

        if (row?.value === 'sectors') {
          value = company[row?.value]?.[0]?.name;
        }

        if (row?.value === 'industry') {
          value = company[row?.value]?.name;
        }

        if (row?.value === 'externalEsgContact') {
          value = company[row?.value]?.email;
        }

        if (row?.value === 'governanceScore') {
          const govScore = company[row?.value];
          value = showGovernanceScore(govScore) ? getGovernanceScoreLabel(govScore) : '';
        }

        if (row.value === 'reports') {
          const assessmentReports = getCompanyAssessmentReports(company, allUniqueAssessmentSteps);

          const companyReportFlags = assessmentReports?.map((report) => {
            return {
              detailLabel: report?.survey?.name,
              companyId: String(company?.id),
              flags: `${getAssessmentFlags(report)} flags`,
            };
          });

          reportFlags.push(companyReportFlags);

          detail = null;
          return;
        }
        detail = {
          ...detail,
          [company.id]: value || '',
        } as CompanyDetailsData;
      });
      return detail;
    });

    const reportsFlat = reportFlags?.flatMap((r) => r);

    const uniqueReports = [...new Set(reportsFlat?.map((r) => r.detailLabel))];

    const reportDetails: CompanyDetailsData[] = uniqueReports?.map((report) => {
      let rD = {
        detailLabel: report,
      };

      reportsFlat.forEach((r) => {
        if (r.detailLabel === report) {
          rD = {
            ...rD,
            [r.companyId]: r.flags,
          };
        }
      });

      return rD;
    });

    return [...details, ...reportDetails]?.filter((d) => Boolean(d));
  }, [allUniqueAssessmentSteps, selectedColumns, selectedCompanies]);

  const onExportCompanyDetails = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet('Companies');

    const companyCols = selectedCompanies?.map((company) => {
      return {
        header: company.name,
        key: String(company.id),
        width: 20,
        style: {
          alignment: {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left',
          } as Alignment,
        },
      };
    });

    worksheet.columns = [{ header: '', key: 'detailLabel', width: 40 }, ...(companyCols || [])];

    formattedDetails?.forEach((formattedDetail) => {
      worksheet.addRow(formattedDetail);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `Companies.xlsx`);
  }, [formattedDetails, selectedCompanies, user?.fullName]);

  const formattedExclusions: (CompanyExclusion & { name: string })[] = useMemo(() => {
    if (!selectedCompanies?.length) return [];

    return selectedCompanies
      ?.map((comp) => {
        const sortedExclusions = comp.exclusions?.sort((a, b) =>
          a?.condition?.category?.name?.localeCompare(b?.condition?.category?.name)
        );
        const compExclusions = getFormattedCompanyExclusions(sortedExclusions, comp, investors);
        return getExclusionsData(
          comp.name,
          compExclusions,
          selectedColumns as ExportCompanyExclusionsColumn[]
        );
      })
      ?.flatMap((data) => data);
  }, [investors, selectedColumns, selectedCompanies]);

  const isExportDisabled = useMemo(
    () => !selectedCompanies?.length || !selectedColumns?.length,
    [selectedColumns?.length, selectedCompanies?.length]
  );

  const onExportExclusions = useCallback(async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const worksheet = workbook.addWorksheet('Exclusions');

    const exclusionCols = selectedColumns?.map((col) => {
      let width = 20;
      if (col.value === 'condition' || col.value === 'text') {
        width = 40;
      }

      return {
        header: col.column,
        key: col.value,
        width,
        style: {
          alignment: {
            wrapText: true,
            vertical: 'top',
            horizontal: 'left',
          } as Alignment,
        },
      };
    });

    worksheet.columns = [
      { header: 'Investment Name', key: 'name', width: 40 },
      ...(exclusionCols || []),
    ];

    formattedExclusions?.forEach((formattedExclusion) => {
      worksheet.addRow(formattedExclusion);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `Exclusions.xlsx`);
  }, [formattedExclusions, selectedColumns, user?.fullName]);

  const onCancel = useCallback(() => {
    resetState();
    navigate(`/${ROUTES.COMPANIES}`);
  }, [navigate, resetState]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (exportType === EXPORT_COMPANIES_TYPE.companyDetails) {
        onExportCompanyDetails();
      }

      if (exportType === EXPORT_COMPANIES_TYPE.companyExclusions) {
        onExportExclusions();
      }

      resetState();
      navigate(`/${ROUTES.COMPANIES}`);
    },
    [exportType, navigate, onExportCompanyDetails, onExportExclusions, resetState]
  );

  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onCancel}>
        Cancel
      </Button>
      {activeStep === 1 && (
        <Button
          variant='contained'
          style={{ width: '95px' }}
          disabled={!Object.keys(selectedColumns)?.length}
          onClick={onNext}
        >
          Next
        </Button>
      )}
      {activeStep === 2 && (
        <>
          <Button
            variant='contained'
            style={{ width: '95px' }}
            disabled={isExportDisabled}
            onClick={onClick}
          >
            Export
          </Button>
        </>
      )}
    </>
  );
};
