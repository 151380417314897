import { useParams } from 'react-router';
import { Task } from '../TaskGroup';
import { useExclusionDueDiligenceReport } from '../../../hooks/useExclusionDueDiligenceReport';
import { QuestionOption, REPORT_STATUS } from '../../../types';
import { useOnReportClick } from '../../../hooks/useOnReportClick';
import { useMemo } from 'react';
import { useInvestorExclusionScreeningReport } from '../../../hooks/useInvestorExclusionScreeningReport';
import { TaskReportIndicator } from '../TaskGroup/TaskReportIndicator';
import { useInvestorDueDiligenceExclusionClick } from '../../../pages/SIngleCompany/hooks/useInvestorDueDiligenceExclusionsClick';
import { useExclusionScreeningSurvey } from '../../../hooks/useExclusionScreeningSurvey';
import { useReportStatusIndicator } from '../../../pages/SIngleCompany/hooks/useReportStatusIndicator';

interface Props {
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasEvaluationAccess?: boolean;
  isStepCompleted?: boolean;
}

export const DueDiligenceExclusionStep = ({
  hasAccess,
  hasEvaluationAccess,
  isStepCompleted,
}: Props) => {
  const params = useParams();

  const investorExclusionDueDiligenceReport = useExclusionDueDiligenceReport(Number(params.id));
  const { onReportClick } = useOnReportClick();
  const investorExclusionScreeningReport = useInvestorExclusionScreeningReport(Number(params.id));
  const { onInvestorExclusionsDueDiligenceClickHandler } = useInvestorDueDiligenceExclusionClick();
  const exclusionScreeningSurvey = useExclusionScreeningSurvey();

  const reportStatus = useReportStatusIndicator(investorExclusionDueDiligenceReport);

  const isExclusionsScreeningAvailable = useMemo(
    () =>
      investorExclusionScreeningReport?.status === REPORT_STATUS.IN_REVIEW ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.APPROVED ||
      investorExclusionScreeningReport?.status === REPORT_STATUS.SUBMITTED,
    [investorExclusionScreeningReport?.status]
  );

  const hasFlaggedConditions = useMemo(() => {
    if (!investorExclusionScreeningReport?.response) return false;
    const responses = Object.values(investorExclusionScreeningReport?.response);
    return responses.some((response) => (response as QuestionOption).name === 'Yes');
  }, [investorExclusionScreeningReport?.response]);

  const isInitialAccess = useMemo(() => {
    return (
      !investorExclusionDueDiligenceReport ||
      investorExclusionDueDiligenceReport?.status === REPORT_STATUS.NOT_STARTED ||
      investorExclusionDueDiligenceReport?.status === REPORT_STATUS.IN_PROGRESS ||
      investorExclusionDueDiligenceReport?.status === REPORT_STATUS.REJECTED
    );
  }, [investorExclusionDueDiligenceReport]);

  const hasInitialAccess = hasAccess && isInitialAccess;

  const isEvaluationAccess = useMemo(
    () => investorExclusionDueDiligenceReport?.status === REPORT_STATUS.IN_REVIEW,
    [investorExclusionDueDiligenceReport?.status]
  );

  const hasEvalAccess = hasEvaluationAccess && isEvaluationAccess;

  const isDisabled =
    (!isExclusionsScreeningAvailable && hasInitialAccess) ||
    (!hasInitialAccess && !hasEvalAccess && !isStepCompleted);

  return (
    <Task
      taskName={`Investor Exclusions Due Diligence`}
      isHighlighted={
        (hasInitialAccess || hasEvalAccess) &&
        reportStatus !== REPORT_STATUS.APPROVED &&
        reportStatus !== REPORT_STATUS.SUBMITTED
      }
      onTaskNameClick={
        investorExclusionDueDiligenceReport && reportStatus !== REPORT_STATUS.NOT_STARTED
          ? () => onReportClick(investorExclusionDueDiligenceReport)
          : undefined
      }
      disabled={!hasFlaggedConditions || isDisabled}
      infoText={
        (investorExclusionScreeningReport?.status === REPORT_STATUS.IN_REVIEW ||
          investorExclusionScreeningReport?.status === REPORT_STATUS.APPROVED ||
          investorExclusionScreeningReport?.status === REPORT_STATUS.SUBMITTED) &&
        !hasFlaggedConditions
          ? 'No flagged conditions in Investor Screening assessment.'
          : isExclusionsScreeningAvailable
          ? ''
          : 'Submit “Investor Exclusions: Screening” first.'
      }
    >
      <TaskReportIndicator
        onButtonClick={() => {
          if (investorExclusionDueDiligenceReport && reportStatus === REPORT_STATUS.NOT_STARTED) {
            onReportClick(investorExclusionDueDiligenceReport);
            return;
          }
          onInvestorExclusionsDueDiligenceClickHandler(
            investorExclusionScreeningReport,
            exclusionScreeningSurvey
          );
        }}
        report={investorExclusionDueDiligenceReport}
        buttonText='Proceed to Assessment'
        disabled={!hasFlaggedConditions || isDisabled}
      />
    </Task>
  );
};
