import { activeSurveyState, responsesState } from '../../../../state/UIState';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { QUESTION_TYPE, QuestionTableData } from '../../../../types';
import {
  HEADER_COLUMN_ID,
  KPI_EXCEL_NOTES_COLUMN_ID,
  KPI_DEFINITION_COLUMN_ID,
  KPI_FORMAT_COLUMN_ID,
  KPI_METRIC_COLUMN_ID,
  KPI_VALUE_COLUMN_ID,
} from '../../../../constants/defaultValues';
import { UploadExcel } from './UploadExcel';
import { useToastMessage } from '../../../../hooks/useToastMessage';

export const CoreKpisSurveyUpoadArea = () => {
  const [activeSurvey] = useAtom(activeSurveyState);
  const [, setResponses] = useAtom(responsesState);

  const { pushSuccessToast } = useToastMessage();

  const tableData: QuestionTableData = useMemo(() => {
    const rows: (Record<string, string> & { id: string })[] = [];
    const categoryColumnId = 'category';
    const categoryIdColumnId = 'categoryId';
    const kpiIdColumnId = 'kpiId';
    const columns: string[] = [
      HEADER_COLUMN_ID,
      kpiIdColumnId,
      categoryColumnId,
      categoryIdColumnId,
      KPI_DEFINITION_COLUMN_ID,
      KPI_METRIC_COLUMN_ID,
      KPI_FORMAT_COLUMN_ID,
      KPI_VALUE_COLUMN_ID,
      KPI_EXCEL_NOTES_COLUMN_ID,
    ];
    if (!activeSurvey) return { columns, rows };
    const { sections } = activeSurvey;
    sections.forEach((section, i) => {
      const [question] = section.questions;
      if (question.type !== QUESTION_TYPE.TABLE) return;
      const { rows: questionRows } = question.tableData;
      const [firstRow, ...rest] = questionRows;
      if (i === 0)
        rows.push({
          [categoryIdColumnId]: 'Category ID',
          [kpiIdColumnId]: 'KPI ID',
          [categoryColumnId]: 'Category',
          ...firstRow,
          [KPI_EXCEL_NOTES_COLUMN_ID]: 'Notes',
        });
      const updatedRest = rest.map((row) => ({
        ...row,
        [categoryIdColumnId]: section.id,
        [kpiIdColumnId]: row.id,
        [categoryColumnId]: section.name,
      }));
      rows.push(...updatedRest);
    });
    return { columns, rows };
  }, [activeSurvey]);

  const onSynchronize = (uploadedRows: any[][]) => {
    const [, ...restRows] = uploadedRows;
    setResponses((responses) => {
      restRows.forEach((row) => {
        const [, kpiId, , questionIdStr, , , , rawValue, rawNotes] = row;
        const questionId = parseInt(questionIdStr);
        responses[questionId] = {
          ...(responses[questionId] as QuestionTableData),
          rows: (responses[questionId] as QuestionTableData)?.rows?.map((r) => {
            if (Number(r?.id) !== Number(kpiId)) return r;
            return {
              ...r,
              [KPI_VALUE_COLUMN_ID]: {
                value: rawValue || '',
                notes: rawNotes || '',
              },
            };
          }),
        };
      });
      //return deep copy of responses
      return JSON.parse(JSON.stringify(responses));
    });
    pushSuccessToast({
      message: `Core KPI's are successfully synchronized`,
    });
  };

  return <UploadExcel tableData={tableData} onSynchronize={onSynchronize} />;
};
