import { AGGREGATED_REPORT_ISSUERS_FILTER, Company, GOVERNANCE_SCORE_TYPE } from '../../../types';
import { filterByGovernanceScoreType } from '../../../utils/filterByGovernanceScoreType';

export const filterCompany = (
  company: Company,
  issuerFilters?: Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[] | string> | null
) => {
  const riskFilters = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.RISK_LEVEL];
  const opportunitiesFilters = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.OPPORTUNITIES];

  const typeFilter = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.TYPE];
  const activePositionFilter = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.ACTIVE_POSITION];

  const sectorsFilters = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.SECTOR] as string[];

  const countriesFilters = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.GEOGRAPHY] as string[];

  const companiesFilter = issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES] as string[];

  const filteredByRisks = riskFilters?.length ? riskFilters?.includes(company?.esgRiskLevel) : true;
  const filteredByOpportunities = opportunitiesFilters?.length
    ? opportunitiesFilters?.includes(company?.esgOpportunitiesLevel)
    : true;

  const filteredByGovScore = filterByGovernanceScoreType(
    company?.governanceScore as number,
    issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.GOVERNANCE_SCORE_TYPE] as GOVERNANCE_SCORE_TYPE
  );

  const filteredByType = typeFilter ? typeFilter === company?.type : true;
  const filterByActivePosition = activePositionFilter
    ? activePositionFilter === company?.isActivePosition
    : true;

  const companySectors = company?.sectors?.map((s) => s.name);

  const filterBySectors = sectorsFilters?.length
    ? sectorsFilters?.some((sector) => companySectors?.includes(sector))
    : true;

  const filterByCountries = countriesFilters?.length
    ? countriesFilters?.includes(company?.country)
    : true;

  const filterBySelectedCompanies = companiesFilter?.length
    ? companiesFilter?.includes(company?.name)
    : true;

  return (
    filteredByRisks &&
    filteredByOpportunities &&
    filteredByGovScore &&
    filteredByType &&
    filterByActivePosition &&
    filterBySectors &&
    filterByCountries &&
    filterBySelectedCompanies
  );
};
