import { useMemo } from 'react';
import { useWidgetQuestion } from './useWidgetQuestion';
import {
  AggregatedReportWidget,
  QUESTION_TYPE,
  Response,
  WidgetCompanyAnswer,
} from '../../../../../../types';
import { useBreakdownData } from '../../../../hooks/useBreakdownData';
import { NO_ANSWER_OPTION } from '../constants';

export const useCompaniesAnswers = (widget: AggregatedReportWidget) => {
  const widgetQuestion = useWidgetQuestion(widget);
  const { filteredCompanies } = useBreakdownData();

  const companiesAnswers: WidgetCompanyAnswer[] = useMemo(() => {
    if (!widgetQuestion) return [];

    return filteredCompanies?.map((company) => {
      const allAnswers = company?.reports?.map((report) => report?.response);
      const allAnswersMap: Record<string, Response> = allAnswers?.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr,
        };
      }, {});

      return {
        companyName: company.name,
        companyLogo: company.logoUrl,
        answer: allAnswersMap[widgetQuestion?.id],
      } as WidgetCompanyAnswer;
    });

    return [];
  }, [filteredCompanies, widgetQuestion]);

  const numOfCompaniesAnswers = useMemo(() => {
    return companiesAnswers?.filter((companyAnswer) => Boolean(companyAnswer?.answer))?.length;
  }, [companiesAnswers]);

  const completeCompanyAnswers = useMemo(() => {
    return (
      companiesAnswers?.map((companyAnswer) => {
        if (!companyAnswer?.answer)
          return {
            ...companyAnswer,
            answer:
              widgetQuestion?.type === QUESTION_TYPE.SINGLE ? NO_ANSWER_OPTION : [NO_ANSWER_OPTION],
          };

        return companyAnswer;
      }) || []
    );
  }, [companiesAnswers, widgetQuestion?.type]);

  return { companiesAnswers: completeCompanyAnswers, numOfCompaniesAnswers };
};
