import { useTheme } from '@mui/material';

export const useCustomChartColors = () => {
  const { colors } = useTheme();
  const customColors = [
    colors.accent[50],
    colors.primary[70],
    colors.primary[60],
    colors.primary[50],
    colors.primary[40],
    colors.primary[30],
  ];

  const getCustomChartColor = (index: number) => {
    return customColors?.[index] || undefined;
  };

  return getCustomChartColor;
};
