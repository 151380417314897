import { Button } from '@mui/material';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router';
import {
  excelCompaniesState,
  rowSelectionUploadCompaniesState,
  selectedCompaniesToUploadState,
  uploadedFileState,
} from '../../../state/UIState';
import { CompanyBulkPayload, useCreateCompanies } from '../../../queries/useCompanies';
import { useCallback, useMemo } from 'react';
import { ROUTES } from '../../../constants/routes';

export const ControlButtons = () => {
  const navigate = useNavigate();
  const [, setFile] = useAtom(uploadedFileState);
  const [excelData, setExcelData] = useAtom(excelCompaniesState);
  const [, setRowSelection] = useAtom(rowSelectionUploadCompaniesState);
  const params = useParams();
  const [companies] = useAtom(selectedCompaniesToUploadState);
  const createCompanies = useCreateCompanies();

  const activeStep = Number(params.activeStep);

  const isNextDisabled = useMemo(() => {
    if (activeStep === 1) {
      return !excelData || !excelData.length;
    }
    return !companies || !companies.length;
  }, [excelData, activeStep, companies]);

  const resetState = useCallback(() => {
    setFile(null);
    setExcelData([]);
    setRowSelection({});
  }, [setExcelData, setFile, setRowSelection]);

  const onCancel = useCallback(() => {
    navigate(`/${ROUTES.COMPANIES}`);
    resetState();
  }, [navigate, resetState]);

  const onNext = useCallback(() => {
    if (activeStep === 1) {
      navigate(`${2}`);
      return;
    }
    if (activeStep === 2) {
      navigate(`/${ROUTES.COMPANIES}`);
      resetState();
      const mappedCompanies = companies.map((company) => ({
        ...company,
        type: company['type (not controlled/controlled)'],
        analyst: company.analyst || null,
      }));
      createCompanies.mutate({ data: [...mappedCompanies] as unknown as CompanyBulkPayload[] });
      return;
    }
  }, [activeStep, companies, createCompanies, navigate, resetState]);
  return (
    <>
      <Button variant='outlined' style={{ width: '95px' }} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant='contained'
        style={{ width: '95px' }}
        disabled={isNextDisabled}
        onClick={onNext}
      >
        {activeStep === 1 ? 'Next' : 'Upload'}
      </Button>
    </>
  );
};
