import { Multiselect } from '../../../../../components/Multiselect/Multiselect';
import { AGGREGATED_REPORT_GROUP, SelectItem } from '../../../../../types';
import { useMemo } from 'react';
import { Button, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../state/UIState';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled('div')<{ isDisabled?: boolean }>`
  flex: 1;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
`;

const groupByOptions: SelectItem[] = [
  {
    id: 0,
    value: AGGREGATED_REPORT_GROUP.SECTOR,
  },
  {
    id: 1,
    value: AGGREGATED_REPORT_GROUP.GEOGRAPHY,
  },
];

export const GroupsSelect = () => {
  const { colors } = useTheme();
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const selectedGroupOptions = useMemo(() => {
    const groupByValues = aggregatedReport?.groupedBy?.map((group) => group.name);
    return groupByOptions?.filter((option) =>
      groupByValues?.includes(option.value as AGGREGATED_REPORT_GROUP)
    );
  }, [aggregatedReport?.groupedBy]);

  const onGroupByChange = (val: SelectItem[]) => {
    if (!aggregatedReport) return;
    const groups = val?.map((groupVal, i) => ({
      id: groupVal.value as AGGREGATED_REPORT_GROUP,
      name: groupVal.value as AGGREGATED_REPORT_GROUP,
      order: i,
    }));

    setAggregatedReport({ ...aggregatedReport, groupedBy: groups });
  };

  const onClearAll = () => {
    if (!aggregatedReport) return;

    setAggregatedReport({ ...aggregatedReport, groupedBy: [] });
  };

  const isSelectDisabled = aggregatedReport?.groupedBy?.length === 2;

  return (
    <Wrapper>
      <SelectWrapper isDisabled={isSelectDisabled}>
        <Multiselect
          style={{ width: '100%' }}
          options={groupByOptions}
          value={selectedGroupOptions}
          onChange={(_, val) => {
            onGroupByChange(val as SelectItem[]);
          }}
          disablePortal
          optionsName='Groups'
          fieldPlaceholder='Group By'
          isDisabled={isSelectDisabled}
        />
      </SelectWrapper>
      <Button variant='text' onClick={onClearAll}>
        <Typography variant='subtitle2' color={colors.accent[50]}>
          Clear all
        </Typography>
      </Button>
    </Wrapper>
  );
};
