import {
  AGGREGATED_REPORT_GROUP,
  AggregatedReportBreakdownGroup,
  Company,
  SurveyQuestion,
  TAggregatedReportGroup,
} from '../../../types';
import { getBreakDownTableData } from './getBreakDownTableData';

type TGroup = {
  groupCategory: AGGREGATED_REPORT_GROUP;
  groupName: string;
  groupedCompanies: Company[];
};

const getGroups = (companies: Company[], group: TAggregatedReportGroup): TGroup[] => {
  if (group.id === AGGREGATED_REPORT_GROUP.SECTOR) {
    const uniqueSectorGroups = [
      ...new Set(companies?.map((company) => company.sectors?.[0]?.name)),
    ]?.filter((s) => s);

    return uniqueSectorGroups?.map((sectorGroup) => {
      const groupedCompanies = companies?.filter((company) => {
        return company.sectors?.some((sector) => sector.name === sectorGroup);
      });
      return {
        groupCategory: group.id,
        groupName: sectorGroup,
        groupedCompanies,
      };
    });
  }

  if (group.id === AGGREGATED_REPORT_GROUP.GEOGRAPHY) {
    const uniqueCountriesGroups = [...new Set(companies.map((c) => c.country))]?.filter((c) => c);

    return uniqueCountriesGroups?.map((countriesGroup) => {
      const groupedCompanies = companies?.filter((company) => {
        return company.country === countriesGroup;
      });
      return {
        groupCategory: group.id,
        groupName: countriesGroup,
        groupedCompanies,
      };
    });
  }
  return [];
};

export const getGroupsData = ({
  companies,
  groupedBy,
  selectedQuestions,
}: {
  companies: Company[];
  groupedBy: TAggregatedReportGroup[];
  selectedQuestions: (SurveyQuestion | undefined)[];
}): AggregatedReportBreakdownGroup[] => {
  const orderedGroupedBy = groupedBy.sort((a, b) => a.order - b.order);
  const mainGroup = orderedGroupedBy[0];
  const subGroup = orderedGroupedBy[1];
  const main = getGroups(companies, mainGroup);

  if (!subGroup) {
    return main.map((group) => {
      return {
        ...group,
        tableData: getBreakDownTableData({
          filteredCompanies: group.groupedCompanies,
          selectedQuestions,
        }),
      };
    });
  }

  return main?.map((group) => {
    return {
      ...group,
      subgroups: getGroups(group.groupedCompanies, subGroup)?.map((subGroup) => {
        return {
          ...subGroup,
          tableData: getBreakDownTableData({
            filteredCompanies: subGroup.groupedCompanies,
            selectedQuestions,
          }),
        };
      }),
    };
  });
};
