import { useMemo } from 'react';
import {
  AggregatedReportBreakdownGroup,
  SurveyQuestion,
  TAggregatedReportData,
  TReportSurveyData,
} from '../../../types';
import { getAggregatedReportSurveysMap } from '../../../utils/getAggregatedReportSurveysMap';
import { filterCompany } from '../utils/filterCompany';
import { getBreakDownTableData } from '../utils/getBreakDownTableData';
import { getGroupsData } from '../utils/getGroupsData';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../state/UIState';

export const useBreakdownData = () => {
  const [aggregatedReport] = useAtom(aggregatedReportState);

  const selectedSurveys = useMemo(() => {
    return aggregatedReport?.surveys;
  }, [aggregatedReport?.surveys]);

  const allSurveysMap: Record<string, TReportSurveyData> = useMemo(() => {
    if (!selectedSurveys?.length) return {};
    return getAggregatedReportSurveysMap(selectedSurveys);
  }, [selectedSurveys]);

  const allSurveysQuestions: SurveyQuestion[] = useMemo(() => {
    return allSurveysMap ? Object.values(allSurveysMap).flatMap((q) => q.questions) : [];
  }, [allSurveysMap]);

  const selectedQuestions = useMemo(() => {
    return (
      aggregatedReport?.selectedQuestions
        ?.map((qId) => allSurveysQuestions?.find((q) => q.id === qId))
        ?.filter((q) => q) || []
    );
  }, [aggregatedReport?.selectedQuestions, allSurveysQuestions]);

  const filteredCompanies = useMemo(() => {
    if (!aggregatedReport?.companies?.length) return [];
    return aggregatedReport?.companies?.filter((company) => {
      return filterCompany(company, aggregatedReport?.issuerFilters);
    });
  }, [aggregatedReport?.companies, aggregatedReport?.issuerFilters]);

  const breakdownTableData: TAggregatedReportData[] = useMemo(() => {
    if (!filteredCompanies?.length) return [];
    return getBreakDownTableData({ filteredCompanies, selectedQuestions });
  }, [filteredCompanies, selectedQuestions]);

  const groupedBy = useMemo(() => {
    return aggregatedReport?.groupedBy;
  }, [aggregatedReport?.groupedBy]);

  const aggregatedReportBreakdownGroups: AggregatedReportBreakdownGroup[] = useMemo(() => {
    if (!groupedBy?.length) return [];

    return getGroupsData({
      companies: filteredCompanies,
      groupedBy,
      selectedQuestions,
    });
  }, [filteredCompanies, groupedBy, selectedQuestions]);

  return {
    breakdownTableData,
    allSurveysQuestions,
    allSurveysMap,
    selectedQuestions,
    filteredCompanies,
    groupedBy,
    aggregatedReportBreakdownGroups,
  };
};
