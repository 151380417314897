import { createColumnHelper, DisplayColumnDef } from '@tanstack/react-table';
import { TAggregatedReportData } from '../../../../../types';
import { CompanyCell } from '../../../../../components/Table/CellRenderers/CompanyCell';
import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../../components/Typography/Typography';
import { useBreakdownData } from '../../../hooks/useBreakdownData';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';

const columnHelper = createColumnHelper<TAggregatedReportData>();

const ColName = styled('div')`
  p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const useBreakdownTableColumns = () => {
  const { colors } = useTheme();
  const { selectedQuestions } = useBreakdownData();

  const nameCol = columnHelper.accessor('companyName', {
    cell: (info) => (
      <CompanyCell
        name={info.getValue()}
        logo={info.row.original.logoUrl}
        labelColor={colors.accent[50]}
      />
    ),
    header: () => (
      <Typography variant='overline' color={colors.primary[70]}>
        Issuer
      </Typography>
    ),
    meta: {
      width: '240px',
      minWidth: '240px',
      tdStyles: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        paddingLeft: 16,
        height: '100%',
        backgroundColor: '#fff',
        borderRight: `1px solid ${colors.primary[30]}`,
      },
      thStyles: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        borderRight: `1px solid ${colors.primary[30]}`,
      },
    },
    enableColumnFilter: true,
  });

  const dynamicColumns: DisplayColumnDef<TAggregatedReportData>[] =
    selectedQuestions
      ?.map?.((question) => {
        return columnHelper.display({
          id: question?.id || '',
          cell: (info) => {
            const answer = info.row.original.assessmentQuestionsData?.find(
              (questionData) => questionData.questionId === question?.id
            )?.answer;
            return answer ? (
              <Typography variant='body' color={colors.primary[90]}>
                {answer}
              </Typography>
            ) : null;
          },
          header: () => (
            <ColName>
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [55, -13],
                      },
                    },
                  ],
                }}
                title={question?.name}
              >
                <Typography variant='overline' color={colors.primary[70]}>
                  {question?.name}
                </Typography>
              </Tooltip>
            </ColName>
          ),
          meta: {
            width: '200px',
            minWidth: '200px',
            tdStyles: {
              paddingLeft: 16,
            },
            thStyles: {
              paddingLeft: 16,
            },
          },
          enableSorting: false,
        });
      })
      ?.filter((col) => Boolean(col)) || [];

  return [nameCol, ...dynamicColumns];
};
