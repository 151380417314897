import {
  Company,
  QUESTION_TYPE,
  QuestionOption,
  Response,
  SurveyQuestion,
  TAggregatedReportData,
  TAssessmentQuestionsData,
} from '../../../types';

export const getBreakDownTableData = ({
  filteredCompanies,
  selectedQuestions,
}: {
  filteredCompanies: Company[];
  selectedQuestions: (SurveyQuestion | undefined)[];
}): TAggregatedReportData[] => {
  if (!filteredCompanies?.length) return [];
  return filteredCompanies?.map((company) => {
    const reports = company?.reports;

    const allResponses = reports?.map((report) => report?.response);
    const allResponsesMap: Record<string, Response> = allResponses?.reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {});

    const assessmentQuestionsData: TAssessmentQuestionsData[] = selectedQuestions
      ?.map((question) => {
        if (!question) return null;
        const answer = allResponsesMap[question.id];
        let textResponse = '';
        if (question.type === QUESTION_TYPE.MULTI) {
          textResponse = (answer as QuestionOption[])?.map((a) => a.name)?.join('; ') || '';
        }
        if (question.type === QUESTION_TYPE.SINGLE || question.type === QUESTION_TYPE.YES_NO) {
          textResponse = (answer as QuestionOption)?.name || '';
        }
        if (question.type === QUESTION_TYPE.TEXT) {
          textResponse = answer as string;
        }

        return {
          questionId: question.id,
          questionName: question.name,
          answer: textResponse || '',
        };
      })
      ?.filter((d) => d) as TAssessmentQuestionsData[];

    return {
      companyName: company?.name,
      logoUrl: company?.logoUrl,
      companyId: company?.id,
      assessmentQuestionsData,
    };
  });
};
