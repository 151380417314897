import { Report, SURVEY_STAGE } from '../../../types';
import { CoreKpiSingleAssessment } from './CoreKpiSingleAssessment/CoreKpiSingleAssessment';
import { QuestionsTable } from './QuestionsTable';

interface Props {
  report: Report;
}

export const SingleAssessmentContent = ({ report }: Props) => {
  if (report?.survey?.stage === SURVEY_STAGE.CORE_KPI)
    return <CoreKpiSingleAssessment survey={report?.survey} response={report?.response} />;

  return <QuestionsTable report={report} />;
};
