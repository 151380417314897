import { Multiselect } from '../../../../../../components/Multiselect/Multiselect';

import { useCallback, useMemo } from 'react';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

export const CompaniesFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const companiesOptions = useMemo(() => {
    if (!aggregatedReport?.companies?.length) return [];
    return aggregatedReport?.companies?.map((company) => ({
      id: company.id,
      value: company.name,
    }));
  }, [aggregatedReport?.companies]);

  const selectedCompanies = useMemo(() => {
    if (!aggregatedReport) return [];

    const selectedCompanies =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES];

    const hasSelectedCompanies =
      aggregatedReport?.issuerFilters?.[AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES]?.length;

    if (!hasSelectedCompanies) return [];

    return companiesOptions?.filter((r) => selectedCompanies?.includes(r?.value));
  }, [aggregatedReport, companiesOptions]);

  const onChangeSelectedCompanies = useCallback(
    (companies: SelectItem[]) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.COMPANIES]: companies?.length
            ? companies?.map((r) => r?.value)
            : [],
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[]>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <Multiselect
      style={{ width: '100%' }}
      options={companiesOptions}
      value={selectedCompanies}
      onChange={(_, val) => onChangeSelectedCompanies(val as SelectItem[])}
      disablePortal
      optionsName='Issuers'
      fieldPlaceholder='Issuers'
      selectedTextMaxWidth='9rem'
    />
  );
};
