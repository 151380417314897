import { styled, useTheme } from '@mui/material';
import { TAggregatedReportData } from '../../../../../../types';
import { BreakdownTable } from '../../BreakdownTable/BreakdownTable';
import { RefObject } from 'react';

const Container = styled('div')`
  border-top: ${({ theme }) => `1px solid ${theme.colors.primary[30]}`};
`;

interface Props {
  tableData: TAggregatedReportData[];
  tableRef?: RefObject<HTMLDivElement>;
  onHorizontalScroll?: React.UIEventHandler<HTMLDivElement>;
}
export const GroupedBreakdownTable = ({ tableData, tableRef, onHorizontalScroll }: Props) => {
  const { colors } = useTheme();
  return (
    <Container>
      <BreakdownTable
        tableData={tableData}
        height='auto'
        thStyle={{ backgroundColor: colors.accent[1] }}
        tableRef={tableRef}
        onHorizontalScroll={onHorizontalScroll}
      />
    </Container>
  );
};
