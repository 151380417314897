import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from '../../../../../components/Table/Table';
import { TAggregatedReportData } from '../../../../../types';
import { useBreakdownTableColumns } from './useBreakdownTableColumns';
import { RefObject } from 'react';

interface Props {
  tableData: TAggregatedReportData[];
  height?: string;
  thStyle?: React.CSSProperties;
  onHorizontalScroll?: React.UIEventHandler<HTMLDivElement>;
  tableRef?: RefObject<HTMLDivElement>;
}
export const BreakdownTable = ({ tableData, height, thStyle, onHorizontalScroll, tableRef }: Props) => {
  const columns = useBreakdownTableColumns();

  const table = useReactTable({
    data: tableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (report) => String(report.companyId),
  });
  return (
    <Table
      table={table}
      thStyle={thStyle}
      tdStyle={{ height: 'inherit' }}
      height={height || 'auto'}
      onHorizontalScroll={onHorizontalScroll}
      tableRef={tableRef}
    />
  );
};
