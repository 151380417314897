import { Button, useTheme } from '@mui/material';
import { Typography } from './Typography/Typography';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { useAtomValue } from 'jotai';
import { userState } from '../state/UIState';
import { useCallback } from 'react';
import { SingleAssessmentQuestionRow } from '../types';
import { useParams } from 'react-router';
import { useCompanyById } from '../queries/useCompanies';
import { useCompanyReports } from '../pages/SIngleCompany/hooks/useCompanyReports';
import { getGovernanceScoreLabel, showGovernanceScore } from '../utils/governanceScoreUtils';
import { getAssessmentExportQuestions } from '../utils/getAssessmentExportQuestions';
import { getHasOverrideColumn } from '../utils/getHasOverrideColumn';
import { getHasEvaluationColumn } from '../utils/getHasEvaluationColumn';
import { formatAssessmentWorksheet } from '../utils/formatAssessmentWorksheet';

enum OVERVIEW_KEYS {
  NAME = 'name',
  SASB_SECTOR_INDUSTRY = 'sectorIndustry',
  GEOPGRAPHY = 'geography',
  RISK = 'risk',
  OPPORTUNITY = 'opportunity',
  GOOD_GOVERNANCE = 'goodGovernance',
}

export const ExportSingleCompany = () => {
  const { colors } = useTheme();
  const user = useAtomValue(userState);

  const params = useParams();
  const { data: company } = useCompanyById(Number(params.id));
  const { assessmentReports } = useCompanyReports();

  const onExport = useCallback(async () => {
    if (!company) return;
    const workbook = new ExcelJS.Workbook();
    workbook.creator = user?.fullName || '';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const overviewCols = [
      { header: 'Name', key: OVERVIEW_KEYS.NAME, width: 30 },
      { header: 'SASB Sector Industry', key: OVERVIEW_KEYS.SASB_SECTOR_INDUSTRY, width: 20 },
      { header: 'Geography', key: OVERVIEW_KEYS.GEOPGRAPHY, width: 20 },
      { header: 'Risk', key: OVERVIEW_KEYS.RISK, width: 10 },
      { header: 'Opportunity', key: OVERVIEW_KEYS.OPPORTUNITY, width: 10 },
      { header: 'Good Governance', key: OVERVIEW_KEYS.GOOD_GOVERNANCE, width: 20 },
    ];

    const overviewWorksheet = workbook.addWorksheet('Overview');

    overviewWorksheet.columns = overviewCols;

    const overviewData = {
      [OVERVIEW_KEYS.NAME]: company?.name,
      [OVERVIEW_KEYS.SASB_SECTOR_INDUSTRY]: company?.sectors?.[0]?.name,
      [OVERVIEW_KEYS.GEOPGRAPHY]: company?.country,
      [OVERVIEW_KEYS.RISK]: company?.esgRiskLevel,
      [OVERVIEW_KEYS.OPPORTUNITY]: company?.esgOpportunitiesLevel,
      [OVERVIEW_KEYS.GOOD_GOVERNANCE]: showGovernanceScore(company?.governanceScore)
        ? getGovernanceScoreLabel(company?.governanceScore)
        : '',
    };

    overviewWorksheet.addRow(overviewData);

    assessmentReports?.forEach((report) => {
      const assessmentWorksheet = workbook.addWorksheet(report?.survey?.name);
      const exportData: SingleAssessmentQuestionRow[] = getAssessmentExportQuestions(report);

      const showOverrideColumn = getHasOverrideColumn(report);
      const hasEvaluation = getHasEvaluationColumn(report);

      formatAssessmentWorksheet({
        worksheet: assessmentWorksheet,
        showOverrideColumn,
        hasEvaluation,
        exportData,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${company?.name}.xlsx`);
  }, [assessmentReports, company, user?.fullName]);

  return (
    <Button variant={'outlined'} style={{ minWidth: 'auto' }} onClick={onExport}>
      <Typography variant='subtitle2' color={colors.accent[50]}>
        Export
      </Typography>
    </Button>
  );
};
