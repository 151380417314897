import {
  SortingState,
  Table as RTable,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { Company, COMPANY_TYPE, Report, Sector, SelectItem, USER_ROLE } from '../../types';
import { useAssessmentSummaryColumns } from './useAssessmentSummaryColumns';
import { Table } from '../Table/Table';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material';
import { SearchInput } from '../SearchInput/SearchInput';
import { Typography } from '../Typography/Typography';
import { getNumberOfFlaggedAnswers } from '../../utils/getNumberOfFlaggedAnswers';
import { getCompanyAssessmentReports } from '../../utils/getCompanyAssessmentReports';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  activeRoleState,
  filteredCompaniesIdsState,
  issuerActivePositionState,
  issuersAnalystsFilterState,
  issuersCountriesFilterState,
  issuersGovernanceScoreTypeState,
  issuersSectorsFilterState,
  issuersSelectedTypeFilterState,
  selectedOpportunitiesFilterState,
  selectedRisksFilterState,
} from '../../state/UIState';
import { ResetFiltersButton } from '../ResetFiltersButton/ResetFiltersButton';
import { useAllSteps } from '../../hooks/useAllSteps';
import { CompanyTypeFilter } from '../CompanyTypeFilter';
import { SectorsFilter } from '../SectorsFilter';
import { CountriesFilter } from '../CountriesFilter';
import { AnalystFilter } from '../AnalystFilter';
import { GovernanceScoreFilter } from '../GovernanceScoreFilter';
import { ActivePositionFilter } from './ActivePositionFilter';
import { OpportunitiesFilter } from '../OpportunitiesFilter';
import { RiskFilter } from '../RiskFilter';

const Container = styled('div')``;

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;
interface Props {
  companies: Company[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  prevRouteName: string;
  companiesType?: COMPANY_TYPE;
  wrapperHeight?: string;
}

export type TAssessmentReportFlagData = {
  surveyId: number;
  name: string;
  flagsNum: number;
};

export type PortfolioCompanyData = Company & {
  assessmentReportFlags: TAssessmentReportFlagData[];
};

const getUniqueValuesFromTableColumn = (
  table: RTable<PortfolioCompanyData>,
  columnName: string
) => {
  const allValues = Array.from(table.getColumn(columnName)?.getFacetedUniqueValues().keys() ?? []);

  const unique =
    allValues
      .filter((value) => Boolean(value))
      .sort()
      .map((value, id) => ({ id, value })) ?? [];

  return unique;
};

const getAssessmentFlags = (assessmentReport?: Report) => {
  if (!assessmentReport) return 0;
  return getNumberOfFlaggedAnswers(assessmentReport) || 0;
};

export const AssessmentSummaryTable = ({
  companies,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  prevRouteName,
  companiesType,
  wrapperHeight,
}: Props) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const [selectedRisks, setSelectedRisks] = useAtom(selectedRisksFilterState);
  const [selectedOpportunities, setSelectedOpportunities] = useAtom(
    selectedOpportunitiesFilterState
  );
  const [selectedType, setSelectedType] = useAtom(issuersSelectedTypeFilterState);
  const setFilteredCompaniesIds = useSetAtom(filteredCompaniesIdsState);
  const activeRole = useAtomValue(activeRoleState);
  const [selectedSectors, setSelectedSectors] = useAtom(issuersSectorsFilterState);
  const [selectedCountries, setSelectedCountries] = useAtom(issuersCountriesFilterState);
  const [selectedAnalysts, setSelectedAnalysts] = useAtom(issuersAnalystsFilterState);
  const [selectedGovernanceScoreType, setSelectedGovernanceScoreType] = useAtom(
    issuersGovernanceScoreTypeState
  );
  const [selectedIsActivePosition, setSelectedIsActivePosition] =
    useAtom(issuerActivePositionState);

  const { allUniqueAssessmentSteps: allAssessmentSteps } = useAllSteps(companiesType);

  const location = useLocation();

  const tableData: PortfolioCompanyData[] = useMemo(() => {
    const data = companies.map((company) => {
      const assessmentReports = getCompanyAssessmentReports(company, allAssessmentSteps);
      return {
        ...company,
        assessmentReportFlags: assessmentReports?.map((report) => ({
          surveyId: report.survey.id,
          name: report.survey.name,
          flagsNum: getAssessmentFlags(report),
        })),
      };
    });

    const filteredData = data?.filter((d) => {
      const selectedSectorsValues = selectedSectors?.map((selectedSector) => selectedSector.value);
      const selectedCountriesValues = selectedCountries?.map(
        (selectedCountry) => selectedCountry.value
      );

      if (!selectedSectorsValues?.length && !selectedCountriesValues?.length) return true;

      const hasSectors = d.sectors?.some((s) => selectedSectorsValues?.includes(s.name));
      const hasCountry = selectedCountriesValues?.includes(d.country);

      if (selectedSectorsValues?.length && !selectedCountriesValues?.length) return hasSectors;

      if (!selectedSectorsValues?.length && selectedCountriesValues?.length) return hasCountry;

      return hasSectors && hasCountry;
    });

    return filteredData;
  }, [allAssessmentSteps, companies, selectedCountries, selectedSectors]);

  const columns = useAssessmentSummaryColumns(companiesType);

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (company) => String(company.id),
  });

  const sortedUniqueSectors = useMemo(() => {
    const allValues: any[] = companies?.map((company) => company.sectors) || [];
    const map = allValues.reduce((acc, curr) => {
      curr.forEach((element: Sector) => {
        acc[element.name] = element.name;
      });
      return acc;
    }, {});
    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueCountries = useMemo(() => {
    const allValues: any[] = companies?.map((company) => company.country) || [];
    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr] = curr;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const sortedUniqueAnalysts = useMemo(() => {
    const allValues = Array.from(table.getColumn('analyst')?.getFacetedUniqueValues().keys() ?? []);
    const unique = allValues.sort().map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, companies]);

  const tableRows = table.getRowModel().rows;

  useEffect(() => {
    setFilteredCompaniesIds(tableRows.map((row) => Number(row.id)));
  }, [setFilteredCompaniesIds, tableRows]);

  const sortedUniqueRisks = useMemo(() => {
    return getUniqueValuesFromTableColumn(table, 'esgRiskLevel');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, tableData]);

  const sortedUniqueOpportunities = useMemo(() => {
    return getUniqueValuesFromTableColumn(table, 'esgOpportunitiesLevel');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, tableData]);

  useEffect(() => {
    if (columnFilters?.length) return;
    setSelectedOpportunities(sortedUniqueOpportunities);
    setSelectedRisks(sortedUniqueRisks);
  }, [
    columnFilters?.length,
    setSelectedOpportunities,
    setSelectedRisks,
    setSelectedSectors,
    sortedUniqueOpportunities,
    sortedUniqueRisks,
    sortedUniqueSectors,
  ]);

  const onChangeSelectedRisks = useCallback(
    (risks: SelectItem[]) => {
      setSelectedRisks(risks);
      table.getColumn('esgRiskLevel')?.setFilterValue(risks.map((r) => r.value));
    },
    [setSelectedRisks, table]
  );
  const onChangeSelectedOpportunities = useCallback(
    (opportunities: SelectItem[]) => {
      setSelectedOpportunities(opportunities);
      table.getColumn('esgOpportunitiesLevel')?.setFilterValue(opportunities.map((o) => o.value));
    },
    [setSelectedOpportunities, table]
  );

  const sortedUniqueTypes = useMemo(() => {
    const unique = getUniqueValuesFromTableColumn(table, 'type');
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, tableData]);

  const onChangeTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedType(type);
      table.getColumn('type')?.setFilterValue(type?.value ?? '');
    },
    [setSelectedType, table]
  );

  const onChangeGovernanceScoreTypeFilter = useCallback(
    (type: SelectItem | null) => {
      setSelectedGovernanceScoreType(type);
      table.getColumn('governanceScore')?.setFilterValue(type?.value ?? '');
    },
    [setSelectedGovernanceScoreType, table]
  );

  const onChangeSectorsFilter = useCallback(
    (sectors: SelectItem[]) => {
      setSelectedSectors(sectors);
    },
    [setSelectedSectors]
  );

  const onChangeCountriesFilter = useCallback(
    (countries: SelectItem[]) => {
      setSelectedCountries(countries);
    },
    [setSelectedCountries]
  );

  const onChangeAnalystsFilter = useCallback(
    (analysts: SelectItem[]) => {
      setSelectedAnalysts(analysts);
      table.getColumn('analyst')?.setFilterValue(analysts.map((r) => r.value));
    },
    [setSelectedAnalysts, table]
  );

  const onChangeActivePositionFilter = useCallback(
    (activePosition: SelectItem | null) => {
      if (activePosition === selectedIsActivePosition) {
        setSelectedIsActivePosition(null);
        table.getColumn('isActivePosition')?.setFilterValue('');
        return;
      }
      setSelectedIsActivePosition(activePosition);
      table.getColumn('isActivePosition')?.setFilterValue(activePosition?.value ?? '');
    },
    [selectedIsActivePosition, setSelectedIsActivePosition, table]
  );

  const onRowClick = useCallback(
    (row: Row<PortfolioCompanyData>) => {
      const companyId = row.id;
      navigate(`/${ROUTES.COMPANIES}/${companyId}/${ROUTES.COMPANY_OVERVIEW}`, {
        state: { prevRoute: location.pathname, prevRouteName: prevRouteName || '' },
      });
    },
    [location.pathname, navigate, prevRouteName]
  );

  const onResetFilters = useCallback(() => {
    setSelectedRisks(sortedUniqueRisks);
    setSelectedOpportunities(sortedUniqueOpportunities);
    setColumnFilters([]);
    setSelectedType(null);
    setSelectedCountries([]);
    setSelectedSectors([]);
    setSelectedAnalysts([]);
    setSelectedGovernanceScoreType(null);
  }, [
    setColumnFilters,
    setSelectedAnalysts,
    setSelectedCountries,
    setSelectedGovernanceScoreType,
    setSelectedOpportunities,
    setSelectedRisks,
    setSelectedSectors,
    setSelectedType,
    sortedUniqueOpportunities,
    sortedUniqueRisks,
  ]);

  const isResetDisabled = useMemo(() => {
    if (!columnFilters.length && !selectedSectors?.length && !selectedCountries?.length)
      return true;
  }, [columnFilters.length, selectedCountries?.length, selectedSectors?.length]);

  const title = useMemo(() => {
    if (activeRole === USER_ROLE.ANALYST) {
      return 'Summaries by company';
    }

    return 'Assessment Summary';
  }, [activeRole]);

  return (
    <Container>
      <Typography variant='h4' color={colors.primary[90]} style={{ marginBottom: '20px' }}>
        {title}
      </Typography>
      <FiltersWrapper>
        <SearchInput
          placeholder='Search for a company'
          style={{ width: '345px', marginRight: '12px' }}
          onClear={() => table.getColumn('name')?.setFilterValue('')}
          onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
          value={table.getColumn('name')?.getFilterValue() ?? ''}
        />
        {!companiesType && (
          <>
            <AnalystFilter
              sortedUniqueAnalysts={sortedUniqueAnalysts}
              selectedAnalysts={selectedAnalysts}
              onChangeAnalystsFilter={onChangeAnalystsFilter}
            />
          </>
        )}
        <RiskFilter
          sortedUniqueRisks={sortedUniqueRisks}
          selectedRisks={selectedRisks}
          onChangeSelectedRisks={onChangeSelectedRisks}
        />

        <OpportunitiesFilter
          sortedUniqueOpportunities={sortedUniqueOpportunities}
          selectedOpportunities={selectedOpportunities}
          onChangeSelectedOpportunities={onChangeSelectedOpportunities}
        />
        {!companiesType && (
          <>
            <GovernanceScoreFilter
              selectedType={selectedGovernanceScoreType}
              onChangeTypeFilter={onChangeGovernanceScoreTypeFilter}
            />
            <CompanyTypeFilter
              selectedType={selectedType}
              sortedUniqueTypes={sortedUniqueTypes}
              onChangeTypeFilter={onChangeTypeFilter}
            />
            <ActivePositionFilter
              selectedActivePosition={selectedIsActivePosition}
              onChangeActivePositionFilter={onChangeActivePositionFilter}
            />
            <SectorsFilter
              sortedUniqueSectors={sortedUniqueSectors}
              onChangeSectorsFilter={onChangeSectorsFilter}
              selectedSectors={selectedSectors}
            />
            <CountriesFilter
              sortedUniqueCountries={sortedUniqueCountries}
              onChangeCountriesFilter={onChangeCountriesFilter}
              selectedCountries={selectedCountries}
            />
          </>
        )}

        <ResetFiltersButton onResetFilters={onResetFilters} isResetDisabled={isResetDisabled} />
      </FiltersWrapper>
      <Table
        table={table}
        tdStyle={{ height: 'inherit' }}
        onRowClick={onRowClick}
        height={wrapperHeight || 'calc(100vh - 122px)'}
      />
    </Container>
  );
};
