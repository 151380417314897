import { RefObject, useState } from 'react';
import { RoundedAccordion } from '../../../../../../../components/RoundedAccordion/RoundedAccordion';
import { AggregatedReportBreakdownGroup } from '../../../../../../../types';
import { GroupSummary } from '../../GroupSummary';
import { GroupedBreakdownTable } from '../GroupedBreakdownTable';
import { useTheme } from '@mui/material';

interface Props {
  subgroup: AggregatedReportBreakdownGroup;
  onHorizontalScroll?: React.UIEventHandler<HTMLDivElement>;
  tableRef?: RefObject<HTMLDivElement>;
}
export const SubgroupAccordion = ({ subgroup, onHorizontalScroll, tableRef }: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { colors } = useTheme();
  return (
    <RoundedAccordion
      key={subgroup.groupName}
      isExpanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      accordionSummary={<GroupSummary group={subgroup} />}
      accordionDetails={
        <GroupedBreakdownTable
          onHorizontalScroll={onHorizontalScroll}
          tableRef={tableRef}
          tableData={subgroup.tableData || []}
        />
      }
      hasDetails
      containerStyles={{ borderRadius: '10px', border: `1px solid ${colors.primary[40]}` }}
      summaryStyles={{ backgroundColor: '#fff' }}
    />
  );
};
