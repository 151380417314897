import { styled } from '@mui/material';
import { ReactComponent as SpinnerSvg } from '../assets/icons/spinner/spinner.svg';

const Spinner = styled(SpinnerSvg)`
  animation: spin 900ms infinite linear;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = () => {
  return <Spinner />;
};
