import { GOVERNANCE_SCORE_TYPE } from '../types';

export const filterByGovernanceScoreType = (
  value: number,
  selectedFilter: GOVERNANCE_SCORE_TYPE
) => {
  if (selectedFilter === GOVERNANCE_SCORE_TYPE.GOOD) {
    return value < 5;
  }
  if (selectedFilter === GOVERNANCE_SCORE_TYPE.BAD) {
    return value >= 5;
  }
  return true;
};
