import styled from '@emotion/styled';
import { AggregatedReportBreakdownGroup } from '../../../../../../../types';
import { SubgroupAccordion } from './SubgroupAccordion';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface Props {
  subgroups: AggregatedReportBreakdownGroup[];
  tablesMap: Record<string, HTMLDivElement>;
  onScroll: React.UIEventHandler<HTMLDivElement>;
  group: AggregatedReportBreakdownGroup;
}

export const Subgroups = ({ subgroups, tablesMap, group, onScroll }: Props) => {
  return (
    <Wrapper>
      {subgroups.map((subgroup) => (
        <SubgroupAccordion
          onScroll={onScroll}
          tableRef={tablesMap[group.groupName + subgroup.groupName]}
          subgroup={subgroup}
          key={subgroup.groupName}
        />
      ))}
    </Wrapper>
  );
};
