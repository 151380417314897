import { useEffect, useState } from 'react';
import {
  fetchCompanyById,
  useInvalidateCompanyById,
  useUpdateCompanyWithouthRefetchData,
} from '../../../queries/useCompanies';
import { useAtom } from 'jotai';
import {
  companyKpiRequestFrequencyState,
  companyKpisDataState,
  selectedFrequencyPreviewState,
} from '../../../state/UIState';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { SURVEY_FREQUENCY } from '../../../types';

export function useInitKpiMatrix(companyId: number) {
  const [companyKpisData, setCompanyKpisData] = useAtom(companyKpisDataState);
  const [, setCompanyKpiRequestFrequency] = useAtom(companyKpiRequestFrequencyState);
  const [isLoading, setIsLoading] = useState(true);
  const updateCompany = useUpdateCompanyWithouthRefetchData();
  const debouncedCompanyKpisData = useDebouncedValue(companyKpisData, 500);
  const invalidateCompanyById = useInvalidateCompanyById();
  const [, setSelectedFrequencyPreview] = useAtom(selectedFrequencyPreviewState);

  useEffect(() => {
    const setData = async () => {
      if (!companyId) return;
      let numberOfAttemps = 0;
      const maxNumberOfAttemps = 5;
      async function getFirstValid() {
        try {
          const company = await fetchCompanyById(Number(companyId));
          if (!company) return;
          setCompanyKpisData(company?.kpisData);
          setCompanyKpiRequestFrequency(company?.kpisDataFrequency || SURVEY_FREQUENCY.QUARTERLY);
          setSelectedFrequencyPreview(company?.kpisDataFrequency || SURVEY_FREQUENCY.QUARTERLY);
          setIsLoading(false);
        } catch (error) {
          console.log({ error });
          numberOfAttemps++;
          if (numberOfAttemps < maxNumberOfAttemps) {
            getFirstValid();
          }
        }
      }
      getFirstValid();
    };

    setData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!debouncedCompanyKpisData) return;
    updateCompany.mutate({ id: companyId, kpisData: debouncedCompanyKpisData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCompanyKpisData]);

  useEffect(() => {
    return () => {
      setCompanyKpisData(null);
      setCompanyKpiRequestFrequency(null);
      invalidateCompanyById(companyId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
}
