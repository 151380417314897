import { Multiselect } from './Multiselect/Multiselect';
import { SelectItem } from '../types';

interface Props {
  sortedUniqueSectors: SelectItem[];
  onChangeSectorsFilter: (sectors: SelectItem[]) => void;
  selectedSectors: SelectItem[];
  width?: string;
  placeholder?: string;
}

export const SectorsFilter = ({
  sortedUniqueSectors,
  onChangeSectorsFilter,
  selectedSectors,
  width,
  placeholder,
}: Props) => {
  return (
    <Multiselect
      style={{ width: width || '220px' }}
      options={sortedUniqueSectors}
      value={selectedSectors}
      onChange={(_, val) => {
        onChangeSectorsFilter(val as SelectItem[]);
      }}
      disablePortal
      optionName='Sector'
      fieldPlaceholder={placeholder || 'Filter by Sectors'}
    />
  );
};
