import { styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.accent[50]};
  border-radius: 100px;
  padding: 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px;
`;

interface Props {
  badgeNum?: number;
}

export const Badge = ({ badgeNum }: Props) => {
  const { colors, text } = useTheme();
  return (
    <Wrapper>
      <Typography
        variant='tabLabel'
        color={colors.primary[0]}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 700,
          fontFamily: text.font.fontInconsolataSemiBold,
        }}
      >
        {badgeNum}
      </Typography>
    </Wrapper>
  );
};
