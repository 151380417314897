import { SingleSelect } from '../../../../../../components/SingleSelect/SingleSelect';
import { AGGREGATED_REPORT_ISSUERS_FILTER, SelectItem } from '../../../../../../types';
import { useCallback, useMemo } from 'react';

import { useAtom } from 'jotai';
import { aggregatedReportState } from '../../../../../../state/UIState';

const options = [
  { id: 'Yes', value: 'Yes' },
  { id: 'No', value: 'No' },
];

export const ActivePositionFilter = () => {
  const [aggregatedReport, setAggregatedReport] = useAtom(aggregatedReportState);

  const selectedActivePosition = useMemo(() => {
    if (!aggregatedReport) return null;

    const selectedPosition = aggregatedReport?.issuerFilters?.[
      AGGREGATED_REPORT_ISSUERS_FILTER.ACTIVE_POSITION
    ] as string;

    if (!selectedPosition) return null;

    return options.find((o) => o.value === selectedPosition) || null;
  }, [aggregatedReport]);

  const onChangeActivePosition = useCallback(
    (activePosition: SelectItem | null) => {
      if (!aggregatedReport) return;

      setAggregatedReport({
        ...aggregatedReport,
        issuerFilters: {
          ...aggregatedReport?.issuerFilters,
          [AGGREGATED_REPORT_ISSUERS_FILTER.ACTIVE_POSITION]: activePosition?.value || null,
        } as Record<AGGREGATED_REPORT_ISSUERS_FILTER, string>,
      });
    },
    [aggregatedReport, setAggregatedReport]
  );

  return (
    <SingleSelect
      style={{ width: '100%' }}
      options={options}
      value={selectedActivePosition}
      onChange={(_, activePosition) => onChangeActivePosition(activePosition)}
      disablePortal
      fieldPlaceholder='Active Position'
    />
  );
};
