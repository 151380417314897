import { styled } from '@mui/material';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { Multiselect } from '../../components/Multiselect/Multiselect';
import { Table } from '@tanstack/react-table';
import { AggregatedReport, SelectItem } from '../../types';
import { useCallback, useMemo, useState } from 'react';

const FiltersWrapper = styled('div')`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin: 20px 0;
`;

interface Props {
  table: Table<AggregatedReport>;
  aggregatedReports?: AggregatedReport[];
}

export const AggregatedReportsFilters = ({ table, aggregatedReports }: Props) => {
  const [selectedAuthors, setSelectedAuthors] = useState<SelectItem[]>([]);

  const sortedUniqueAuthors = useMemo(() => {
    const allValues = Array.from(
      table.getColumn('createdBy')?.getFacetedUniqueValues().keys() ?? []
    );
    const map = allValues.reduce((acc, curr) => {
      if (!curr) return acc;
      acc[curr.fullName] = curr.fullName;
      return acc;
    }, {});

    const unique =
      Object.keys(map)
        .sort()
        .map((value, id) => ({ id, value })) ?? [];
    return unique;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, aggregatedReports]);

  const onChangeAuthorsFilter = useCallback(
    (authors: SelectItem[]) => {
      setSelectedAuthors(authors);
      table.getColumn('createdBy')?.setFilterValue(authors.map((c) => c.value));
    },
    [setSelectedAuthors, table]
  );

  return (
    <FiltersWrapper>
      <SearchInput
        placeholder='Search for a report'
        style={{ width: '345px', marginRight: '12px' }}
        onClear={() => table.getColumn('name')?.setFilterValue('')}
        onChange={(e) => table.getColumn('name')?.setFilterValue(e.target.value)}
        value={table.getColumn('name')?.getFilterValue() ?? ''}
      />
      <Multiselect
        style={{ width: '200px' }}
        options={sortedUniqueAuthors}
        value={selectedAuthors}
        onChange={(_, val) => {
          onChangeAuthorsFilter(val as SelectItem[]);
        }}
        disablePortal
        optionsName='Authors'
        fieldPlaceholder='Filter by Authors'
      />
    </FiltersWrapper>
  );
};
