import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import {
  AGGREGATED_REPORT_ISSUERS_FILTER,
  AggregatedReport,
  AggregatedReportWidget,
  LoadingId,
  TAggregatedReportGroup,
} from '../types';
import { useLoadingBar } from '../hooks/useLoadingBar';
import { useToastMessage } from '../hooks/useToastMessage';
import { AxiosError } from 'axios';

export const AGGREGATED_REPORTS = 'aggregatedReports';

export interface AggregatedReportPayload {
  id?: number;
  name: string;
  description?: string;
  surveys?: number[];
  selectedQuestions?: string[] | null;
  issuerFilters?: Record<AGGREGATED_REPORT_ISSUERS_FILTER, string[] | string> | null;
  createdBy?: number;
  groupedBy?: TAggregatedReportGroup[] | null;
  companies?: number[];
  widgets?: AggregatedReportWidget[];
}

async function fetchAggregatedReports(): Promise<AggregatedReport[]> {
  const { data } = await Http.axios.get<AggregatedReport[]>(`/aggregated-report`);

  return data?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
}

export function useAggregatedReports() {
  return useQuery([AGGREGATED_REPORTS], () => fetchAggregatedReports(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function createAggregatedReport(payload: AggregatedReportPayload): Promise<AggregatedReport> {
  const { data } = await Http.axios.post<AggregatedReportPayload, AggregatedReport>(
    `/aggregated-report`,
    payload
  );
  return data;
}

export function useCreateAggregatedReport() {
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();
  const queryClient = useQueryClient();

  return useMutation(createAggregatedReport, {
    onMutate: async () => {
      startLoading(LoadingId.createAggregatedReport);
    },
    onError: (error: AxiosError & { response: { data: { message: string } } }) => {
      const message = error.response?.data?.message ?? 'Failed to create report';
      pushErrorToast({ message });
    },
    onSettled: () => {
      queryClient.refetchQueries(AGGREGATED_REPORTS);
      stopLoading(LoadingId.createAggregatedReport);
    },
  });
}

export async function fetchAggregatedReportById(id?: number): Promise<AggregatedReport> {
  const { data } = await Http.axios.get<AggregatedReport>(`/aggregated-report/${id}`);
  return data;
}

export function useAggregatedReportById(id?: number) {
  return useQuery(`${AGGREGATED_REPORTS}-${id}`, () => fetchAggregatedReportById(id), {
    enabled: Boolean(id),
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
}

async function updateAggregatedReport(
  report: Partial<AggregatedReportPayload>
): Promise<AggregatedReport> {
  const { id, ...payload } = report;
  const { data } = await Http.axios.patch<Partial<AggregatedReportPayload>, AggregatedReport>(
    `/aggregated-report/${id}`,
    payload
  );
  return data;
}

export function useUpdateAggregatedReport() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(updateAggregatedReport, {
    onMutate: async () => {
      startLoading(LoadingId.updateAggregatedReport);
    },
    onError: (error: AxiosError & { response: { data: { message: string } } }) => {
      const message = error.response?.data?.message ?? 'Failed to update report';
      pushErrorToast({ message });
    },
    onSettled: () => {
      queryClient.refetchQueries(AGGREGATED_REPORTS);
      stopLoading(LoadingId.updateAggregatedReport);
    },
  });
}

async function deleteAggregatedReport(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/aggregated-report/${id}`);
  return data;
}

export function useDeleteAggregatedReport() {
  const queryClient = useQueryClient();
  const { startLoading, stopLoading } = useLoadingBar();
  const { pushErrorToast } = useToastMessage();

  return useMutation(deleteAggregatedReport, {
    onMutate: async (id) => {
      startLoading(LoadingId.deleteAggregatedReport);
      await queryClient.cancelQueries(AGGREGATED_REPORTS);
      const prevReports = queryClient.getQueryData(AGGREGATED_REPORTS);
      queryClient.setQueryData(AGGREGATED_REPORTS, (oldReports: AggregatedReport[] | undefined) => {
        return (
          oldReports?.filter((report) => {
            return report.id !== id;
          }) ?? []
        );
      });
      return { prevReports };
    },
    onError: (error, _, context) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to delete report' });
      queryClient.setQueryData(AGGREGATED_REPORTS, context?.prevReports);
    },
    onSettled: () => {
      queryClient.invalidateQueries(AGGREGATED_REPORTS);
      stopLoading(LoadingId.deleteAggregatedReport);
    },
  });
}
